import React from 'react';
import {Icon} from 'antd';
import {withRouter} from 'react-router-dom';
import {
  StyledHeader,
  HeaderSectionTitle,
  HeaderSectionToggle
} from './LayoutStyles';

const HeaderComponent = ({history, location}) => {
  const localUser = JSON.parse(localStorage.user);
  
  if (location.pathname.indexOf('/management') === 0) {
    return (
      <StyledHeader>
        <HeaderSectionTitle>
          Solar da levada & Quinta Lago dos Cisnes
          <HeaderSectionToggle onClick={() => history.push('/')}>
            <Icon type="team" />
            Go To Wedding Day
          </HeaderSectionToggle>
        </HeaderSectionTitle>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader>
      <HeaderSectionTitle>
        Wedding Day
        {localUser.admin || localUser.organizer ? <HeaderSectionToggle onClick={() => history.push('/management')}>
          <Icon type="global" />
          Manage Websites
        </HeaderSectionToggle> : null}
      </HeaderSectionTitle>
    </StyledHeader>
  );
};

export default withRouter(HeaderComponent);
