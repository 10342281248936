import client from '../config/AxiosConfig';

export const GetSchedule = async (id) =>
  client.get(`/schedule/${id}`);

export const GetScheduleAndRelatedWeddingInfo = async (id) =>
  client.get(`/schedule/${id}/allByWedding`);

export const GetSchedulesToConfirmAndRelatedWeddingInfo = async (id) =>
  client.get(`/schedule/${id}/allToConfirmByWedding`);

export const ValidateScheduleType = async (weddingId) =>
  client.get(`/schedule/checkType/${weddingId}`);

export const GetPossibleScheduleTime = async (weddingId, data) =>
  client.get(`/schedule/possibleTime/${weddingId}?filter=${JSON.stringify(data)}`);

export const CreateSchedule = async (data) =>
  client.put(`/schedule`, data);

export const CreateOrganizationSchedule = async (data) =>
  client.put(`/schedule/organization`, data);

export const CreateInternalSchedule = async (data) =>
  client.put(`/schedule/internal`, data);

export const UpdateSchedule = async (id, data) =>
  client.post(`/schedule/${id}`, data);

export const UpdateOrganizationSchedule = async (id, data) =>
  client.post(`/schedule/organization/${id}`, data);

export const UpdateInternalSchedule = async (id, data) =>
  client.post(`/schedule/internal/${id}`, data);

export const UpdateScheduleNotes = async (id, data) =>
  client.post(`/schedule/notes/${id}`, data);

export const CheckSchedule = async (data) =>
  client.post(`/schedule/check`, data);

export const ConfirmSchedule = async (id) =>
  client.post(`/schedule/${id}/confirm`);

export const RegisterSchedulePresence = async (id, type) =>
  client.post(`/schedule/${id}/registerPresence/${type}`);

export const SoftDeleteInternalSchedule = async (id, deleted) =>
  client.delete(`/schedule/softDeleteInternal/${id}/${deleted}`);

export const SoftDeleteSchedule = async (id, deleted) =>
  client.delete(`/schedule/softDelete/${id}/${deleted}`);

export const GetSchedulesPaginated = async (currentPage, limit, filters) =>
  client.get(`/schedule/paginate/${currentPage}/${limit}?filter=${filters}`);

export const GetSchedulesPaginatedByEmployee = async (currentPage, limit, filters) =>
  client.get(`/schedule/paginateByEmployee/${currentPage}/${limit}?filter=${filters}`);

export const DownloadSchedules = async (filters) => {
  return client.post(`/schedule/export?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const DownloadSchedulesAvailables = async (filters) => {
  return client.post(`/schedule/exportAvailableList?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    // Check if the response indicates failure
    if (response?.success === false) {
      return null;
    }
    return response;
  });
}

export const UploadSchedulesToDrive = async (filters) =>
  client.get(`/reports/schedule/uploadDrive?filter=${filters}`);

// IMPORTANT
// Only use on schedule filters (includes deleted users)
export const GetScheduleColaborators = async () =>
  await client.get('/users/scheduleColaborators');