import React, { Component, useEffect, useRef, useState } from 'react';
import { Modal, Button, Icon } from 'antd';
import { RoomEditor, RoomEditorContainer, RoomEditorContent } from '../../process/guests/RoomPlanStyles';
import { SpinLoading } from '../../styles/BasicStyles';
import styled from 'styled-components';
import TextAreaInput from '../../components/inputs/TextAreaInput';

const StyledModal = styled.div`
  width: 50% !important;

  .styled-div {
    width: 0px;
    height: 0px;
    overflow: hidden ;
  }
`;

const StyledModalContent = styled.div`
  height: auto;

  .ant-modal-close {
    height: 54px;
    width: 35px;
  }

  .ant-modal-title {
    text-align: left;
  }

  .ant-modal-body {
    height: auto;
    overflow: auto;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: ${p => p?.notes ? 'column' : 'row'};
  text-align: left;

  b {
    margin-right: 4px;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }
`;

class RoomEditorModal extends Component {
  roomEditor = null;

  state = {
    display: 'block',
    loading: false,
    data: {},
  }

  componentDidMount = async () => {
    const { initialValues } = this.props;

    this.roomEditor = new window.RoomEditor('editor', 'pt');
    this.roomEditor.translationSystem.setActiveLanguage("pt");

    this.setState({
      loading: true,
      data: initialValues?.data ? JSON.parse(initialValues?.data) : {}
    }, () => this.setRoomEditorData());
  }

  componentWillUnmount() {
    if (this.roomEditor) {
      this.roomEditor.destroy();
    }
  }

  setRoomEditorData = () => {
    const { initialValues } = this.props;
    if (initialValues?.data) {
      const dataRoomEditor = JSON.parse(initialValues?.data);
      let answerSeatId = dataRoomEditor?.answerSeatId;
      if (this.roomEditor) {
        this.roomEditor.setWeddingPlace(dataRoomEditor?.weddingPlace);
        this.roomEditor.deserializeEditor(dataRoomEditor);
        this.roomEditor.setMode("READ_ONLY");
        this.roomEditor.setPreviewGuestsActive(true);

        if (answerSeatId) {
          this.roomEditor.setGuestsMode('SEAT');
        } else {
          this.roomEditor.setGuestsMode('TABLE');
        }
      }
      this.setState({loading: false});
    } else {
      this.beforeCloseModal();
    }
  }

  beforeCloseModal = () => {
    const editor = document.getElementById('editor');
    if (editor) editor.innerHTML = ""
    this.props.closeModal();
  }

  render() {
    const { loading, display } = this.state;
    const { data } = this.state;

    // ref={modalRef} 
    return (
      <div style={{ display }} >
        <div className="ant-modal-mask"></div>
        <div tabIndex={-1} className="ant-modal-wrap" role='dialog'>

          <StyledModal className="ant-modal" role='document'>
            <div className='styled-div' tabIndex={0} aria-hidden="true"></div>
            <StyledModalContent className='ant-modal-content'>
              <button type="button" aria-label="Close" className="ant-modal-close" onClick={() => this.beforeCloseModal()}>
                <Icon type="close" />
              </button>
              <div className="ant-modal-header">
                <div className="ant-modal-title">Pré-visualização do plano de sala</div>
              </div>
              <div className="ant-modal-body">
                <RoomEditorContainer guestMap={false}>
                  {(loading) && <div className='backdrop'>
                    <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                  </div>}
                  <RoomEditor log={true} id='editor'></RoomEditor>
                </RoomEditorContainer>

                <StyledRow notes={true}>
                  <b>Notas do plano de sala:</b>
                  <TextAreaInput
                    noLabel
                    disabled={true}
                    meta={{ invalid: false, submitFailed: false }}
                    input={{ value: data?.notes || '' }} />
                </StyledRow>

                <StyledRow notes={true}>
                  <b>Notas para cozinha:</b>
                  <TextAreaInput
                    noLabel
                    disabled={true}
                    meta={{ invalid: false, submitFailed: false }}
                    input={{ value: data?.kitchenNotes || '' }} />
                </StyledRow>
              </div>
              <div className="ant-modal-footer">
                <Button
                  key='cancel'
                  onClick={() => this.beforeCloseModal()}
                >
                  Cancelar
                </Button>
              </div>
            </StyledModalContent>
          </StyledModal>
        </div>
      </div >
    );
  }
};
export default RoomEditorModal;