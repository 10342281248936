import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import { getOrganizers } from '../../infra/requests/AdminsRequests';
import XpertGoRangePickerInput from '../../components/inputs/XpertGoRangePickerInput';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import { GetSubcategories } from '../../infra/requests/SubcategoryRequests';

const employeeTypes = [
  { _id: 'employeeAttending', name: 'CA' },
  { _id: 'employeeTrainee', name: 'Estagiário' }
];

export const EmployeeStatus = {
  ACTIVE: 'A',
  PENDING: 'P',
  INCOMPLETE: 'I',
  UNFILLED: 'U',
  DELETED: 'D'
}

const employeeStatusOptions = [
  { _id: EmployeeStatus.ACTIVE, name: 'Ativo' },
  { _id: EmployeeStatus.PENDING, name: 'Pendente' },
  { _id: EmployeeStatus.INCOMPLETE, name: 'Incompleto' },
  { _id: EmployeeStatus.UNFILLED, name: 'Por preencher' },
  { _id: EmployeeStatus.DELETED, name: 'Eliminado' },
]

class EmployeesFilters extends Component {
  timeout = 0;
  state = {
    search: '',
    subcategory: null,
    employeeType: null,
    status: null,
    loading: false,
    subcategories: [],
  };

  componentDidMount = async () => {
    const {data} = await GetSubcategories();
    this.setState({ subcategories: data || [] });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (key !== 'subcategories' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    // queryChange(JSON.stringify(query));
    queryChange(query);
  };

  render() {
    const { search, subcategory, subcategories, employeeType, status, loading } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 600)
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar"
              placeholder="Procurar por nomes ou emails"
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <SelectInput
              label="Subcategoria"
              disabled={loading}
              allowClear
              placeholder="Escolher subcategoria"
              data={subcategories}
              input={{
                value: subcategory,
                onChange: value => this.onInputChange('subcategory', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={4}>
            <SelectInput
              label="Tipo"
              disabled={loading}
              placeholder="Escolher tipo"
              allowClear={true}
              data={employeeTypes}
              input={{
                value: employeeType,
                onChange: value => this.onInputChange('employeeType', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={4}>
            <SelectInput
              label="Estado"
              disabled={loading}
              placeholder="Escolher estado"
              allowClear={true}
              data={employeeStatusOptions}
              input={{
                value: status,
                onChange: value => this.onInputChange('status', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(EmployeesFilters);
