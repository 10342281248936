import React, { Component, Fragment } from 'react';
import { Button, Dropdown, Icon, Menu, message, Modal, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
  SectionTitle
} from '../../styles/BasicStyles';
import { CheckAttendingAt, ExtendReserveEditingTime, GetWeddingsNoReserves, GetWeddingsNoWaitingDates, SoftDeleteWaitingWedding, UnblockMeetings, UpdateReserveNotes } from '../../infra/requests/WeddingRequests';
import moment from 'moment';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { getLocalColor, getWeddingContacts, getWeddingEmails, getWeddingName } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import Alerts from '../../components/alert/Alert';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import { LocalLabel } from '../weddings/Styles';
import styledComponents from 'styled-components';
import { GetTextColor } from '../../infra/services/text/textUtils';
import momentTimezone from 'moment-timezone';
import { AuthGoogleDrive } from '../../infra/requests/ReportsRequests';
import { DownloadWaitingDate, GetWaitingDatePaginated, SoftDeleteWaitingDate, UploadWaitingDateToDrive } from '../../infra/requests/WaitingDatesRequests';
import WaitingDateFilters from './WaitingDateFilters';
import { GetWaitingRowColor, setDataToUpdateForReserveWedding } from '../reserves/reservesUtils';
import { WeddingStatus } from '../reserves/ReserveAgenda';
import WeddingNoReservesFilters from '../reserves/WeddingNoReservesFilters';
import AttendingWaitingDateFilters from './AttendingWaitingDateFilters';
import { DateColumn, SpanNotes } from '../reserves/ReservesPage';
import ModalDeleteWaitingWedding from './DeleteWaitingWeddingModal';
import AccessGuestModal from '../weddings/AccessGuestModal';
import { CreateCoupleReserve } from '../../infra/requests/UsersRequests';
import ReserveInfoModal from '../reservesAttending/ReserveInfoModal';
import { UpdateReserveRelatedInfo } from '../../infra/requests/ReserveRequests';
import BaseButton from '../../components/buttons/BaseButton';
import ReserveNotesModal from '../reserves/ReserveNotesModal';
import OpenLockIcon from '../../assets/icons/open_lock.svg';
import ReactSVG from 'react-svg';

const confirm = Modal.confirm;

export const SpanWaitingContact = styledComponents.a`
  text-overflow: ellipsis;
  width: 60px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
`;

export const SpanWaitingNotes = styledComponents.div`
  width: 100%;
  height: 80px;
  max-height: 80px;
  justify-content: center;
  overflow: auto;
`;

const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer eliminar esta data da lista de espera?</div>
    <div>
      Toda a informação relacionada com esta data será removida da lista de espera e da plataforma.
    </div>
  </div>
);

export const WaitingActionType = {
  MONTH: 'M', // M: Month
  WEEKDAY: 'WD', // WD: Weekday
  WEEK: 'W', // W: week
  ALL: 'A', // A: All
  DAY: 'D', // D: Day
};

class WaitingDatesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    download: false,
    upload: false,

    filters: {},
    columnsAttending: [
      {
        title: 'Data',
        width: '80px',
        render: data => {
          return <DateColumn>
            <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
            <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
          </DateColumn>
        }
      },
      {
        title: 'Local',
        width: '40px',
        className: 'align-center',
        render: data => {
          const local = getLocalColor(data, this.state.colors);
          return local
            ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
            : null;
        }
      },
      {
        title: 'Noivos',
        width: '100px',
        render: data => getWeddingName(data.wedding)
      },
      {
        title: 'Email',
        width: '60px',
        render: data => {
          return <Tooltip title={getWeddingEmails(data.wedding)}>
            <span onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
              {getWeddingEmails(data.wedding)}
            </span>
          </Tooltip>
        }
      },
      {
        title: 'Notificar até',
        width: '80px',
        render: data => {
          return `${data?.wedding?.untilPeriodNotificationWaitingDate || '2'} meses antes`;
        }
      },
      {
        title: 'Notas',
        width: '100px',
        render: data => {
          return <SpanWaitingNotes
            dangerouslySetInnerHTML={{
              __html: data?.wedding?.reserveNotes || ''
            }}>
          </SpanWaitingNotes>
        }
      },
      {
        title: 'Acções',
        width: '70px',
        render: data => {
          return <div onClick={e => e.stopPropagation()}>
            {data?.wedding?.status === WeddingStatus.RESERVE &&
              <Tooltip title={'Editar Noivos/Info Geral'}>
                <TableButton onClick={e => this.editGeneral(e, data)}>
                  <Icon type="team" />
                </TableButton>
              </Tooltip>}
            <Tooltip title='Editar lista de espera do casal'>
              <TableButton onClick={(e) => this.editWaitingDate(e, data)}>
                <Icon type="edit" />
              </TableButton>
            </Tooltip>
            <Tooltip title={'Eliminar da lista de espera'}>
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title={RemoveMessage}
                  okText='Eliminar'
                  onConfirm={async () => await this.deleteWaitingDate(data)}>
                  <Icon type="delete" />
                </Popconfirm>
              </TableButton>
            </Tooltip>
          </div>
        }
      }
    ],
    columns: [
      {
        title: 'Data',
        width: '80px',
        render: data => {
          return {
            props: {
              // style: {
              //   backgroundColor: GetWaitingRowColor(this.state.colors, data),
              //   color: GetTextColor(GetWaitingRowColor(this.state.colors, data))
              // }
            },
            children: data?.date ?
              <DateColumn>
                <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
              </DateColumn>
              : ''
          };
        }
      },
      {
        title: 'Local',
        width: '50px',
        className: 'align-center',
        render: data => {
          const local = getLocalColor(data, this.state.colors);

          return {
            props: {
              // style: {
              //   backgroundColor: GetWaitingRowColor(this.state.colors, data),
              //   color: GetTextColor(GetWaitingRowColor(this.state.colors, data))
              // }
            },
            children: local
              ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>)
              : null
          };
        }
      },
      {
        title: 'Noivos',
        width: '100px',
        render: data => {
          return {
            props: {
              // style: {
              //   backgroundColor: GetWaitingRowColor(this.state.colors, data),
              //   color: GetTextColor(GetWaitingRowColor(this.state.colors, data))
              // }
            },
            children: getWeddingName(data.wedding)
          };
        }
      },
      {
        title: 'Email',
        width: '60px',
        render: data => {
          return {
            props: {
              // style: {
              //   backgroundColor: GetWaitingRowColor(this.state.colors, data),
              //   color: GetTextColor(GetWaitingRowColor(this.state.colors, data))
              // }
            },
            children: <Tooltip title={getWeddingEmails(data.wedding)}>
              <span onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
                {getWeddingEmails(data.wedding)}
              </span>
            </Tooltip>
          };
        }
      },
      {
        title: 'Tel',
        width: '60px',
        render: data => {
          return {
            props: {
              // style: {
              //   backgroundColor: GetWaitingRowColor(this.state.colors, data),
              //   color: GetTextColor(GetWaitingRowColor(this.state.colors, data))
              // }
            },
            children: <Tooltip title={getWeddingContacts(data.wedding)}>
              <span onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
                {getWeddingContacts(data.wedding)}
              </span>
            </Tooltip>
          };
        }
      },
      {
        title: 'Notificar até',
        width: '80px',
        render: data => {
          return {
            props: {
              // style: {
              //   backgroundColor: GetWaitingRowColor(this.state.colors, data),
              //   color: GetTextColor(GetWaitingRowColor(this.state.colors, data))
              // }
            },
            children: `${data?.wedding?.untilPeriodNotificationWaitingDate || '2'} meses antes`
          };
        }
      },
      {
        title: 'Notas',
        width: '100px',
        render: data => {
          return {
            props: {
              // style: {
              //   backgroundColor: GetRowColor(this.state.colors, data),
              //   color: GetTextColor(GetRowColor(this.state.colors, data))
              // }
            },
            children: <Tooltip title={'Editar'}>
              <SpanWaitingNotes
                dangerouslySetInnerHTML={{
                  __html: data?.wedding?.reserveNotes || ''
                }}
                onClick={($event) => this.editReserveNotes($event, data)}>
              </SpanWaitingNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Acções',
        width: '50px',
        // fixed: 'right',
        render: data => {
          return {
            props: {
              // style: {
              //   backgroundColor: GetWaitingRowColor(this.state.colors, data),
              //   color: GetTextColor(GetWaitingRowColor(this.state.colors, data))
              // }
            },
            children: <div onClick={e => e.stopPropagation()}>
              <Tooltip title={data?.wedding?.status !== WeddingStatus.RESERVE ? 'Editar Acessos/Padrão' : 'Editar Noivos/Info Geral'}>
                <TableButton onClick={e => this.editGeneral(e, data)}>
                  <Icon type="team" />
                </TableButton>
              </Tooltip>
              {data?.wedding && <Tooltip title='Acesso Convidado'>
                <TableButton onClick={e => this.editGuestAccess(e, data)}>
                  <Icon type="solution" />
                </TableButton>
              </Tooltip>}
              {data?.wedding?.status == WeddingStatus.CONFIRMED && data?.wedding?.meetingsBlocked
                && <Tooltip title='Desbloquear Reuniões'>
                  <TableButton onClick={e => this.unblockMeetings(data?.wedding)}>
                    <ReactSVG src={OpenLockIcon} />
                  </TableButton>
                </Tooltip>}
              {data?.wedding?.attendedBy && <Tooltip title={'Prolongar Tempo de Edição'}>
                <TableButton onClick={e => this.extendEditingTime(e, data)}>
                  <Icon type="clock-circle" />
                </TableButton>
              </Tooltip>}
              <Tooltip title='Editar lista de espera do casal'>
                <TableButton onClick={(e) => this.editWaitingDate(e, data)}>
                  <Icon type="edit" />
                </TableButton>
              </Tooltip>
              <Tooltip title={'Eliminar data da lista de espera'}>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={RemoveMessage}
                    okText='Eliminar'
                    onConfirm={async () => await this.deleteWaitingDate(data)}>
                    <Icon type="delete" />
                  </Popconfirm>
                </TableButton>
              </Tooltip>
              {data?.canDeleteWedding && <Tooltip title={'Eliminar casal da plataforma'}>
                <TableButton onClick={e => {
                  e.stopPropagation()
                  this.setState({
                    openModalDelete: true,
                    weddingToDelete: data?.wedding
                  });
                }}>
                  <Icon type="usergroup-delete" />
                </TableButton>
              </Tooltip>}
            </div>
          };
        }
      }
    ],
    rows: [],
    total: 0,

    showReserveModal: false,
    valuesReserveModal: {},
    savingReserveModal: false,

    openModalDelete: false,
    weddingToDelete: null,

    notesModal: {},
    openNotesModal: false,
    savingNotesModal: false,

    pageSizeWeddings: 30,
    currentPageWeddings: 1,
    loadingWeddings: false,
    filtersWeddings: {},
    columnsWeddings: [
      {
        title: 'Noivos',
        render: data => getWeddingName(data)
      },
      {
        title: 'Email',
        render: data => {
          return <a onClick={($event) => this.copy($event, getWeddingEmails(data))}>
            {getWeddingEmails(data)}
          </a>;
        }
      },
      {
        title: 'Tel',
        render: data => {
          return <a onClick={($event) => this.copy($event, getWeddingContacts(data))}>
            {getWeddingContacts(data)}
          </a>;
        }
      },
      {
        title: 'Data Ativação',
        width: '80px',
        render: data => {
          return data?.createdAt ? moment.utc(data?.createdAt).format('DD-MM-YYYY') : '';
        }
      },
      {
        title: 'Acções',
        width: '70px',
        render: data => {
          return <div onClick={e => e.stopPropagation()}>
            <Tooltip title="Editar Noivos/Info Geral">
              <TableButton onClick={e => this.editGeneral(e, { wedding: data })}>
                <Icon type="team" />
              </TableButton>
            </Tooltip>
            <Tooltip title='Acesso Convidado'>
              <TableButton onClick={e => this.editGuestAccess(e, { wedding: data })}>
                <Icon type="solution" />
              </TableButton>
            </Tooltip>
            {isCurrentUserAdmin() && data?.status == WeddingStatus.CONFIRMED && data?.meetingsBlocked
              && <Tooltip title='Desbloquear Reuniões'>
                <TableButton onClick={e => this.unblockMeetings(data)}>
                  <ReactSVG src={OpenLockIcon} />
                </TableButton>
              </Tooltip>}
            <Tooltip title={'Gerir lista de espera'}>
              <TableButton onClick={e => this.mngWaitingDatesFromWedding(data)}>
                <Icon type="plus-circle" />
              </TableButton>
            </Tooltip>
          </div>
        }
      }
    ],
    rowsWeddings: [],
    totalWeddings: 0,

    colors: {
      colorSys1: null,
      colorSys2: null
    },

    showGuestAccessModal: false,
    guestWeddingId: null,
  };

  componentDidMount = async () => {
    this.getLocalColors();
    this.updateDataTable();
    this.updateWeddingDataTable();
  };

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1?.success && resColorSys1?.data ? resColorSys1.data?.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2?.success && resColorSys2?.data ? resColorSys2.data?.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  copy = ($event, text) => {
    $event.stopPropagation();
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Campo copiado!');
  }

  // Reserve Notes
  editReserveNotes = async ($event, data) => {
    $event.stopPropagation();

    this.setState({
      notesModal: {
        wedding: data?.wedding,
        notes: data?.wedding?.reserveNotes,
        visits: data?.wedding?.visits,
        attendedBy: data?.wedding?.attendedBy?._id ? data?.wedding?.attendedBy?._id : data?.wedding?.attendedBy,
      },
      openNotesModal: true
    });
  }

  onNotesModalSubmit = async (values) => {
    const { notesModal, rows } = this.state;
    const weddingId = values?.wedding?._id ? values?.wedding?._id : values?.wedding;
    if (isCurrentUserEmployeeAttending()) {
      const resultCheck = await CheckAttendingAt(weddingId);
      if (!resultCheck.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição terminou!'
        });
        return;
      }
    }

    // console.warn('values', values);
    try {
      this.setState({ savingNotesModal: true });

      const dataToUpdate = {
        reserveNotes: values.notes,
        visits: values.visits,
        attendedBy: values?.attendedBy
      }
      const { success, data } = await UpdateReserveNotes(weddingId, dataToUpdate);

      if (success && data) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });

        this.setState({ openNotesModal: false, notesModal: {}, savingNotesModal: false }, () => {
          this.updateDataTable();
        });
      } else {
        const message = data == 'TIME_CREATE_EDIT_ENDED'
          ? 'O período de edição terminou!'
          : data == 'NOT_AUTHORIZED'
            ? 'Não autorizado'
            : data;
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
        this.setState({
          savingNotesModal: false,
          openNotesModal: data == 'TIME_CREATE_EDIT_ENDED' || data == 'NOT_AUTHORIZED' ? false : true
        });
        return;
      }
    } catch (e) {
      console.error(e);
      this.setState({ savingNotesModal: false });
    }
  };
  // End Reserve Notes

  // Waiting Date Table
  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    let { filters } = this.state;
    if (isCurrentUserEmployeeAttending() && (filters?.search === undefined || filters?.search === null || filters?.search.trim() === '')) {
      this.setState({
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 30,
      });
      return;
    }

    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;

      // Ignore filter dates if array is empty
      if (Array.isArray(filters?.dates) && filters?.dates.length === 0) {
        delete filters.dates;
      }

      const result = await GetWaitingDatePaginated(currentPage, pageSize, JSON.stringify(filters));
      // console.warn('Res', result);

      this.setState({
        rows: result?.data?.items || [],
        total: result?.data?.total || 0,
        //currentPage: result.data.page,
        pageSize: result?.data?.limit || 30,
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };
  // End Waiting Date Table

  editWaitingDate = (event, waitingDate) => {
    event.stopPropagation();
    const { history } = this.props;

    if (isCurrentUserEmployeeAttending()) {
      history.push(`/waiting?wedding=${waitingDate?.wedding?._id}&page=WD`);
    } else history.push(`/waiting-agenda?wedding=${waitingDate?.wedding?._id}&page=WD`);
  }

  deleteWaitingDate = async (waitingDate) => {
    const result = await SoftDeleteWaitingDate(waitingDate?._id, true);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Data eliminada da lista de espera do casal!'
      });
      this.updateDataTable();
      this.updateWeddingDataTable();
    }
  }

  deleteWedding = async (wedding, password) => {
    const res = await SoftDeleteWaitingWedding(wedding?._id, true, JSON.parse(localStorage.user).email, password);
    if (res?.success) this.updateDataTable();
    else {
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: res?.data
      });
    }
  };

  openProcess = record => {
    const { history } = this.props;

    if (isCurrentUserAdmin() && record?.wedding?.status !== WeddingStatus.RESERVE) {
      //Clear previous saved Wedding
      ClearWedding();
      history.push(`/wedding/${record?.wedding?._id}?page=WD`);
    } else return;
  };

  editGeneral = (event, record) => {
    event.stopPropagation();
    const { history } = this.props;

    if (record?.wedding?.status === WeddingStatus.ARCHIVED) {
      if (isCurrentUserAdmin()) history.push(`/archived/wedding/${record?.wedding?._id}?page=R`);
      else return;
    } else if (record?.wedding?.status !== WeddingStatus.RESERVE) {
      const { history } = this.props;
      if (isCurrentUserAdmin()) history.push(`/weddings/${record?.wedding?._id}?page=WD`);
    } else {
      const valuesReserveModal = record?.wedding ? {
        ...record.wedding,
        bridename: record.wedding.bride.name,
        brideemail: record.wedding.bride.email,
        bridecontact: record.wedding.bride.contact,
        groomname: record.wedding.groom.name,
        groomemail: record.wedding.groom.email,
        groomcontact: record.wedding.groom.contact,
        alternativename: record.wedding.alternativeGuest?.name || '',
        alternativemail: record.wedding.alternativeGuest?.email || '',
        alternativecontact: record.wedding.alternativeGuest?.contact || '',
        alternativerelationcouple: record.wedding.alternativeGuest?.relationToCouple || '',
      } : {};

      // Check employee attending
      if (isCurrentUserEmployeeAttending() && record?.wedding?.attendedBy) {
        const attendedById = record?.wedding?.attendedBy?._id ? record?.wedding?.attendedBy?._id : record?.wedding?.attendedBy;
        if (attendedById != getUserId()) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'Acesso não autorizado!'
          });
          return;
        }

        if (attendedById === getUserId() && record?.wedding?.attendingAt && moment.utc().isAfter(moment.utc(record?.wedding?.attendingAt).add(15, 'minutes'))) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'O período de edição terminou!'
          });
          return;
        }
      }

      this.setState({ showReserveModal: true, valuesReserveModal });
    }
  };

  extendEditingTime = async (event, record) => {
    event.stopPropagation();

    const result = await ExtendReserveEditingTime(record?.wedding?._id);
    if (result.success) {
      return Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Tempo de edição prolongado por mais 15 minutos!'
      });
    }
  };

  editGuestAccess = (event, record) => {
    event.stopPropagation();
    this.setState({ showGuestAccessModal: true, guestWeddingId: record.wedding._id });
  };

  unblockMeetings = async (wedding) => {
    const res = await UnblockMeetings(wedding?._id);
    if (res?.success) {
      Alerts.new({
        type: 'success',
        title: 'Successo',
        text: 'Reuniões desbloqueadas!'
      })
      this.updateDataTable();
    }
  }

  createUpdateWedding = async (values) => {
    const { history } = this.props;
    // console.warn('values', values);
    const editAction = values?._id ? true : false;

    //Check if employee attending still has time to create/edit wedding reserves
    if (editAction && isCurrentUserEmployeeAttending()) {
      const resultCheck = await CheckAttendingAt(values._id);
      if (!resultCheck?.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição terminou!'
        });
        this.setState({ showReserveModal: false, valuesReserveModal: {} });
        return;
      }
    }

    // if ((!values?.visitedPlace || values?.visitedPlace == false)
    //   && (!values?.placeToVisit || values?.placeToVisit == false)
    //   && (!values?.wantToVisitWithoutInfo || values?.wantToVisitWithoutInfo == false)) {
    //   Alerts.new({
    //     type: 'warning',
    //     title: 'Atenção',
    //     text: 'Indique se já visitou ou pretende visitar algum dos espaços.'
    //   });
    //   return;
    // }

    this.setState({ savingReserveModal: true });

    const data = setDataToUpdateForReserveWedding(values);
    const resultCoupleWedding = editAction
      ? await UpdateReserveRelatedInfo(values._id, data)
      : await CreateCoupleReserve(data);

    if (resultCoupleWedding?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: editAction
          ? 'Informação do casal alterada!'
          : 'Casal registado com sucesso!'
      });


      if (!editAction && resultCoupleWedding?.data?.wedding) {
        confirm({
          title: 'Pretende editar a lista de espera deste casal?',
          okText: 'Editar',
          cancelText: 'Cancelar',
          onOk() {
            if (isCurrentUserEmployeeAttending()) {
              history.push(`/waiting?wedding=${resultCoupleWedding?.data?.wedding?._id}`);
            } else history.push(`/waiting-agenda?wedding=${resultCoupleWedding?.data?.wedding?._id}`);
          },
          onCancel: () => {
            this.setState({
              showReserveModal: false,
              valuesReserveModal: {},
              savingReserveModal: false
            }, () => {
              this.updateDataTable();
              this.updateWeddingDataTable();
            });
          },
        });
      }

      this.setState({
        showReserveModal: false,
        valuesReserveModal: {},
        savingReserveModal: false
      }, () => {
        this.updateDataTable();
        this.updateWeddingDataTable();
      });
    } else {
      this.setState({
        showReserveModal: false,
        valuesReserveModal: {},
        savingReserveModal: false
      });
    }
  }

  // Export Methods
  // onSelectExportMenu = (value) => {
  //   const itemSelected = parseInt(value.key);
  //   // console.warn('itme', itemSelected);

  //   // Download
  //   if (itemSelected === 1) {
  //     this.exportWaitingDates();
  //   }
  //   // Upload to Google Drive
  //   else if (itemSelected === 2) {
  //     this.uploadWaitingDatesToGoogleDrive();
  //   }
  // }

  // exportMenu = (
  //   <Menu onClick={this.onSelectExportMenu}>
  //     <Menu.Item key={1}>
  //       <Icon type="file-protect" />
  //       Download
  //     </Menu.Item>
  //     <Menu.Item key={2}>
  //       <Icon type="cloud-download" />
  //       Google Drive
  //     </Menu.Item>
  //   </Menu>
  // );

  exportWaitingDates = async () => {
    let { filters } = this.state;
    this.setState({ download: true });
    let result;

    // Export all reserves information
    result = await DownloadWaitingDate(JSON.stringify(filters));

    if (result) {
      let filename = 'Lista_Espera_' + moment().format('YYYYMMDD_HHmmss') + '.xlsx';

      if (result.headers) {
        const contentDisposition = result.headers.get('content-disposition');
        filename = contentDisposition.split('filename="')[1].split('"')[0];
      }

      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([result])); //result.blob
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      //Append to html link element page
      document.body.appendChild(link);

      //Start download
      link.click();

      //Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    this.setState({ download: false });
  };

  uploadWaitingDatesToGoogleDrive = async () => {
    let { filters } = this.state;
    this.setState({ upload: true });

    const result = await AuthGoogleDrive();
    // console.warn('result', result);

    if (!result?.data?.active) {
      localStorage.setItem('googleReturnUrl', window.location.pathname);
      const newWindow = window.open(result?.data.authUrl, '_self', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null;
      this.setState({ upload: false });
      return;
    }

    const res = await UploadWaitingDateToDrive(JSON.stringify(filters));
    // console.warn('res', res);

    this.setState({ upload: false });
    if (res?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Upload com sucesso para o Google Drive!'
      });
    }
  }

  // Weddings Table
  handleWeddingChangePage = currentPageWeddings => {
    this.setState({ currentPageWeddings }, this.updateWeddingDataTable);
  };

  handleWeddingChangeRowsPerPage = (currentSize, pageSizeWeddings) => {
    this.setState({ pageSizeWeddings, currentPageWeddings: 1 }, this.updateWeddingDataTable);
  };

  updateWeddingDataTable = async () => {
    this.setState({ loadingWeddings: true });
    try {
      const { currentPageWeddings, pageSizeWeddings } = this.state;
      let { filtersWeddings } = this.state;

      const result = await GetWeddingsNoWaitingDates(currentPageWeddings, pageSizeWeddings, JSON.stringify(filtersWeddings));
      // console.warn('Res', result);

      this.setState({
        rowsWeddings: result.data.items,
        totalWeddings: result.data.total,
        pageSizeWeddings: result.data.limit,
        loadingWeddings: false
      });
    } catch (e) {
      this.setState({ loadingWeddings: false });
      console.error(e);
    }
  };

  handleWeddingsFilterChange = filtersWeddings => {
    this.setState({ filtersWeddings, currentPageWeddings: 1 }, this.updateWeddingDataTable);
  };

  mngWaitingDatesFromWedding = (wedding) => {
    const { history } = this.props;

    if (isCurrentUserEmployeeAttending()) {
      history.push(`/waiting?wedding=${wedding?._id}&page=WD`);
    } else history.push(`/waiting-agenda?wedding=${wedding?._id}&page=WD`);
  }
  // End Weddings Table

  render() {
    const { history } = this.props;
    const { columns, columnsAttending, columnsWeddings } = this.state;
    const { rows, total, loading, currentPage, pageSize } = this.state;
    const { download, upload } = this.state;
    const { openNotesModal, notesModal, savingNotesModal } = this.state;
    const { rowsWeddings, totalWeddings, loadingWeddings, currentPageWeddings, pageSizeWeddings } = this.state;
    const { openModalDelete, weddingToDelete } = this.state;
    const { showGuestAccessModal, guestWeddingId } = this.state;
    const { showReserveModal, valuesReserveModal, savingReserveModal } = this.state;

    const countBtns = isCurrentUserAdmin() ? 2 : 1;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={countBtns}>
            <PageTitle>Lista de Espera</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={countBtns}>
            <BaseButton
              type={'primary'}
              icon={'usergroup-add'}
              text={'Registar Casal'}
              onClick={() => this.setState({ showReserveModal: true, valuesReserveModal: {} })}
            />
            {/*{isCurrentUserAdmin() &&
              <Dropdown disabled={download || loading} overlay={this.exportMenu} trigger={['click']}>
                <Button loading={upload | download}>
                  Exportar
                  <Icon type="down" />
                </Button>
              </Dropdown>} */}

            {isCurrentUserAdmin() && <BaseButton
              type={'default'}
              icon={'file-protect'}
              text={'Exportar'}
              loading={upload || download}
              onClick={this.exportWaitingDates}
            />}
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={countBtns}>
          {isCurrentUserEmployeeAttending()
            ? <AttendingWaitingDateFilters loading={loading} queryChange={this.handleFilterChange} />
            : <WaitingDateFilters loading={loading} queryChange={this.handleFilterChange} />}
          <Table
            columns={isCurrentUserEmployeeAttending() ? columnsAttending : columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={'Não existem datas na lista de espera'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.openProcess}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

          <SectionTitle subsection>Casais sem lista de espera</SectionTitle>
          <WeddingNoReservesFilters loading={loadingWeddings} queryChange={this.handleWeddingsFilterChange} />
          <Table
            columns={columnsWeddings}
            currentPage={currentPageWeddings}
            pageSize={pageSizeWeddings}
            defaultPageSize={pageSizeWeddings}
            loading={loadingWeddings}
            rows={rowsWeddings}
            showHeader={true}
            emptyIcon="calendar"
            emptyText={'Não existem casais sem lista de espera'}
            total={totalWeddings}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleWeddingChangePage}
            handleChangeRowsPerPage={this.handleWeddingChangeRowsPerPage}
          />
        </PageContainer>

        {showReserveModal
          && <ReserveInfoModal
            isOpen={showReserveModal}
            onClose={() => this.setState({ showReserveModal: false, valuesReserveModal: {} })}
            onSubmit={this.createUpdateWedding}
            initialValues={valuesReserveModal}
            onlyCoupleInfo={true}
          />
        }

        {openNotesModal &&
          <ReserveNotesModal
            isOpen={openNotesModal}
            onSubmit={this.onNotesModalSubmit}
            saving={savingNotesModal}
            onClose={() => this.setState({ openNotesModal: false, notesModal: {}, savingNotesModal: false })}
            initialValues={notesModal}
            onlyNotes={true}
          />}

        {openModalDelete
          && <ModalDeleteWaitingWedding
            isOpen={openModalDelete}
            initialValues={{ wedding: weddingToDelete }}
            onSubmit={($event) => this.setState({ openModalDelete: false, weddingToDelete: null }, async () => await this.deleteWedding($event.wedding, $event.password))}
            onClose={() => this.setState({ openModalDelete: false, weddingToDelete: null })}
          />}

        {showGuestAccessModal
          && <AccessGuestModal
            isOpen={showGuestAccessModal}
            onClose={() => this.setState({ showGuestAccessModal: false, guestWeddingId: null })}
            weddingId={guestWeddingId}
          />}
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(WaitingDatesPage);
