import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Input, Popconfirm, Tooltip, Icon, Checkbox } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { dateFormat, isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees, GetAttendingList, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GenerateAccessCode, GetConfirmedWeddings, GetWedding, MngGuestAccess, NotifyWeddingGuest, UpdateCreateWeddingGuest } from '../../infra/requests/WeddingRequests';
import Alerts from '../../components/alert/Alert';
import { CodeInput, CodeP } from '../../process/ChoicesPage/SectionStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { bindActionCreators } from 'redux';
import { VenueColors, getVenueColor, getWeddingFullName, getWeddingName } from '../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { CheckSchedule, GetSchedule, GetScheduleAndRelatedWeddingInfo, GetSchedulesToConfirmAndRelatedWeddingInfo } from '../../infra/requests/SchedulesRequests';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import { visitedLocals } from './SchedulesFilters';
import { InputDiv, InputLabelDiv, TimeInput } from '../../components/inputs/InputStyles';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import DateInput from '../../components/inputs/DateInput';
import momentTimezone from 'moment-timezone';
import { DateColumn, dateTimeFormat } from './SchedulesPageDetail';
import moment from 'moment';
import { LocalLabel } from '../weddings/Styles';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { TableContent, Title } from '../reservesAttending/ReserveInfoModalStyles';
import Table from '../../components/table/Table';
import { Note } from '../../process/dashboard/ProcessStyles';

export const Summary = styledComponents.div`
  line-height: 12px;
  height: 200px;
  width: 100%;
  overflow: auto;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid ${p => p.theme.inputBorderColor};
  border-radius: 4px;
  padding: 4px 11px;
  background-color: #f5f5f5;

  &:focus-within,
  &:hover {
    border: 1px solid ${p => p.theme.inputFocusColor};
  }
`;


class ConfirmScheduleModal extends React.Component {
    state = {
        loading: false,
        schedule: null,
        wedding: null,
        weddingSummary: null,

        columns: [
            {
                title: ' ',
                key: 'select',
                width: '30px',
                render: data => {
                    return <div className="sc-dxgOiQ fWHHkk" style={{ textAlign: 'center' }}>
                        <Checkbox
                            checked={data.checked}
                            onChange={(e) => {
                                let element = data;
                                element.checked = !element.checked;
                                let elements = this.state.rows.map(m => ({ ...m, checked: false }));
                                let index = elements.findIndex(x => x._id === element._id);
                                if (index > -1) {
                                    elements[index] = element;
                                    this.setState({ rows: elements })
                                }
                            }}>
                        </Checkbox>
                    </div>
                }
            },
            {
                title: 'Data',
                width: '85px',
                render: data => {
                    return data?.date
                        ? <DateColumn>
                            <span className='date'>{moment.utc(data.date).format(dateFormat)}</span>
                            <span className='weekdayName'>{moment.utc(data.date).locale('pt').format('dddd')}</span>
                        </DateColumn>
                        : '';
                }
            },
            {
                title: 'Horário',
                width: '60px',
                render: data => {
                    return <div>
                        {moment.utc(data.startDate).local().format('HH:mm')}
                        < Icon type="swap-right" />
                        {moment.utc(data.endDate).local().format('HH:mm')}
                    </div>
                }
            },
            {
                title: 'Local',
                width: '50px',
                render: data => {
                    const local = getVenueColor(data, this.state.colors);
                    return local
                        ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
                        : null
                }
            },
            {
                title: 'Idioma',
                width: '100px',
                render: data => {
                    return data?.language == 'pt_en'
                        ? 'Preferencialmente Inglês, mas pode ser em Português'
                        : data?.language == 'en'
                            ? 'Inglês'
                            : 'Português';
                }
            },
            {
                title: 'Tipo de Atendimento',
                width: '80px',
                render: data => {
                    return data?.attendingType?.name?.pt || '';
                }
            },
        ],
        rows: [],

        colors: VenueColors,
    }

    componentDidMount() {
        const { initialValues } = this.props;

        if (initialValues?._id) this.getSchedule();

        this.setState({ colors: initialValues?.colors || VenueColors })
    }

    getSchedule = async () => {
        const { dispatch, initialValues } = this.props;

        this.setState({ loading: true });
        const result = await GetSchedulesToConfirmAndRelatedWeddingInfo(initialValues?._id);
        const data = (result?.success && result?.data) || null;
        const rows = Array.isArray(data?.schedules)
            ? data?.schedules.map(m => ({ ...m, checked: false }))
            : [];

        const selectedIndex = rows.find(f => f?._id === data?.schedule?._id);
        if (selectedIndex > -1) rows[selectedIndex].checked = true;

        this.setState({
            schedule: data?.schedule || null,
            wedding: data?.wedding || null,
            weddingSummary: data?.weddingSummary || null,
            rows,
            loading: false
        });
    }

    render() {
        const { isOpen } = this.props;
        const { onClose, onSubmit, confirming } = this.props;
        const { loading } = this.state;
        const { schedule, wedding, rows, columns, weddingSummary } = this.state;

        return (
            <Modal
                visible={isOpen}
                title='Confirmar atendimento'
                maskClosable
                onCancel={onClose}
                footer={schedule?.confirmed
                    ? [
                        <Button
                            key='cancel'
                            type='default'
                            onClick={onClose}>
                            Fechar
                        </Button>
                    ]
                    : [
                        <Button
                            key='cancel'
                            type='default'
                            onClick={onClose}>
                            Fechar
                        </Button>,
                        <Button
                            key='submit'
                            type='primary'
                            loading={confirming}
                            disabled={schedule?.confirmed}
                            onClick={() => onSubmit(rows)}>
                            Confirmar
                        </Button>
                    ]} >

                <TableContent first={true}>
                    <Title>Atendimentos do casal</Title>
                    <Table
                        loading={loading}
                        columns={columns}
                        rows={rows}
                        showHeader={true}
                        emptyText='Não existem atendimentos'
                        rowKey={'_id'}
                        hasPagination={false}
                    />
                </TableContent>

                {weddingSummary && weddingSummary !== '' &&
                    <InputDiv>
                        <InputLabelDiv>Ponto de Situação</InputLabelDiv>
                        <Summary
                            dangerouslySetInnerHTML={{
                                __html: weddingSummary
                            }}
                        />
                    </InputDiv>}
            </Modal >
        );
    }
};

ConfirmScheduleModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ConfirmScheduleModal