import React, { Component } from 'react';
import Table from '../../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../../styles/BasicStyles';
import moment from 'moment';
import ReserveLogsFilters, { ReserveLogType } from './ReserveLogsFilters';
import { GetReservesLogs } from '../../../infra/requests/ReserveLogsRequests';
import { DateColumn } from '../ReservesPage';
import { VenueColors, getLocalColor, getVenueColor, getWeddingEmails, getWeddingName } from '../../../infra/services/wedding/weddingUtils';
import { LocalLabel } from '../../weddings/Styles';
import { GetTextColor } from '../../../infra/services/text/textUtils';
import { GetColorSystemByType, GetVenueColors } from '../../../infra/requests/ColorSystemRequests';
import styledComponents from 'styled-components';
import { Icon } from 'antd';

export const ScheduleColumn = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;
class ReserveLogsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: JSON.stringify({ logType: ReserveLogType.RESERVE }),

    columns: [
      {
        title: 'Data',
        key: 'createdAt',
        width: '85px',
        render: data => moment(data.createdAt).local().format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        title: 'Utilizador',
        key: 'user',
        render: data => {
          const username = data?.user?.name ? data?.user?.name : null;
          let fullname = '';
          if (username && data?.user && data?.user?.admin) fullname = `${username} (Admin)`;
          else if (username && data?.user && data?.user?.employeeAttending) fullname = `${username} (Colab. Atendimento)`;
          else if (username && data?.user && data?.user?.alternativeUser) fullname = `${username} (Convidado)`;
          else if (username && data?.user && !data?.user?.admin && !data?.user?.employee && !data?.user?.alternativeUser) {
            fullname = `${username} (Noivos)`;
          } else fullname = username;
          return <React.Fragment>
            <p style={{ marginBottom: 0 }}>{fullname || ''}</p>
            <span>{data?.user?.email ? data?.user?.email : ''}</span>
          </React.Fragment>
        }
      },
      {
        title: 'Noivos',
        render: data => getWeddingName(data.wedding),
      },
      {
        title: 'Email',
        render: data => getWeddingEmails(data.wedding)
      },
      {
        title: 'Data da Reserva',
        key: 'date',
        width: '90px',
        render: data => {
          return data?.reserve?.date
            ? <DateColumn>
              <span className='date'>{moment.utc(data?.reserve?.date).format('DD-MM-YYYY')}</span>
              <span className='weekdayName'>{moment.utc(data?.reserve?.date).locale('pt').format('dddd')}</span>
            </DateColumn>
            : ''
        },
      },
      {
        title: 'Local da Reserva',
        key: 'reserve',
        width: '100px',
        render: data => {
          const local = getVenueColor(data?.reserve, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null;
        },
      },
      {
        title: 'Ação',
        key: 'description',
        render: data => <span dangerouslySetInnerHTML={{ __html: data?.description }} />,
      }
    ],
    columnsWaitingDate: [
      {
        title: 'Data',
        key: 'createdAt',
        width: '85px',
        render: data => moment(data.createdAt).local().format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        title: 'Utilizador',
        key: 'user',
        render: data => {
          const username = data?.user?.name ? data?.user?.name : null;
          let fullname = '';
          if (username && data?.user && data?.user?.admin) fullname = `${username} (Admin)`;
          else if (username && data?.user && data?.user?.employeeAttending) fullname = `${username} (Colab. Atendimento)`;
          else if (username && data?.user && data?.user?.alternativeUser) fullname = `${username} (Convidado)`;
          else if (username && data?.user && !data?.user?.admin && !data?.user?.employee && !data?.user?.alternativeUser) {
            fullname = `${username} (Noivos)`;
          } else fullname = username;
          return <React.Fragment>
            <p style={{ marginBottom: 0 }}>{fullname || ''}</p>
            <span>{data?.user?.email ? data?.user?.email : ''}</span>
          </React.Fragment>
        }
      },
      {
        title: 'Noivos',
        render: data => getWeddingName(data.wedding),
      },
      {
        title: 'Email',
        render: data => getWeddingEmails(data.wedding)
      },
      {
        title: 'Data Lista Espera',
        key: 'date',
        width: '90px',
        render: data => {
          return data?.date
            ? <DateColumn>
              <span className='date'>{moment.utc(data?.date).format('DD-MM-YYYY')}</span>
              <span className='weekdayName'>{moment.utc(data?.date).locale('pt').format('dddd')}</span>
            </DateColumn>
            : ''
        },
      },
      {
        title: 'Local Lista Espera',
        key: 'reserve',
        width: '100px',
        render: data => {
          const local = getVenueColor(data, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null;
        },
      },
      {
        title: 'Ação',
        key: 'description',
        render: data => <span dangerouslySetInnerHTML={{ __html: data?.description }} />,
      }
    ],
    columnsInterestDate: [
      {
        title: 'Data',
        key: 'createdAt',
        width: '85px',
        render: data => moment(data.createdAt).local().format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        title: 'Utilizador',
        key: 'user',
        render: data => {
          const username = data?.user?.name ? data?.user?.name : null;
          let fullname = '';
          if (username && data?.user && data?.user?.admin) fullname = `${username} (Admin)`;
          else if (username && data?.user && data?.user?.employeeAttending) fullname = `${username} (Colab. Atendimento)`;
          else if (username && data?.user && data?.user?.alternativeUser) fullname = `${username} (Convidado)`;
          else if (username && data?.user && !data?.user?.admin && !data?.user?.employee && !data?.user?.alternativeUser) {
            fullname = `${username} (Noivos)`;
          } else fullname = username;
          return <React.Fragment>
            <p style={{ marginBottom: 0 }}>{fullname || ''}</p>
            <span>{data?.user?.email ? data?.user?.email : ''}</span>
          </React.Fragment>
        }
      },
      {
        title: 'Noivos',
        render: data => getWeddingName(data.wedding),
      },
      {
        title: 'Email',
        render: data => getWeddingEmails(data.wedding)
      },
      {
        title: 'Data Interesse',
        key: 'date',
        width: '90px',
        render: data => {
          return data?.date
            ? <DateColumn>
              <span className='date'>{moment.utc(data?.date).format('DD-MM-YYYY')}</span>
              <span className='weekdayName'>{moment.utc(data?.date).locale('pt').format('dddd')}</span>
            </DateColumn>
            : ''
        },
      },
      {
        title: 'Local Interesse',
        key: 'reserve',
        width: '100px',
        render: data => {
          const local = getVenueColor(data, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel>) : null;
        },
      },
      {
        title: 'Ação',
        key: 'description',
        render: data => <span dangerouslySetInnerHTML={{ __html: data?.description }} />,
      }
    ],
    columnsSchedules: [
      {
        title: 'Data',
        key: 'createdAt',
        width: '85px',
        render: data => moment(data.createdAt).local().format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        title: 'Utilizador',
        key: 'user',
        render: data => {
          const username = data?.user?.name ? data?.user?.name : null;
          let fullname = '';
          if (username && data?.user && data?.user?.admin) fullname = `${username} (Admin)`;
          else if (username && data?.user && data?.user?.employeeAttending) fullname = `${username} (Colab. Atendimento)`;
          else if (username && data?.user && data?.user?.alternativeUser) fullname = `${username} (Convidado)`;
          else if (username && data?.user && !data?.user?.admin && !data?.user?.employee && !data?.user?.alternativeUser) {
            fullname = `${username} (Noivos)`;
          } else fullname = username;
          return <React.Fragment>
            <p style={{ marginBottom: 0 }}>{fullname || ''}</p>
            <span>{data?.user?.email ? data?.user?.email : ''}</span>
          </React.Fragment>
        }
      },
      {
        title: 'Noivos / Reunião',
        render: data => data?.schedule?.type === 'I'
          ? data?.schedule?.name
          : getWeddingName(data.wedding),
      },
      {
        title: 'Email',
        render: data => data?.schedule?.type !== 'I'
          ? getWeddingEmails(data.wedding)
          : null
      },
      {
        title: 'Data do Atendimento',
        key: 'date',
        width: '90px',
        render: data => {
          return data?.schedule?.date
            ? <DateColumn>
              <span className='date'>{moment.utc(data?.schedule?.date).format('DD-MM-YYYY')}</span>
              <span className='weekdayName'>{moment.utc(data?.schedule?.date).locale('pt').format('dddd')}</span>
            </DateColumn>
            : ''
        },
      },
      {
        title: 'Horário do Atendimento',
        key: 'date',
        width: '90px',
        render: data => {
          return data?.schedule?.startTime && data?.schedule?.endTime
            ? <ScheduleColumn>
              {data?.schedule?.startTime}
              <Icon type="swap-right" />
              {data?.schedule?.endTime}
            </ScheduleColumn>
            : ''
        },
      },
      {
        title: 'Local ',
        key: 'weddingPlace',
        width: '100px',
        render: data => {
          const local = getVenueColor(data?.schedule, this.state.colors);
          return local ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.name}</LocalLabel> : null;
        },
      },
      {
        title: 'Ação',
        key: 'description',
        render: data => <span dangerouslySetInnerHTML={{ __html: data?.description }} />,
      }
    ],
    rows: [],
    total: 0,

    colors: VenueColors,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
    await this.getLocalColors();
  };

  getLocalColors = async () => {
    let { colors } = this.state;
    const result = await GetVenueColors();

    if (result?.success) colors = result?.data;
    this.setState({ colors });
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      let { currentPage, pageSize, filters } = this.state;

      if (Array.isArray(filters?.dates) && filters?.dates.length === 0) {
        delete filters.dates;
      }

      const result = await GetReservesLogs(currentPage, pageSize, filters);
      // console.warn('Result', result.data);

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, columnsWaitingDate, columnsInterestDate, columnsSchedules, rows, total, loading, currentPage, pageSize, filters } = this.state;
    const logType = JSON.parse(filters)?.logType;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Histórico de Ações</PageTitle>
            {logType === ReserveLogType.WAITING_DATE
              ? 'Logs da Lista de Espera'
              : logType === ReserveLogType.INTEREST
                ? 'Logs das Datas de Interesse'
                : logType === ReserveLogType.SCHEDULE
                  ? 'Logs dos Atendimentos'
                  : 'Logs das Reservas'
            }
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <ReserveLogsFilters
            loading={loading}
            queryChange={this.handleFilterChange}
          />
          <Table
            columns={logType === ReserveLogType.WAITING_DATE
              ? columnsWaitingDate
              : logType === ReserveLogType.INTEREST
                ? columnsInterestDate
                : logType === ReserveLogType.SCHEDULE
                  ? columnsSchedules
                  : columns
            }
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="warning"
            emptyText={'Não existe histórico'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          // expandedRowRender={(data) => }
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ReserveLogsPage;