import axios from 'axios';
import { notification } from 'antd';
import { auth_token_key } from './LocalStorageKeys';
import Alerts from '../../components/alert/Alert';

const REACT_APP_API = process.env.REACT_APP_API;

const mergeCommonHeaders = rest => {
  const authToken = localStorage.getItem(auth_token_key);

  return {
    ...rest,
    Authorization: `Bearer ${authToken}`
  };
};

const client = axios.create({
  baseURL: REACT_APP_API,
  responseType: 'json'
});

client.interceptors.request.use(request => {
  const headers = mergeCommonHeaders(request.headers.common);
  request.headers.common = headers;
  return request;
});

client.setupInterceptors = store => {
  client.interceptors.response.use(
    success => {
      return success.data;
    },
    error => {
      let description = 'An error has ocurred';
      if (error.response && error.response.data) {
        description = error.response.data.message || 'An error has ocurred';
      }

      // Reserves Error
      let reserveError = false;
      if (description === 'ERROR_DATE_OCCUPIED' || description === 'ERROR_CANT_CONFIRM'
        || description === 'ERROR_CANT_REACTIVATE' || description === 'ERROR_REACTIVATE_RESERVES'
        || description === 'ERROR_RESERVE_ACTIVE' || description === 'ERROR_DATE_UNAVAILABLE'
        || description === 'ERROR_TAKE_DECISION' || description === 'DUPLICATED_DATES'
        || description === 'NUMBER_RESERVES_BY_PLACE' || description === 'TIME_CREATE_EDIT_ENDED'
        || description === 'ERROR_DATE_FREE' || description === 'EXISTS_WAITING_DATE'
        || description === 'EXISTS_RESERVES_FOR_DATE' || description === 'EXISTS_INTEREST_DATE') {
        reserveError = true;
        // description = 'Não é possivel realizar a operação pois já existe uma reserva com data ocupada ou em sinalização.';
      }

      if (description === 'START_TIME_OUT_OF_RANGE') description = 'Hora inicial superior à indicada!';
      if (description === 'NO_RESERVES_OR_INTEREST') description = 'Casal sem datas de interesse ou reservas!';
      if (description === 'ERROR_NO_RESOURCES') description = 'Sem recursos para essa data e/ou local.';
      if (description === 'OTHER_SCHEDULE_BY_COUPLE_SAME_TIME') description = 'Existe outro atendimento para o casal nesse horário!';
      if (description === 'OTHER_SCHEDULE_CONFIRMED_EMPLOYEE') description = 'Existe outro atendimento confirmado nesse horário para o colaborador!';
      if (description === 'ERROR_EMPLOYEE_OCCUPIED') description = 'Colaborador ocupado!';
      if (description === 'SCHEDULE_ALREADY_CONFIRMED') description = 'Atendimento já se encontra confirmado!';
      if (description === 'ERROR_SCHEDULE_DATE_UNAVAILABLE') description = 'Dia de descanso marcado para data e/ou local!';
      if (description === 'OTHER_SCHEDULE_BY_COUPLE') description = 'Outro agendamento cofirmado por casal!';
      if (description === 'FIRST_VISIT_DONE') description = '1ª visita já foi realizada!';
      if (description === 'VISITS_BLOCKED') description = 'Casal já tem visita confirmada! Agenda de visitas bloqueada!';
      if (description === 'ORGANIZER_INTERNAL_MEETING') description = 'Existe uma reunião interna nesse horário para o colaborador!';
      if (description === 'OTHER_SCHEDULE_FOR_ORGANIZERS_SAME_TIME') description = 'Existe atendimentos nesse horário para os organizadores/CA!';
      if (description === 'OTHER_INTERNAL_MEETING_SAME_TIME') description = 'Existe outra reunião interna nesse horário!';
      if (description === 'SERVICE_SCHEDULED_BY_ORGANIZER') description = 'Existem organizadores com vagas de atendimento aos noivos!';
      if (description === 'ERROR_SERVICE_OCCUPIED') description = 'Horário preenchido!';
      if (description === 'CA_NOT_AVAILABLE') description = 'CA não se encontra disponível!';
      if (description === 'NOT_POSSIBLE') description = 'Ação inválida! O atendimento têm presença registada ou já passou!';

      if (description === 'ROOM_PLAN_COMPLETED') description = 'Lista de convidados comunicada como concluída!';
      if (description === 'ROOM_PLAN_NOT_VALIDATED') description = 'Lista de convidados por bloquear!';
      if (description === 'ERROR_CHANGE_ROOM_PLAN') description = 'Nº de mesas/cadeiras inválido para o plano atual. Altere o plano!';
      if (description === 'OLD_ROOM_PLAN_VERSION') description = 'Existem alterações mais recentes! Iremos atualizar a página.';
      if (description === 'COUPLE_TABLE_REQUIRED') description = 'Mesa de casal é obrigatória!';
      if (description === 'COUPLE_TABLE_EMPTY') description = 'Mesa de casal por preencher!';

      if (description === 'INVALID_ACTION') description = 'Ação inválida';
      if (description === 'NOT_AUTHORIZED') description = 'Não autorizado';
      if (description === 'SERVER_WEDDING_NOT_FOUND') description = 'Casamento não encontrado!';

      // notification.error({message: 'Request Error', description, duration: 3})
      if (!reserveError && description != 'SERVER_OPTION_NOT_AVAILABLE') {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: description
        });
      }

      if (error && error.response && error.response.status === 403) {
        localStorage.clear();
        // eslint-disable-next-line no-restricted-globals
        location.replace("/login");
      }

      return error.response
        ? { ...error.response.data, success: false }
        : { success: false };
    }
  );
};

export default client;
