import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import YearInput from '../../components/inputs/YearInput';
import XpertGoRangePickerInput from '../../components/inputs/XpertGoRangePickerInput';
import BaseButton from '../../components/buttons/BaseButton';
import Alerts from '../../components/alert/Alert';

class AttendingWaitingDateFilters extends Component {
  timeout = 0;
  state = {
    search: '',
    weddingPlace: '',
    // date: '',
    dates: [],
    year: null,
    loading: false
  };

  componentDidMount = async () => {
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(query);
  };

  render() {
    const { locals } = this.props;
    const { search, weddingPlace, date, dates, year, loading } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              input={{
                value: search,
                onChange: event => {
                  if (event.target.value && event.target.value.trim() !== '') {
                    this.setState({ search: event.target.value });
                  } else this.onInputChange('search', event.target.value, 600)
                }
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar"
              placeholder="Procurar por emails"
            />
          </Col>
          {/* <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <DateInput
              label="Data"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{ valid: true }}
            />
          </Col> */}
          <Col xs={24} md={6}>
            <XpertGoRangePickerInput
              label="Intervalo de datas"
              allowClear={true}
              placeholder="Escolher datas"
              input={{
                value: dates,
                onChange: value => {
                  if (Array.isArray(value) && value.length > 0) {
                    this.setState({ dates: value });
                    this.setState({ year: null });
                  } else {
                    this.onInputChange('dates', value);
                    this.onInputChange('year', null);
                  }
                }
              }}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={3} xl={2}>
            <YearInput
              label="Ano"
              disabled={loading}
              placeholder="Escolher ano"
              input={{
                value: year,
                onChange: value => {
                  if (value) {
                    this.setState({ dates: [] });
                    this.setState({ year: value });
                  } else {
                    this.onInputChange('dates', []);
                    this.onInputChange('year', null);
                  }
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Local"
              disabled={loading}
              allowClear
              placeholder="Escolher local"
              data={locals}
              input={{
                value: weddingPlace,
                onChange: value => {
                  if (value) {
                    this.setState({ weddingPlace: value });
                  } else this.onInputChange('weddingPlace', value);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={1} xl={1} style={{ height: 58, display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <BaseButton
              type={'primary'}
              icon={'filter'}
              text={'Filtrar'}
              disabled={loading}
              onClick={() => {
                if(search && search.trim() !== '') {
                  this.constructQuery();
                } else {
                  Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Insira o email do/a noivo/noiva'
                  });
                }
              }}
            />
          </Col>
        </Row>
      </TableFilterSection >
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(AttendingWaitingDateFilters);
