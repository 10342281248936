import styled from 'styled-components';

export const DetailBlockContainer = styled.div`
  padding: 5px 10px;
  background-color: #faf9f9;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
`;

export const Question = styled.div`
  color: #000000;
  font-size: ${p => (p.small ? '12px' : '13px')};
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 50%;
`;

export const Answer = styled.div`
  color: #000000;
  font-size: ${p => (p.small ? '12px' : '13px')};
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding-left: 10px;
`;

export const Title = styled.div`
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

export const Subtitle = styled.div`
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

export const SubtitleValue = styled.div`
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding-left: 35px;
`;
