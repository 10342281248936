import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Tabs, BackTop, Dropdown, Menu, Icon, Button, Tooltip, Tag } from 'antd';
import BaseButton from '../../components/buttons/BaseButton';
import { GetArchived, GetArchivedByWedding, GetArchivedOurGuestsEditor, GetArchivedQuestionForBudget } from '../../infra/requests/ArchivedRequests';
import {
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  SpinLoading,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { GetConfigByArchived, GetConfigByWedding } from '../../infra/requests/ConfigsRequests';
import { GetQuestionForBudget } from '../../infra/requests/QuestionsRequests';
import { calculateExtraTables, checkExtraCostByTables, convertNumberToLetter, getSummaryFoodRestrictions } from '../../process/guests/utilRoomPlan';
import { GuestAgeEnum, TablePurposeEnum } from '../../components/guestMap/GuestTablesComponent';
import { DecorContainer } from '../../process/decoration/DecorationStyles';
import { CostExtraMessage, GuestMapContentStyle, GuestMapStats, RoomEditor, RoomEditorContainer, RoomEditorContent, RoomPlanContentStyle, StatsContentRow, TableSearchCode, Toolbar } from '../../process/guests/RoomPlanStyles';
import { StatsEnum } from '../../process/guests/ManageWeddingGuestMapPage';
import { Link } from 'react-scroll';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';
import { GetOurGuestsEditor, SaveRoomPlanImage } from '../../infra/requests/WeddingProcessRequests';
import { hasNotes } from '../../infra/services/notes/FindNotes';
import { auth_token_key } from "../../infra/config/LocalStorageKeys";
import Alerts from '../../components/alert/Alert';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import CostExtraIcon from '../../assets/icons/extra_cost.jpg';
import ArchivedGuestTablesComponent from './components/ArchivedGuestTablesComponent';
import RoomPlanEditor from '../../process/guests/RoomPlanEditor';
const { TabPane } = Tabs;

const REACT_APP_API = process.env.REACT_APP_API;

class ArchivedGuestMap extends Component {
  roomEditor = null;

  state = {
    loading: false,
    ready: false,
    guestMap: true,

    info: {},

    tables: [],
    ourGuest: {},

    notes: '',
    kitchenNotes: '',
    notesActiveTab: '1',
    invalidKitchenNotes: false,

    exporting: false,
  };

  componentDidMount() {
    const { history, location } = this.props;
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    if (!inRoomPlanTest()) return history.goBack();

    const guestMap = location?.pathname?.includes('/roomPlan')
      ? false
      : true;

    this.setState({ guestMap, loading: true }, () => {
      if (!guestMap) {
        this.roomEditor = new window.RoomEditor('editor', 'pt');
        this.roomEditor.translationSystem.setActiveLanguage("pt");
      }

      this.getArchived();
    });
  }

  componentWillUnmount() {
    if (this.roomEditor) {
      this.roomEditor.destroy();
    }
  }

  getArchived = async () => {
    const {
      match: { params },
      history,
    } = this.props;
    const resultArchived = await GetArchivedByWedding(params.id);

    const dataRoomEditor = {
      roomPlan: {
        imageSrc: null,
        x: 0,
        y: 0,
        scale: 0
      },
      kitchenNotes: '',
      tables: [],
      foodRestrictions: [],
    };

    const { data, success } = await GetArchivedOurGuestsEditor(params.id);
    if(success) {
      if (this.roomEditor) {
        this.roomEditor.setWeddingPlace(resultArchived?.data?.wedding_place);
      }

      let tables = success ? (data?.tables || []) : [];
      if (tables?.length > 0) {
        tables = tables.map(m => ({ ...m, tableType: m?.tableClass }));
      }
      let answerSeatId = success ? data?.answerSeatId : null;
  
      // Set data for room editor
      dataRoomEditor.roomPlan = {
        imageSrc: `${process.env.REACT_APP_IMAGES_URL}${data?.ourGuest?.bgRoomPlan?._id}`,
        x: data?.ourGuest?.roomPlanPosition?.x || 0,
        y: data?.ourGuest?.roomPlanPosition?.y || 0,
        scale: data?.ourGuest?.roomPlanPosition?.scale || 0,
        horizontalCenterOfRoom: data?.ourGuest?.roomPlanHorizontalCenterOfRoom || 0,
        constraintPoints: data?.ourGuest?.roomPlanConstraintPoints || [],
        zones: data?.ourGuest?.roomPlanZones || [],
        spaceBetweenTables: data?.spaceBetweenTables || 0,
      };
      dataRoomEditor.kitchenNotes = data?.ourGuest?.kitchenNotes || '';
      dataRoomEditor.tables = tables;
  
      if (this.roomEditor) this.roomEditor.deserializeEditor(dataRoomEditor);
  
      this.setState({
        ready: true,
        loading: false,
        info: resultArchived?.data || {},
        ourGuest: data?.ourGuest || {},
        notes: data?.ourGuest?.notes || '',
        kitchenNotes: data?.ourGuest?.kitchenNotes || '',
        answerSeatId,
        tables
      }, () => {
        setTimeout(() => {
          if (this.roomEditor) {
            this.roomEditor.setMode("READ_ONLY");
            // this.roomEditor.setPreviewGuestsActive(true);
          }
  
          if (answerSeatId) {
            if (this.roomEditor) this.roomEditor.setGuestsMode('SEAT');
          } else {
            if (this.roomEditor) this.roomEditor.setGuestsMode('TABLE');
          }
        }, 1500);
      });
    } else {
      history.goBack();
    }
  };

  changeNotesActiveTab = (e) => {
    this.setState({ notesActiveTab: e });
  }

  /** Stats */
  getFoodRestrictionsStats = () => {
    const { tables } = this.state;
    const seatsWithFoodRestrictions = tables?.length > 0
      ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '' && f?.foodRestrictions?.length > 0)
      : [];

    return seatsWithFoodRestrictions?.length > 0
      ? getSummaryFoodRestrictions(seatsWithFoodRestrictions)
      : `<div>Sem restrições alimentares</div>`;
  }

  getStats = (type) => {
    const { tables } = this.state;
    const seats = tables?.length > 0
      ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '')
      : [];

    let count = 0;
    switch (type) {
      case StatsEnum.ADULT:
        count = seats.filter(f => f?.guestAge == GuestAgeEnum.ADULT)?.length;
        break;
      case StatsEnum.NEWBORN:
        count = seats.filter(f => (f?.guestAge == GuestAgeEnum.NEWBORN || f?.guestAge == GuestAgeEnum.BABY))?.length;
        break;
      case StatsEnum.CHILD:
        count = seats.filter(f => f?.guestAge == GuestAgeEnum.CHILD)?.length;
        break;
      case StatsEnum.STAFF:
        const seatsStaff = tables?.find(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose == TablePurposeEnum.STAFF)?.seats?.filter(f => f?.guestName?.trim() != '');
        count = seatsStaff?.length || 0;
        break;
      case StatsEnum.TABLES:
        count = tables.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF)
          .filter(f => f?.seats?.length > 0 && f?.seats.find(f2 => f2?.guestName?.trim() != ''))
          .map(m => m?.value).reduce((a, b) => a + b, 0);
        break;
      case StatsEnum.GUESTS:
        count = seats?.length || 0;
        break;
      default:
        count = 0;
        break;
    }
    return count;
  }

  getPax = () => {
    const countAdults = this.getStats(StatsEnum.ADULT);
    const countChildren = this.getStats(StatsEnum.CHILD);
    const countStaff = this.getStats(StatsEnum.STAFF);
    return (countAdults + (countChildren / 2) + (countStaff / 2)) || 0;
  }

  getAvgPaxTables = () => {
    const totalSeatsFilled = this.getStats(StatsEnum.GUESTS);
    const totalTablesActive = this.getStats(StatsEnum.TABLES);
    return (totalSeatsFilled / totalTablesActive) || 0;
  }

  getChefFoodRestrictions = () => {
    const { foodRestrictions } = this.state;
    return `Restrições alimentares: ${foodRestrictions?.filter(f => f?.chefChoice && f?.acronym).map(m => m?.acronym['pt']).join(', ')}`;
  }

  // Export Methods
  exportRoomPlan = async () => {
    const { match: { params } } = this.props;
    this.setState({ exporting: true });
    // const result = DownloadGuestPlan(wedding.wedding._id);
    const authToken = localStorage.getItem(auth_token_key);
    let filename = 'Distribuição de Convidados e Plano de Sala';

    fetch(
      `${REACT_APP_API}/reports/process/${params.id}/roomPlan`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        },
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace(/_/g, ' ')?.trim().replace('.pdf', '');
            name.concat('.pdf');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({ exporting: false });
          });
        } else {
          resp.json().then(obj => {
            this.setState({ exporting: false });
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => {
        this.setState({ exporting: false });
        console.error(e);
      });
  }

  // uploadRoomPlanToDrive = async () => {
  //   const { match: { params } } = this.props;

  //   if (this.roomEditor) {
  //     this.setState({
  //       exporting: true,
  //       loading: true
  //     });

  //     const resultAuthDrive = await AuthGoogleDrive();
  //     // console.warn('resultAuthDrive', resultAuthDrive);

  //     if (!resultAuthDrive?.data?.active) {
  //       localStorage.setItem('googleReturnUrl', window.location.pathname);
  //       const newWindow = window.open(resultAuthDrive?.data.authUrl, '_self', 'noopener,noreferrer')
  //       if (newWindow) newWindow.opener = null;
  //       this.setState({
  //         exporting: false,
  //         loading: false
  //       });
  //       return;
  //     }

  //     try {
  //       const image = await this.roomEditor.getImage();
  //       const result = await SaveRoomPlanImage(params.id, { image });

  //       if (result?.success) {
  //         const resultUpload = await UploadRoomPlanToDrive(params.id, JSON.stringify({}));
  //         // console.warn('resultUpload', resultUpload);

  //         if (resultUpload?.success) {
  //           this.setState({
  //             exporting: false,
  //             loading: false
  //           });
  //           return Alerts.new({
  //             type: 'success',
  //             title: 'Sucesso',
  //             text: 'Upload com sucesso para o Google Drive!'
  //           });
  //         } else {
  //           this.setState({
  //             exporting: false,
  //             loading: false
  //           });
  //         }
  //       } else {
  //         this.setState({
  //           exporting: false,
  //           loading: false
  //         });
  //         if (result?.data != 'ROOM_PLAN_NOT_VALIDATED') {
  //           return Alerts.new({
  //             type: 'error',
  //             title: 'Erro de servidor',
  //             text: result?.data || 'Tente novamente mais tarde.'
  //           });
  //         }
  //       }
  //     } catch (error) {
  //       this.setState({
  //         exporting: false,
  //         loading: false
  //       });
  //       return Alerts.new({
  //         type: 'error',
  //         title: 'Erro',
  //         text: 'O editor ainda está a carregar. Aguarde e tente novamente.'
  //       });
  //     }
  //   } else {
  //     console.error('editor not found');
  //   }
  // }

  exportGuest = async () => {
    const { match: { params } } = this.props;
    // const result = DownloadGuestPlan(wedding.wedding._id);
    this.setState({ exporting: true });
    const authToken = localStorage.getItem(auth_token_key);
    let filename = 'Distribuição de Convidados';

    fetch(
      `${REACT_APP_API}/reports/process/${params.id}/guestPlan?filter=${JSON.stringify({})}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace(/_/g, ' ')?.trim().replace('.pdf', '');
            name.concat('.pdf');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({ exporting: false });
          });
        } else {
          resp.json().then(obj => {
            this.setState({ exporting: false, });
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          exporting: false,
          loading: false
        });
      });
  }

  exportFlyers = async () => {
    const { match: { params } } = this.props;
    this.setState({ exporting: true });
    // const result = DownloadGuestFlyers(wedding.wedding._id);
    const authToken = localStorage.getItem(auth_token_key);
    let filename = 'Folhetos de Mesa';

    fetch(
      `${REACT_APP_API}/reports/process/${params.id}/guestFlyers?filter=${JSON.stringify({})}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          if (filename.split(';')?.length > 0) {
            filename = filename.split(';')[0];
          }
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace('.xls', '');
            name.concat('.xls');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({ exporting: false });
          });
        } else {
          resp.json().then(obj => {
            this.setState({ exporting: false });
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          exporting: false,
          loading: false
        });
      });
  }

  onSelectExportMenu = (value) => {
    const itemSelected = parseInt(value.key);

    // Export room plan
    if (itemSelected === 1) this.exportRoomPlan();
    // Upload room plan
    // else if (itemSelected === 2) {
    //   if (this.roomEditor) this.uploadRoomPlanToDrive();
    //   else return;
    // }
    // Export guest map
    else if (itemSelected === 3) this.exportGuest();
    // Export flyers
    else if (itemSelected === 4) this.exportFlyers();
    // Upload flyers
    // else if (itemSelected === 5) this.uploadFlyersToDrive();
  }

  exportMenu = (
    <Menu onClick={this.onSelectExportMenu}>
      <Menu.Item key={1}>
        <Icon type="download" />
        Dist. Convidados + Plano
      </Menu.Item>
      {/* {!this.state.guestMap && <Menu.Item key={2}>
        <Icon type="cloud-upload" />
        Dist. Convidados + Plano
      </Menu.Item>} */}
      <Menu.Item key={3}>
        <Icon type="download" />
        Dist. Convidados
      </Menu.Item>
      <Menu.Item key={4}>
        <Icon type="download" />
        Folhetos de Mesa
      </Menu.Item>
      {/* <Menu.Item key={5}>
        <Icon type="cloud-upload" />
        Upload Folhetos de Mesa
      </Menu.Item> */}
    </Menu>
  );

  // Toolbar
  getToolbar = () => {
    const { guestMap, ourGuest, exporting, loading } = this.state;
    return <React.Fragment>
      <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
        <Toolbar>
          <div className='toolbar-input'>
            <Dropdown disabled={loading}
              overlay={this.exportMenu}>
              <Button type='default'>
                {exporting ? <Icon type="loading" /> : <Icon type="file-protect" />}
                Exportar
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
          {!loading && <div className='toolbar-input' style={{ width: 170 }}>
            <Tag color='gold' style={{ height: 32, display: 'flex', alignItems: 'center', width: 'fit-content' }}>{ourGuest?.roomPlanTemplate?.name || ''}</Tag>
          </div>}
        </Toolbar>
      </Col>
      <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
        <Toolbar right={true}>
          <div className='toolbar-input'>
            <BaseButton
              type={'default'}
              text={guestMap ? 'Plano de Sala' : 'Dist. Convidados'}
              onClick={() => guestMap ? this.goToRoomPlan() : this.goToGuestMap()}
            />
          </div>
        </Toolbar>
      </Col>
    </React.Fragment>;
  }

  // Navigation
  goToRoomPlan = () => {
    const { match: { params }, history } = this.props;
    return history.push(`/archived/${params.id}/roomPlan`);
  };

  goToGuestMap = () => {
    const { match: { params }, history } = this.props;
    return history.push(`/archived/${params.id}/guestMap`);
  }

  handleOnCancel = () => {
    const { history, match: { params } } = this.props;
    return history.push(`/archived/wedding/${params.id}`);
  };

  render() {
    const { ready, loading, exporting } = this.state;
    const { ourGuest, info } = this.state;
    const { guestMap } = this.state;
    const { notesActiveTab, notes, kitchenNotes } = this.state;
    const { tables } = this.state;
    const weddingInfo = info && info.about ? info?.about : info?.wedding;
    const hasExtraCost = checkExtraCostByTables(tables) > 0 ? true : false;
    const extraTables = calculateExtraTables(tables);

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{guestMap ? 'Distribuição de convidados' : 'O vosso plano de sala'}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            {/* <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding?.notes, 'GUESTS')}
            /> */}
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <DecorContainer containerId="guest-map" printed={false}>
          <BackTop />
          {guestMap
            ? <GuestMapContentStyle printed={ourGuest?.validatedRoomPlan}>
              {loading && <SpinLoading />}
              <div style={{ display: loading ? 'none' : 'block' }}>
                <Row gutter={[12, 12]} style={{ display: 'flex', alignContent: 'stretch', flexWrap: 'wrap' }}>
                  {this.getToolbar()}
                  {/* <Col xs={24}>
                    <Tooltip title={this.getChefFoodRestrictions()}>
                      <Icon type="info-circle" style={{ marginRight: 5 }} />
                      As alterações de ingredientes em caso de restrições alimentares ficarão a cargo do chefe.
                    </Tooltip>
                  </Col> */}
                  <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                    <RoomEditorContent noBorder={true}>
                      <Tabs type="card" onChange={this.changeNotesActiveTab} activeKey={notesActiveTab}>
                        <TabPane tab="Notas Cozinha" key="1">
                          <TextAreaInput
                            // label={'Notas'}
                            placeholder={'Escreva aqui as suas notas para a cozinha'}
                            guestMap={true}
                            fillError={true}
                            noLabel={true}
                            minRows={6}
                            disabled={true}
                            meta={{ invalid: false, submitFailed: false }}
                            input={{
                              value: kitchenNotes,
                            }}
                          />
                        </TabPane>
                        <TabPane tab="Notas" key="2">
                          <TextAreaInput
                            // label={'Notas'}
                            placeholder={'Escreva aqui as suas notas sobre os convidados'}
                            guestMap={true}
                            noLabel={true}
                            minRows={6}
                            disabled={true}
                            meta={{ invalid: false, submitFailed: false }}
                            input={{
                              value: notes,
                            }}
                          />
                        </TabPane>
                      </Tabs>
                    </RoomEditorContent>
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                    <RoomEditorContent>
                      <h3 className="title">Contagem automática</h3>
                      <span className="text">Lista de restrições alimentares</span>
                      <div className="info-content" dangerouslySetInnerHTML={{
                        __html: this.getFoodRestrictionsStats(),
                      }}>
                      </div>
                    </RoomEditorContent>
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex' }}>
                    <GuestMapStats avgError={(this.getAvgPaxTables() < 10) ? true : false}>
                      <h3 className="title">Contagem automática</h3>
                      <span className="text">A contagem mínima enviada até 15 dias antes será a contagem a ter em consideração.</span>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>Maior de 8 anos</div>
                        <div className='stats-value'>{this.getStats(StatsEnum.ADULT)}</div>
                      </StatsContentRow>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>Dos 3 aos 7 anos</div>
                        <div className='stats-value'>{this.getStats(StatsEnum.CHILD)}</div>
                      </StatsContentRow>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>Dos 0 a 2 anos</div>
                        <div className='stats-value'>{this.getStats(StatsEnum.NEWBORN)}</div>
                      </StatsContentRow>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>Staff</div>
                        <div className='stats-value'>{this.getStats(StatsEnum.STAFF)}</div>
                      </StatsContentRow>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>Total mesas</div>
                        <div className='stats-value'>{this.getStats(StatsEnum.TABLES)}</div>
                      </StatsContentRow>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>Total convidados</div>
                        <div className='stats-value'>{this.getStats(StatsEnum.GUESTS)}</div>
                      </StatsContentRow>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>Média pessoas/mesa</div>
                        <div className='stats-value avg-value'>{this.getAvgPaxTables()?.toFixed(2)}</div>
                      </StatsContentRow>
                      <StatsContentRow global={true}>
                        <div className='stats-description'>PAX total</div>
                        <div className='stats-value total-pax'>{this.getPax()?.toFixed(2)}</div>
                      </StatsContentRow>
                    </GuestMapStats>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ minHeight: '100%', display: 'flex' }}>
                    {hasExtraCost && <CostExtraMessage>
                      <img src={CostExtraIcon} alt="icon" />
                      <div className="content">
                        <p className="title">Média de pessoas por mesa inferior a 10</p>
                        {/* <p>Isto irá implicar um custo extra por cada mesa adicionada depois do total de 14 mesas ser ultrapassado.</p> */}
                        <p><b>Custo extra a ser aplicado:</b> <Tag color="gold">{extraTables} mesas</Tag></p>
                      </div>
                    </CostExtraMessage>}
                    {/* <RoomEditorContent>
                    <h3 className="title">Plano de Sala</h3>
                    <span className="text">Posição das mesas no plano de sala.</span>
                    <RoomEditorContainer guestMap={true}>
                      {(loading || !ready) && <div className='backdrop'>
                        <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                      </div>}
                      <RoomEditor guestMap={true} id='editor'></RoomEditor>
                    </RoomEditorContainer>
                  </RoomEditorContent> */}
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex' }}>
                    <RoomEditorContent>
                      <h3 className="title">Mesas do plano</h3>
                      <div className="tables-search-content">
                        {tables.filter(f => !f?.removeBecauseOfOvalL)?.map((m, index) => (
                          <Link to={`anchor-${m?.code}`} isDynamic={true} spy={true} smooth={'easeInOutQuint'} offset={-140}>
                            <TableSearchCode active={m?.active}>{convertNumberToLetter(m?.code)}</TableSearchCode>
                          </Link>
                        ))}
                      </div>
                    </RoomEditorContent>
                  </Col>
                  <Col xs={24}>
                    <ArchivedGuestTablesComponent tables={tables} />
                  </Col>
                </Row>
              </div>
            </GuestMapContentStyle>
            : <RoomPlanContentStyle printed={ourGuest?.validatedRoomPlan}>
              <Row gutter={12}>
                {this.getToolbar()}
              </Row>
              <Row gutter={12} style={{ marginTop: 10 }}>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                  <RoomEditorContainer>
                    {(loading || !ready) && <div className='backdrop'>
                      <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                    </div>}
                    <RoomEditor id='editor'></RoomEditor>
                  </RoomEditorContainer>
                </Col>
              </Row>
            </RoomPlanContentStyle>}
        </DecorContainer>
      </React.Fragment>
    );
  }
}

export default ArchivedGuestMap;
