import React, { useState, useEffect } from 'react';
import { Prompt } from "react-router";
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import SectionResponse from '../ChoicesPage/SectionResponse';
import { bindActionCreators } from 'redux';
import { Field, FormSection, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import {
  SectionContentStyle,
  SectionTitle,
  Counting,
  QuestionTitle,
  QuestionTitleContainer,
  QuestionIcon,
  AddBillingContainer,
  SaveAboutUsContainer,
  AboutUsTitle,
  FormContainer,
  CodeP,
  CodeInput,
  SaveGuestContainer
} from '../ChoicesPage/SectionStyles';
import SectionModal from '../ChoicesPage/SectionModal';
import { AnswerQuestion, getWeddingInfo } from '../../infra/requests/WeddingProcessRequests';
import { updateQuestionAction, AddWedding } from '../../redux/wedding/wedding.actions';
import Billings from './components/Billings';
import WeddingDocuments from './components/WeddingDocuments';
import AddBillingModal from './AddBillingModal';
import BaseButton from '../../components/buttons/BaseButton';
import TextInput from '../../components/inputs/TextInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import SelectInput from '../../components/inputs/SelectInput';
import SelectLanguageInput from '../../components/inputs/SelectLanguageInput';
import ImageInput from '../../components/inputs/ImageInput';
import { brideFields, groomFields, contactFields, guestFields } from './FormFields';
import { BaseForm } from '../../styles/BasicStyles';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { Icon, Input, Modal, Popconfirm, Tooltip, message } from 'antd';
import { InputLabelDiv } from '../../components/inputs/InputStyles';
import { getAnswerPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import DraftInput from '../../components/inputs/DraftInput';
import Alerts from '../../components/alert/Alert';
import { GenerateAccessCode, MngGuestAccess, NotifyWeddingGuest } from '../../infra/requests/WeddingRequests';
import { Form } from '../exportation/ExportationStyles';
import { RelationToCouple } from '../../weddingday/weddings/CreateWeddingPage';
const { confirm } = Modal;

const PrintedEdit = <div>
  <div>Tem a certeza que pretende editar a resposta?</div>
  <b>Este processo já foi impresso!</b>
</div>

const GenerateCode = (
  <div>
    <div>Têm a certeza que pretende criar um novo código de acesso?</div>
    <div>Ao criar um novo código de acesso, o convidado deixará de ter acesso ao casamento e
      à informação do casal a partir do código anterior.</div>
  </div>
);

const NotifyGuest = (
  <div>
    <div>Têm a certeza que pretende notificar o convidado?</div>
    <div>Irá ser enviado o código de acesso ao casamento e/ou à informação do casal ao convidado.</div>
  </div>
);

const BlockAccess = (
  <div>
    <div>Têm a certeza que pretende bloquear o acesso do convidado?</div>
    <div>O utilizador convidado deixará de ter acesso ao casamento e/ou a toda a informação relacionada com o casal.</div>
  </div>
);

const UnblockAccess = (
  <div>
    <div>Têm a certeza que pretende desbloquear o acesso do convidado?</div>
    <div>O utilizador convidado poderá aceder ao casamento e/ou informação do casal, a partir do código de acesso que será criado.</div>
  </div>
);

const AboutContent = ({
  section = { chapters: [] },
  dispatch,
  wedding,
  editBilling,
  deleteBilling,
  showModal,
  editBillingIndex,
  addModalSubmit,
  closeAddModal,
  openAddModal,
  onSubmit,
  organizers,
  handleSubmit,
  location: { hash },
  dirty,
  printed,
  notesOpen,
  updateWeddingInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [showEditQuestionModal, toggleEditQuestionModal] = useState(false);
  const [editQuestion, setEditQuestion] = useState(undefined);
  const [confirming, setConfirming] = useState(false);
  const [mngAccess, toogleMngAccess] = useState(false);
  const [generatingCode, toogleGenerateCode] = useState(false);
  const [blockGuestAccess, setBlockGuestAccess] = useState(false);
  const [weddingAccessCode, setWeddingAccessCode] = useState('');
  const [showGuest, toogleShowGuest] = useState(false);
  const [sendingEmail, toogleSendEmail] = useState(false);
  const [plusChapters, setPlusChapters] = useState(3);

  useEffect(() => {
    //If there's a ID, we have to scroll to that question
    if (hash) {
      const questionID = hash.substr(1);

      if (questionID) {
        const questionTop = document.getElementById(questionID)
          ? document.getElementById(questionID).scrollIntoView(true)
          : 0;
        window.scrollTo({ top: questionTop, behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBlockGuestAccess(wedding?.wedding?.blockGuestAccess || false);
    setWeddingAccessCode(wedding?.wedding?.code || '');
    const showGuest = wedding?.wedding?.alternativeGuest && wedding?.wedding?.alternativeGuest?.email && wedding?.wedding?.alternativeGuest?.email !== '' ? true : false;
    toogleShowGuest(showGuest);
    setPlusChapters(showGuest ? 3 : 2);
  }, [wedding?.wedding])

  const openModal = (question) => {
    dispatch(initialize('manage_answers_form', {}));

    if (question?.answer) {
      dispatch(
        initialize('manage_answers_form', {
          ...question?.answer,
          question_obs: question?.answer?.observations !== ''
        })
      );
    }

    setEditQuestion(question);
    toggleEditQuestionModal(true);
  };

  const closeEditQuestionModal = () => {
    toggleEditQuestionModal(false);
    setEditQuestion(undefined);
  };

  const editQuestionModalSubmit = async (values) => {
    if (printed) {
      confirm({
        ...getAnswerPrintedConfirmation(),
        onOk: () => { saveQuestionModal(values); },
        onCancel: () => { closeEditQuestionModal(); },
      });
    } else saveQuestionModal(values);
  }

  const saveQuestionModal = async values => {
    setLoading(true);
    let payload;

    // check if question is "Métodos de pagamento: após a cerimónia" (5e73610729196b814fb9887f)
    if (editQuestion && editQuestion._id === '5e73610729196b814fb9887f') {
      let response = values.response;
      if (values.response && Array.isArray(values.response) && values.response.length > 0) {
        response = [{
          qtd: values.response[0].qtd,
          option: editQuestion.options?.find(f => f.couplePaymentDate && f._id === values?.response[0].id),
          id: values.response[0].id,
          confirmed: values.response[0].confirmed ? values.response[0].confirmed : 'true'
        }]
      }

      payload = FlattenToFormData({
        question: editQuestion._id,
        response,
      })
    } else {
      payload = FlattenToFormData({ ...values, question: editQuestion._id });
    }

    const { data, success } = await AnswerQuestion(wedding.wedding._id, payload);

    if (success) {
      dispatch(updateQuestionAction(section.tag, data));
      updateWeddingInfo(data);
    }

    setLoading(false);
    closeEditQuestionModal();
  };

  const confirmPaymentMethod = async (question, checked) => {
    setConfirming(true);
    if (question?.answer?.response && question?.answer?.response[0]) {
      question.answer.response[0].confirmed = checked
        ? 'true'
        : 'false';
    }

    const answer = question.answer;

    const payload = FlattenToFormData({
      question: answer.question,
      response: [{
        qtd: answer.response[0].qtd,
        option: question.options?.find(f => f.couplePaymentDate && f._id === answer?.response[0].id),
        id: answer.response[0].id,
        confirmed: answer.response[0].confirmed ? answer.response[0].confirmed : 'true'
      }],
    })

    const { data, success } = await AnswerQuestion(wedding.wedding._id, payload);

    if (success) {
      dispatch(updateQuestionAction(section.tag, data));
      updateWeddingInfo(data);
    }
    setConfirming(false);
  };

  const confirmMngGuestAccess = () => {
    if (blockGuestAccess) {
      confirm({
        title: 'Tem a certeza que pretende enviar email ao convidado com o código de acesso?',
        content: 'Ao permitir o acesso do convidado à sua informação, este terá permissão para gerir o vosso casamento, as vossas reservas e datas em lista de espera.',
        okText: 'Desbloquear e Enviar',
        cancelText: 'Desbloquear',
        onOk: () => {
          mngGuestAccess(true);
        },
        onCancel: () => {
          mngGuestAccess();
        },
      });
    } else mngGuestAccess();

  }

  const mngGuestAccess = async (sendEmail = false) => {
    toogleMngAccess(true);
    let { data, success } = await MngGuestAccess(wedding.wedding._id, { sendEmail });

    if (success && data) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: `Convidado ${data?.blockGuestAccess ? 'bloqueado' : 'desbloqueado'}!`
      });
      setBlockGuestAccess(data?.blockGuestAccess || false);
      setWeddingAccessCode(data?.code || '');
      toogleMngAccess(false);
    } else {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao gerir o acesso do convidado. Tente novamente!'
      });
      toogleMngAccess(false);
    }
  }

  const copyCode = () => {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(weddingAccessCode);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = weddingAccessCode;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Texto copiado!');
  }

  const notifyGuestCode = async () => {
    toogleSendEmail(true);
    let { data, success } = await NotifyWeddingGuest(wedding.wedding._id);

    if (success && data) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Convidado notificado com sucesso!'
      });
      toogleSendEmail(false);
    } else {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: data || 'Erro genérico. Tente novamente!'
      });
      toogleSendEmail(false);
    }
  }

  const confirmGenerateCode = () => {
    confirm({
      title: 'Tem a certeza que pretende enviar email ao convidado com o novo código de acesso?',
      content: 'Ao permitir o acesso do convidado à sua informação, este terá permissão para gerir o vosso casamento, as vossas reservas e datas em lista de espera.',
      okText: 'Criar e Enviar',
      cancelText: 'Criar',
      onOk: () => {
        generateCode(true);
      },
      onCancel: () => {
        generateCode();
      },
    });
  }

  const generateCode = async (sendEmail = false) => {
    toogleGenerateCode(true);
    let { data, success } = await GenerateAccessCode(wedding.wedding._id, { sendEmail });

    if (success && data) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: `Criado novo código de acesso. ${sendEmail ? 'Convidado notificado com sucesso!' : ''}`
      });
      setWeddingAccessCode(data?.code || '');
      toogleGenerateCode(false);
    } else {
      Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao criar novo código de acesso. Tente novamente!'
      });
      toogleGenerateCode(false);
    }
  }

  return (
    <SectionContentStyle id="section-container" name="section-container" printed={printed} notesOpen={notesOpen}>
      <Element key="about_us" name="about_us">
        <SectionTitle>
          Sobre nós
          <Counting>1 de {section.chapters.length + plusChapters}</Counting>
        </SectionTitle>
        <Element>
          <FormContainer singleColumn>
            <Prompt
              when={dirty}
              message='Está prestes a sair sem guardar as alterações. Deseja continuar?'
            />
            <BaseForm onSubmit={handleSubmit(onSubmit)}>
              <Field
                component={SelectLanguageInput}
                name={'language'}
                label={'Idioma de comunicação (plataforma)'}
                placeholder={'Selecionar idioma'}
              />

              <Field
                component={SelectLanguageInput}
                name={'organizerLanguage'}
                label={'Preferência de idioma nas comunicações (email e reunião com organizador)'}
                placeholder={'Selecionar idioma'}
                languages={[
                  { code: 'pt', name: 'Português' },
                  { code: 'en', name: 'Inglês' },
                  { code: 'pt_en', name: 'Português e Inglês' }
                ]}

              />
              <Field
                component={SelectLanguageInput}
                name={'weddingDayLanguage'}
                label={'Idioma no dia do casamento *'}
                placeholder={'Selecionar idioma'}
                languages={[
                  { code: 'pt', name: 'Português' },
                  { code: 'en', name: 'Inglês' },
                  { code: 'pt_en', name: 'Português e Inglês' }
                ]}
              />

              <AboutUsTitle id="organizer" subsection>Organização</AboutUsTitle>
              <Field
                component={SelectInput}
                mode="multiple"
                name={'organizer'}
                label={'Organizador *'}
                placeholder={'Selecionar organizador'}
                type="select"
                data={organizers}
                dataKey={'_id'}
                dataLabel={'name'}
              />
              <Field
                component={TextInput}
                name={'coordinator'}
                type="text"
                label={'Coordenador'}
                placeholder={'Insira o nome do coordenador'}
              />

              <FormSection name="">
                <AboutUsTitle subsection>
                  Reuniões com o Organizador
                </AboutUsTitle>
                <Field
                  component={DraftInput}
                  name="meetings"
                  placeholder="Introduza aqui os horários e locais das reuniões"
                  noLabel={true}
                  minRows={10}
                  toolbarOnFocus={false}
                  toolbar='notes'
                />
              </FormSection>

              <FormSection name="">
                <AboutUsTitle subsection>
                  Visitas
                </AboutUsTitle>
                <Field
                  component={DraftInput}
                  name="visits"
                  placeholder="Introduza aqui as informações das visitas"
                  noLabel={true}
                  minRows={10}
                  toolbarOnFocus={false}
                  toolbar='notes'
                />
              </FormSection>

              <AboutUsTitle subsection>Photo</AboutUsTitle>
              <Field
                component={ImageInput}
                name="photo"
                width="100%"
                ratio={0.6}
              />

              <AboutUsTitle subsection id="bride_info">Dados da noiva</AboutUsTitle>
              <FormSection name="bride">
                {brideFields.map(field => (
                  <Field
                    key={field.name}
                    component={field.component}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    disabled={field.disabled}
                  />
                ))}
              </FormSection>

              <AboutUsTitle subsection id="groom_info">Dados do noivo</AboutUsTitle>
              <FormSection name="groom">
                {groomFields.map(field => (
                  <Field
                    key={field.name}
                    component={field.component}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    disabled={field.disabled}
                  />
                ))}
              </FormSection>

              <AboutUsTitle subsection id="alternative_info">Contactos alternativo (familiares ou amigos)</AboutUsTitle>
              <FormSection name="alternative">
                {contactFields.map(field => (
                  <Field
                    key={field.name}
                    component={field.component}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                  />
                ))}
              </FormSection>

              <AboutUsTitle subsection id="alternative_info">Contacto alternativo (familiares ou amigos)</AboutUsTitle>
              <FormSection name="alternative2">
                {contactFields.map(field => (
                  <Field
                    key={field.name}
                    component={field.component}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                  />
                ))}
              </FormSection>
            </BaseForm>
          </FormContainer>
          <SaveAboutUsContainer>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar alterações"
              onClick={handleSubmit(onSubmit)}
              style={{ float: 'none' }}
            />
          </SaveAboutUsContainer>
        </Element>
      </Element>

      {/* Billing */}
      {/* <Element key="billing" name="billing" id="billing">
        <SectionTitle>
          Dados Faturação *
          <Counting>2 de {section.chapters.length + plusChapters}</Counting>
        </SectionTitle>
        <Element>
          <AddBillingContainer>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Novo Dado Faturação'}
              onClick={() => openAddModal()}
              style={{ float: 'none' }}
            />
            <AddBillingModal
              open={showModal}
              editBilling={editBillingIndex}
              onSubmit={addModalSubmit}
              closeModal={closeAddModal}
            />
          </AddBillingContainer>
          <Billings
            billings={wedding.wedding.billings}
            editBilling={editBilling}
            deleteBilling={deleteBilling}
            wedding={wedding.wedding}
          />
        </Element>
      </Element> */}

      {/* Guest Access */}
      {showGuest && <Element key="guestAccess" name="guestAccess">
        <SectionTitle>
          Accesso do Convidado
          <Counting>
            2 de {section.chapters.length + plusChapters}
          </Counting>
        </SectionTitle>
        <FormContainer>
          <BaseForm>
            {/* <AboutUsTitle subsection id="alternative_info">Contacto do Convidado</AboutUsTitle> */}
            <FormSection name="alternativeGuest">
              {guestFields.map(field => (
                <Field
                  key={field.name}
                  component={field.component}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  disabled={field.disabled}
                />
              ))}
              <Field
                component={SelectInput}
                name={'relationToCouple'}
                type={'select'}
                disabled={false}
                label={'Relação do convidado com o casal'}
                dataKey={'value'}
                dataLabel={'name'}
                data={[
                  { value: RelationToCouple.THIRD_USER, name: '3º email do casal' },
                  { value: RelationToCouple.FAMILIAR_FRIEND, name: 'Familiar e/ou amigo' },
                  { value: RelationToCouple.WEDDING_PLANNER, name: 'Wedding Planner' },
                ]}
              />
            </FormSection>
            <Field
              component={CheckboxInput}
              name={'accessToConfirmedWedding'}
              label={'Permitir que o contacto convidado aceda ao casamento após confirmação?'}
              positiveLabel='Sim'
            />
          </BaseForm>
        </FormContainer>
        <SaveGuestContainer>
          <BaseButton
            type="primary"
            icon="save"
            text="Gravar alterações"
            onClick={handleSubmit(onSubmit)}
            style={{ float: 'none' }}
          />
        </SaveGuestContainer>

        <CodeP>O convidado passa a ter acesso à plataforma Wedding Day e pode adicionar, remover ou alterar qualquer dado no vosso processo de casamento.</CodeP>
        <Popconfirm
          title={blockGuestAccess ? UnblockAccess : BlockAccess}
          onConfirm={() => confirmMngGuestAccess()}
          okText={blockGuestAccess ? 'Desbloquear' : 'Bloquear'}
          okType={blockGuestAccess ? 'primary' : 'danger'}
          cancelText="Cancelar">
          <BaseButton
            align="initial"
            htmlType="button"
            type={blockGuestAccess ? 'default' : 'danger'}
            style={{ marginBottom: 15 }}
            loading={mngAccess}
            text={`${blockGuestAccess ? 'Desbloquear' : 'Bloquear'} Acesso do Convidado`}
          />
        </Popconfirm>

        {!blockGuestAccess &&
          <CodeInput>
            <Input value={weddingAccessCode} disabled={true} />
            <Popconfirm
              title={GenerateCode}
              onConfirm={confirmGenerateCode}
              okText="Criar"
              cancelText="Cancelar">
              <BaseButton
                type='default'
                icon={generatingCode ? 'loading' : 'reload'}
                style={{ borderRadius: 0 }}
              />
            </Popconfirm>
            <Tooltip title="Copiar código">
              <BaseButton
                type='default'
                icon='copy'
                style={{ borderRadius: 0 }}
                onClick={() => copyCode()}
              />
            </Tooltip>
            <Popconfirm
              title={NotifyGuest}
              onConfirm={notifyGuestCode}
              okText="Notificar"
              cancelText="Cancelar">
              <BaseButton
                type='default'
                icon={sendingEmail ? 'loading' : 'mail'}
                style={{ borderRadius: '0 4px 4px 0' }}
              />
            </Popconfirm>
          </CodeInput>}
      </Element>
      }
      {
        section.chapters.map((chapter, cIndex) =>
          <Element key={`anchor-${cIndex}`} name={`anchor-${cIndex}`}>
            <SectionTitle>
              {chapter._id === '5e735eeb29196b814fb983b6' ? 'Informação de pagamentos' : TranslateValue(chapter.name)}
              <Counting>
                {cIndex + plusChapters} de {section.chapters.length + plusChapters}
              </Counting>
            </SectionTitle>
            {
              chapter.questions.map((question, qIndex) => {
                const response = question?.answer?.response && Array.isArray(question?.answer?.response) && question?.answer?.response.length > 0
                  ? question?.answer?.response[0]
                  : null;
                return <Element
                  key={`anchor-${cIndex}-${qIndex}`}
                  name={`anchor-${cIndex}-${qIndex}`}
                  id={question._id}
                >
                  <QuestionTitleContainer>
                    <QuestionTitle>{TranslateValue(question.title)} *</QuestionTitle>
                    {
                      (question.type !== 9 || question.observations) &&
                      <QuestionIcon type="edit" title="Editar resposta" onClick={() => openModal(question)} />
                    }
                  </QuestionTitleContainer>
                  <SectionResponse
                    upgrade={wedding.wedding.upgrade}
                    weddingPlace={wedding.wedding.wedding_place}
                    question={question}
                    printed={printed}
                  />
                  {question._id === '5e73610729196b814fb9887f' && response &&
                    <div style={question._id === '5e73610729196b814fb9887f' && question.answer ? { display: 'flex', alignItems: 'flex-end', marginBottom: 30, marginTop: -35 } : {}}>
                      <div style={{ width: 115 }}>
                        <InputLabelDiv><b>Confirmar Data</b></InputLabelDiv>
                        <CheckboxInput
                          positiveLabel='Confirmado'
                          first={true}
                          input={{
                            value: response.confirmed === 'true' ? true : false,
                            onChange: e => confirmPaymentMethod(question, e.target.checked)
                          }}
                          meta={{}} />
                      </div>
                      {confirming &&
                        <span>
                          <Icon type='loading' style={{ marginRight: 5 }} />
                          A guardar...
                        </span>
                      }
                    </div>
                  }
                </Element>
              })
            }
          </Element>
        )
      }
      <Element key="attachments" name="attachments">
        <SectionTitle>
          Documentos
          <Counting>
            {section.chapters.length + plusChapters} de {section.chapters.length + plusChapters}
          </Counting>
        </SectionTitle>
        <WeddingDocuments />
      </Element>
      {
        showEditQuestionModal &&
        <SectionModal
          open={showEditQuestionModal}
          loading={loading}
          onSubmit={editQuestionModalSubmit}
          closeModal={closeEditQuestionModal}
          question={editQuestion}
        />
      }
    </SectionContentStyle>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch => bindActionCreators({ updateQuestionAction, dispatch }, dispatch);

export default withRouter(connect(mapStateToProps, mapActionToProps)(AboutContent));