import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import {FilesGallerySection, FileSection, FileDropSection} from './InputStyles';
import {Icon, Popconfirm} from 'antd';
import {AsyncForEach} from './../../infra/services/async/AsyncFunctions';

const FilesGallery = ({fields, handleAdd, handleDelete, maxSize, label}) => {
  const [loading, setLoading] = useState(false);

  const list = fields.getAll() || [];

  const handleDrop = async accepted => {
    setLoading(true);
    await AsyncForEach(accepted, async file => {
      const {data, success} = await handleAdd(file);
      if (success) fields.push(data);
    });
    setLoading(false);
  };

  const removeFromList = async (id, index) => {
    if (handleDelete) await handleDelete(id);
    return fields.remove(index);
  };

  return (
    <FilesGallerySection>
      {list.map((file, index) => (
        <FileSection key={index}>
          {file.name || file.filename || ''}
          <Popconfirm
            placement={'topRight'}
            title="Tem a certeza que quer remover este ficheiro?"
            onConfirm={() => removeFromList(file._id, index)}>
            <Icon type="delete" />
          </Popconfirm>
        </FileSection>
      ))}
      <Dropzone onDrop={handleDrop} maxSize={maxSize}>
        {({getRootProps, getInputProps}) => (
          <FileDropSection {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon type={loading ? 'loading' : 'plus'} />
            <div>
              {loading
                ? 'loading...'
                : 'Click ou arraste os ficheiros para aqui'}
            </div>
          </FileDropSection>
        )}
      </Dropzone>
    </FilesGallerySection>
  );
};

export default FilesGallery;
