import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import TitleInput from '../../components/inputs/TitleInput';
import SelectQuestion from '../../components/inputs/SelectQuestion';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import ImageSlideInput from '../../components/inputs/ImageSlideInput';
import SelectInput from '../../components/inputs/SelectInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  title: 'required|languages',
  type: 'required'
});

let ManageQuestionModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  supplierCategories,
  question
}) => {
  const [showCategory, toggleCategory] = useState(false);
  const [checkedObs, toogleCheckedObs] = useState(false);
  const [hasObservations, toogleObservations] = useState(false);

  const checkCategory = (value) => {
    if (value === 10) toggleCategory(true);
    else toggleCategory(false);
  }

  const checkObservations = (event) => {
    toogleCheckedObs(true);
    toogleObservations(event.target.checked);
  }

  return (
    <Modal
      visible={open}
      title={edit ? 'Editar Pergunta' : 'Adicionar pergunta'}
      maskClosable={false}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          icon={loading ? 'loading' : ''}
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Field
            disabled={edit ? true : false}
            component={SelectQuestion}
            name={'type'}
            label={'Tipo de pergunta'}
            placeholder={'Escolha o tipo de pergunta'}
            execAfterChange={checkCategory}
          />
          {
            (question?.type === 10 || showCategory) &&
            <Field
              component={SelectInput}
              name={'supplier_category'}
              label={'Categoria de Encomenda'}
              placeholder={'Escolha uma categoria caso a resposta seja "Sim"'}
              type="select"
              data={supplierCategories}
              allowClear
              mode='multiple'
            />
          }
          <Field
            component={TitleInput}
            name={'title'}
            type="text"
            label={'Titulo'}
            placeholder={'Titulo da pergunta'}
          />
          <Field
            component={LanguagesInput}
            name={'description'}
            type="text"
            label={'Descrição'}
            placeholder={'Descrição da pergunta'}
          />
          <Field
            component={CheckboxInput}
            name={'observations'}
            type="text"
            label={'Tem campo de observações?'}
            positiveLabel={'Sim'}
            disabled={question?.isFoodRestriction}
            onChange={(e) => checkObservations(e)}
            />
          {(checkedObs ? hasObservations : question.observations) && <Field
            component={CheckboxInput}
            name={'observationsBlockedForCouple'}
            type="text"
            label={'Bloquear campo de observações para os noivos?'}
            positiveLabel={'Sim'}
            disabled={question?.isFoodRestriction}
          />
          }
          <FieldArray
            component={ImageSlideInput}
            name={'images'}
            label={'Imagens de apresentação'}
          />
        </BaseForm>
      )}
    </Modal>
  );
};

ManageQuestionModal = reduxForm({
  form: 'manage_question_form',
  validate: validations
})(ManageQuestionModal);

export default (ManageQuestionModal);
