import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, initialize, FieldArray } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetUpgrade,
  UpdateUpgrade,
  SaveImage,
  DeleteImage
} from '../../infra/requests/UpgradesRequests';
import TitleInput from '../../components/inputs/TitleInput';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import ImageInput from '../../components/inputs/ImageInput';
import ColourInput from '../../components/inputs/ColourInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import ImageGalleryInput from '../../components/inputs/ImageGalleryInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import ManageSections from './ManageSections';

const validations = FormValidator.make({
  name: 'required|languages',
  icon: 'required',
  description: 'required|languages'
});

class ManageUpgradePage extends Component {
  state = {
    loading: true,
    name: ''
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    const { data } = await GetUpgrade(params.id);
    dispatch(initialize('manage_upgrade_form', data));
    this.setState({ loading: false, name: TranslateValue(data.name) });
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      if (values.icon && !values.icon.blob) {
        delete values.icon;
      }
      if (!values?.price || values?.price == '') {
        values.price = 0;
      }
      const payload = FlattenToFormData(values);
      const { success } = await UpdateUpgrade(params.id, payload);
      if (success) return history.push('/sections/upgrades');
      return this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/sections/upgrades');
  };

  saveNewImage = async image => {
    const {
      match: { params }
    } = this.props;
    const payload = new FormData();
    payload.append('image', image.blob);
    return await SaveImage(params.id, payload);
  };

  deleteImage = async image => {
    const {
      match: { params }
    } = this.props;
    return await DeleteImage(params.id, image);
  };

  render() {
    const {
      handleSubmit,
      match: { params }
    } = this.props;
    const { name, loading } = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{name}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Col xs={4} offset={10}>
                <Field
                  name="icon"
                  label="Icon"
                  component={ImageInput}
                  ratio={1}
                />
              </Col>
              <Col xs={10} offset={7}>
                <Field
                  component={TitleInput}
                  name={'name'}
                  type="text"
                  label={'Nome'}
                  placeholder={'Nome do Upgrade'}
                />
                <Field
                  component={CurrencyInput}
                  name={'price'}
                  label={'Preço'}
                  placeholder={'Preço do upgrade'}
                />
                <Field
                  component={LanguagesInput}
                  name={'description'}
                  type="text"
                  label={'Descrição'}
                  placeholder={'Descrição do Upgrade'}
                />
                <Field
                  component={ColourInput}
                  name={'textColor'}
                  defaultColor="#000"
                  label={'Cor do texto'}
                />
              </Col>
              <Col xs={20} offset={2}>
                <SectionTitle subsection>Galeria de Imagens</SectionTitle>
                <FieldArray
                  name="gallery"
                  component={ImageGalleryInput}
                  handleAdd={this.saveNewImage}
                  handleDelete={this.deleteImage}
                />
              </Col>
              <Col xs={20} offset={2}>
                <SectionTitle subsection>Secções</SectionTitle>
                <FieldArray
                  name="sections"
                  component={ManageSections}
                  upgrade={params.id}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageUpgradePage = reduxForm({
  form: 'manage_upgrade_form',
  validate: validations
})(ManageUpgradePage);

export default withLocalize(ManageUpgradePage);
