import React from 'react';
import { Modal, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChapterOrderName, OrderContainer } from '../chapters/ChapterStyles';
import { SeatAge, SeatName, SeatOrderAge, SeatOrderName, SeatOrderText, SeatRow, TableSeatCode } from '../../process/guests/RoomPlanStyles';
import Alerts from '../alert/Alert';
import { AgeTypes, GuestAgeEnum } from './GuestTablesComponent';

class OrderSeatsModal extends React.Component {
  state = {
    seats: [],
    hasSeatsFrontCouple: true,
    isStaff: false,
  }

  componentDidMount() {
    const { initialValues, isStaff, hasSeatsFrontCouple } = this.props;

    this.setState({
      seats: initialValues ? JSON.parse(JSON.stringify(initialValues)) : [],
      hasSeatsFrontCouple: hasSeatsFrontCouple,
      isStaff: isStaff
    });
  }

  onDragEnd = (data) => {
    const { hasSeatsFrontCouple } = this.props;
    let { seats } = this.state;
    const sourceSeat = data?.source
      ? seats[data.source.index]
      : null;
    const destinationSeat = data?.destination
      ? seats[data.destination.index]
      : null;

    if (data?.destination && destinationSeat?.couple) {
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Ação inválida. Lugar do casal.'
      });
    }

    if (data?.destination && destinationSeat?.frontCouple && !hasSeatsFrontCouple) {
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Ação inválida. Sem lugares em frente ao casal.'
      });
    }

    if (data.source.index !== data.destination.index) {

      // If couple seats
      if (sourceSeat?.couple) {
        const seatsMoved = data?.destination?.index - data?.source?.index;

        seats = this.rearrangeArrayCoupleElements(seats, seatsMoved);
      } else if (sourceSeat?.frontCouple || destinationSeat?.frontCouple) {
        seats = this.rearrangeArrayFromCoupleElement(seats, data.source.index, data.destination.index);
      } else {
        seats = this.rearrangeArrayElement(seats, data.source.index, data.destination.index);
      }

      seats = seats.map((seat, index) => ({
        ...seat,
        number: index
      }));

      this.setState({ seats });
    }
  }

  moveSeat = (currentSeat, move) => {
    const { seats } = this.state;
    const maxSeats = seats?.length;

    // Function to wrap seat numbers within the valid range
    function wrapSeat(seat) {
      if (seat < 0) {
        return maxSeats + seat;
      } else if (seat > maxSeats) {
        return seat - maxSeats;
      }
      return seat;
    }

    // Calculate the new position
    const newPosition = wrapSeat(currentSeat + move);

    return newPosition;
  }

  rearrangeArrayElement = (arr, oldIndex, newIndex) => {
    if (
      oldIndex < 0 ||
      oldIndex >= arr.length ||
      newIndex < 0 ||
      newIndex >= arr.length
    ) {
      return;
    }

    // Remove the element at the old index and store it
    const removedElement = arr.splice(oldIndex, 1)[0];

    // Insert the removed element at the new index
    arr.splice(newIndex, 0, removedElement);

    return arr;
  }

  rearrangeArrayFromCoupleElement = (arr, oldIndex, newIndex) => {
    if (
      oldIndex < 0 ||
      oldIndex >= arr.length ||
      newIndex < 0 ||
      newIndex >= arr.length
    ) {
      return;
    }

    // Remove the element at the old index and store it
    const removedElement = { ...arr[oldIndex] };
    const newElement = { ...arr[newIndex] };

    const auxFrontCouple = removedElement.frontCouple;
    removedElement.frontCouple = newElement?.frontCouple;
    newElement.frontCouple = auxFrontCouple;

    // Insert the removed element at the new index
    arr.splice(newIndex, 1, removedElement);
    arr.splice(oldIndex, 1, newElement);

    return arr;
  }

  rearrangeArrayCoupleElements = (arr, seatsMoved) => {
    const newArr = [...arr];

    for (let index = 0; index < newArr.length; index++) {
      const newSeat = newArr[index];
      const destinationIndex = this.moveSeat(seatsMoved, newSeat?.number);
      newArr[index].number = destinationIndex;
    }

    // Create a copy of the array to avoid mutating the original array
    const sortedArray = [...newArr];

    // Sort the array by the specified numeric attribute
    sortedArray.sort((a, b) => a['number'] - b['number']);

    return sortedArray;
  }

  getSeatCode = (seat) => {
    return (seat?.number || 0) + 1;
  }

  getGuestAge = (seat) => {
    const age = seat?.guestAge != GuestAgeEnum.ADULT
      ? AgeTypes.find(f => f?._id == seat?.guestAge)
      : null;
    return age?.name || '';
  }

  validate = () => {
    const { onSubmit, hasSeatsFrontCouple } = this.props;
    const { seats } = this.state;

    const couple = seats.filter(f => f?.couple);
    if (couple?.length > 0) {
      const bride = couple?.[0] ? couple[0] : null;
      const groom = couple?.[1] ? couple[1] : null;

      if (Math.abs(bride?.number - groom?.number) != 1) {
        return Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'O casal deve sentar-se seguido.'
        });
      }
    }

    onSubmit(seats);
  }

  disableCouple = (seat) => {
    const {seats} = this.state;

    if(!seat?.couple) return false;

    const coupleSeats = seats.filter(f => f?.couple);
    coupleSeats.sort((a, b) => a['number'] - b['number']);
    const smallCouple = coupleSeats?.length > 0 
    ? coupleSeats[0]
    : null;

    return !smallCouple || seat?.number !== smallCouple?.number ? true : false;
  }

  render() {
    const { open, initialValues, isStaff, hasSeatsFrontCouple } = this.props;
    const { closeModal, onSubmit, loading } = this.props;
    const { seats } = this.state;

    return (
      <Modal
        visible={open}
        title="Gerir ordem das convidados"
        maskClosable={false}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => this.validate()}>
            Gravar
          </Button>
        ]}>
        <OrderContainer>
          <DragDropContext onDragEnd={data => this.onDragEnd(data)}>
            <Droppable droppableId="seats">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {seats.map((seat, index) => (
                    <Draggable
                      key={seat.number?.toString()}
                      draggableId={seat.number?.toString()}
                      index={index}
                      // isDragDisabled={(seat?.couple || (!hasSeatsFrontCouple && seat?.frontCouple)) ? true : false}
                      isDragDisabled={this.disableCouple(seat) || !hasSeatsFrontCouple && seat?.frontCouple ? true : false}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <SeatOrderText
                            frontCouple={seat?.frontCouple}
                            disabled={!hasSeatsFrontCouple && seat?.frontCouple}>
                            <TableSeatCode couple={seat?.couple} seat={true}>
                              {this.getSeatCode(seat) || 'X'}
                            </TableSeatCode>

                            <SeatOrderName withAge={isStaff ? false : true} noGuest={seat?.guestName?.trim() == '' ? true : false}>
                              {seat?.guestName || 'Por preencher'}
                            </SeatOrderName>
                            {!isStaff && <SeatOrderAge>
                              {this.getGuestAge(seat)}
                            </SeatOrderAge>}
                          </SeatOrderText>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </OrderContainer>
      </Modal>
    );
  };
};


export default OrderSeatsModal;