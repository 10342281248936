import client from '../config/AxiosConfig';

export const CreateWaitingDate = async (data) =>
  client.put(`/waitingDate/create`, data);

export const CreateMultipleWaitingDate = async (data) =>
  client.put(`/waitingDate/createMultiple`, data);

// export const UpdateWaitingDate = async (data) =>
//   client.post(`/waitingDate/update`, data);

export const SoftDeleteWaitingDate = async (id, deleted) =>
  client.delete(`/waitingDate/softDelete/${id}/${deleted}`);

export const SoftDeleteWaitingDateAgenda = async (deleted, data) =>
  client.post(`/waitingDate/softDeleteAgenda/${deleted}`, data);

export const SoftDeleteMultipleWaitingDate = async (deleted, data) =>
  client.post(`/waitingDate/softDeleteMultiple/${deleted}`, data);

export const GetWaitingDatePaginated = async (currentPage, limit, filters) =>
  client.get(`/waitingDate/paginate/${currentPage}/${limit}?filter=${filters}`);

export const GetWaitingDateByWedding = async (weddingId, filters) =>
  client.get(`/waitingDate/findByWedding/${weddingId}?filter=${filters}`);

export const DownloadWaitingDate = async (filters) => {
  return client.post(`/waitingDate/export?filter=${filters}`, {}, {
    responseType: 'blob'
  }).then(response => {
    return response;
  })
}

export const UploadWaitingDateToDrive = async (filters) =>
  client.get(`/reports/waitingDate/uploadDrive?filter=${filters}`);