import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import TitleInput from '../../components/inputs/TitleInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import SelectInput from '../../components/inputs/SelectInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = values => {
  if (values.cost) {
    return FormValidator.make({
      title: 'required|languages',
      cost_price: 'required',
      cost_type: 'required'
    })(values);
  }
  return FormValidator.make({
    title: 'required|languages'
  })(values);
};

const CostType = [
  { _id: 'GLOBAL', name: 'Global' },
  { _id: 'TABLE', name: 'Por mesa' },
  { _id: 'GUEST', name: 'Por PAX' },
  { _id: 'QUANTITY', name: 'Por quantidade escolhida' }
];

const Locals = [
  { _id: 1, name: 'Solar da Levada' },
  { _id: 2, name: 'Quinta Lago dos Cisnes' }
];

let ManageItemByWeddingModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  questionType,
  hasCost,
  initialValues,
  supplierCategories
}) => {
  return (
    <Modal
      visible={open}
      title={'Editar opção'}
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={TitleInput}
            name={initialValues.defaultOption ? 'defaultOption.title' : 'title'}
            type="text"
            label={'Opção'}
            placeholder={'Nome da opção'}
            disabled={true}
          />
          {/* <Field
            component={CheckboxInput}
            name={'isExtraPrimeTrend'}
            type="text"
            label={'É uma opção do Serviço Premium Prime/Trend?'}
            positiveLabel={'Sim'}
          /> */}
          <Field
            component={CheckboxInput}
            name={'cost'}
            type="text"
            label={'Tem custo acrescido?'}
            positiveLabel={'Sim'}
            disabled={true}
          />
          {hasCost && (
            <Fragment>
              <Field
                component={SelectInput}
                name={'cost_type'}
                label={'Tipo de custo'}
                placeholder={'Escolha o tipo de custo'}
                type="select"
                data={CostType}
                disabled={true}
              />
              <Field
                component={CurrencyInput}
                name={'cost_price'}
                label={'Preço'}
                placeholder={'Insira o preço para o tipo de custo'}
              />
            </Fragment>
          )}
          {/* <Field
            component={SelectInput}
            name={'local'}
            label={'Local'}
            allowClear
            placeholder={'Restrito apenas a um local'}
            type="select"
            data={Locals}
          />
          <Field
            component={SelectInput}
            name={'supplier_category'}
            label={'Categoria de Encomenda'}
            allowClear
            placeholder={'Escolha uma categoria'}
            type="select"
            data={supplierCategories}
          /> */}
        </BaseForm>
      )}
    </Modal>
  );
};

ManageItemByWeddingModal = reduxForm({
  form: 'manage_option_form',
  validate: validations
})(ManageItemByWeddingModal);

const selector = formValueSelector('manage_option_form');

const mapStateToProps = state => ({
  hasCost: selector(state, 'cost')
});

export default connect(mapStateToProps)(ManageItemByWeddingModal);
