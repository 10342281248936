import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Menu, Dropdown, Input } from 'antd';
import { BtnAddTable, DeleteSeatButton, SeatAge, SeatFoodRestrict, SeatName, SeatRow, StatsContent, StatsContentRow, StatsTotal, TableGuests, TableGuestsHeader, TableGuestsModal, TableGuestsOptions, TableGuestsStats, TableGuestsType, TableName, TableSeatCode } from '../../process/guests/RoomPlanStyles';
import Alerts from '../alert/Alert';
import { canChangeSeats, canIncrease, canResize, checkCoupleTable, checkGuestTable, checkStaffTable, convertNumberToLetter } from '../../process/guests/utilRoomPlan';
import GuestTableComponent from './GuestTableComponent';

export const GuestAgeEnum = {
  NEWBORN: 'NEWBORN', // Baby: from 0 months to 5 months old
  BABY: 'BABY', // Baby: from 6 months to 2 years old
  CHILD: 'CHILD', // Child: from 3 to 7 years old
  ADULT: 'ADULT' // Adult: 8 years old or above
}

export const AgeTypes = [
  { _id: 'NEWBORN', name: '0 a 5 meses' },
  { _id: 'BABY', name: '6 meses a 2 anos' },
  { _id: 'CHILD', name: '3 aos 7 anos' },
  // { _id: 'ADULT', name: 'Maior de 8 anos' },
];

export const TablePurposeEnum = {
  GUEST: 'GUEST',
  STAFF: 'STAFF',
  CHILDREN: 'CHILD',
  COUPLE: 'COUPLE'
}

export const TableTypesEnum = {
  OVAL: 'OVAL',
  ROUND: 'ROUND',
  SQUARE: 'SQUARE',
  RECTANGULAR: 'RECTANGULAR'
}

export const SidesEnum = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  ND: 'ND',
}

const GuestTablesComponent = ({
  tables,
  objectTypes,
  foodRestrictions,
  parentActiveTable,
  parentDeleteTable,
  editTableCouple,
  updateTable,
  changeTablePurpose,
  parentSelectToChangeGuests,
  parentCancelChangeGuests,
  parentOpenImportModal
}) => {

  // const addTable = (coupleTable) => {

  //   const listActives = tables.filter(f => f?.active && (f?.tablePurpose != TablePurposeEnum.COUPLE && f?.tablePurpose != TablePurposeEnum.STAFF));
  //   listActives.sort((a, b) => a?.code - b?.code);
  //   const lastTableActive = listActives?.length > 0
  //     ? listActives[listActives.length - 1]
  //     : null;
  //   let nextTableIndexToActive = lastTableActive?.code
  //     ? tables.findIndex(f => f.code == lastTableActive?.code)
  //     : null;
  //   if (nextTableIndexToActive) nextTableIndexToActive = nextTableIndexToActive + 1;

  //   if (nextTableIndexToActive && tables?.get(nextTableIndexToActive)) {
  //     activateTable(nextTableIndexToActive);
  //   } else {
  //     // const tableTypes = coupleTable
  //     //   ? objectTypes.filter(f => f?.coupleTable)
  //     //   : objectTypes.filter(f => !f?.coupleTable);
  //     // tableTypes.sort((a, b) => (a?.position - b?.position));
  //     // const smallTable = tableTypes[0];

  //     // const minSeats = coupleTable ? 2 : smallTable?.maxSeats;
  //     // const seats = [];
  //     // for (let index = 0; index < minSeats; index++) {
  //     //   seats.push({
  //     //     number: index,
  //     //     couple: coupleTable && index < 2 ? true : false,
  //     //     guestName: '',
  //     //     guestAge: GuestAgeEnum.ADULT,
  //     //   });
  //     // }
  //     // const table = {
  //     //   code: (tables?.length || 0),
  //     //   name: '',
  //     //   active: true,
  //     //   tablePurpose: coupleTable ? TablePurposeEnum.COUPLE : TablePurposeEnum.GUEST,
  //     //   seats,
  //     //   tableType: smallTable?._id,
  //     //   new: true,
  //     // };
  //     // tables.push(table);
  //   }
  // }

  const canResize = (table) => {
    // Resizable tables
    if (table?.tableType == 'RectangularTable' || table?.tableType == 'RectangularLTable') {
      return true;

      // const increasedTables = tables?.filter(f => f?.tableClass == 'RectangularLTable');
      // const increasedTablesRight = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.RIGHT);
      // const increasedTablesLeft = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.LEFT);

      // TO INCREASE table
      // if (table?.tableType == 'RectangularTable') {
      //   return canIncrease(table, increasedTablesRight, increasedTablesLeft);
      // }
      // else {
      //   // To reduce table
      //   return canReduce(table, increasedTablesRight, increasedTablesLeft);
      // }
    } else {
      return false;
    }
  }

  const canIncrease = (table, increasedTablesRight, increasedTablesLeft) => {
    if (increasedTablesLeft > increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.RIGHT;
    } else if (increasedTablesLeft < increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.LEFT;
    } else return true;
  }

  const canReduce = (table, increasedTablesRight, increasedTablesLeft) => {
    if (increasedTablesLeft > increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.LEFT;
    } else if (increasedTablesLeft < increasedTablesRight) {
      return table?.sideOfRoom == SidesEnum.RIGHT;
    } else return true;
  }

  const checkCanIncrease = (table) => {
    if (table?.tableType == 'RectangularTable') {
      // const increasedTables = tables?.filter(f => f?.tableClass == 'RectangularLTable');
      // const increasedTablesRight = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.RIGHT);
      // const increasedTablesLeft = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.LEFT);

      return true;
      // return canIncrease(table, increasedTablesRight, increasedTablesLeft);
    } else {
      return false;
    }
  }

  const checkCanReduce = (table) => {
    if (table?.tableType == 'RectangularLTable') {
      const increasedTables = tables?.filter(f => f?.tableClass == 'RectangularLTable');
      const increasedTablesRight = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.RIGHT);
      const increasedTablesLeft = increasedTables.filter(f => f?.sideOfRoom == SidesEnum.LEFT);

      return canReduce(table, increasedTablesRight, increasedTablesLeft);
    } else {
      return false;
    }
  }

  return (
    <Row id="section-tables" gutter={[12, 12]} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>

      {tables.filter(f => !f?.removeBecauseOfOvalL)?.map((table, index) => (
          <GuestTableComponent
            key={index}
            index={index}
            table={table}
            resizable={canResize(table)}
            increasable={checkCanIncrease(table)}
            objectTypes={objectTypes}
            foodRestrictions={foodRestrictions}
            parentActiveTable={parentActiveTable}
            parentDeleteTable={parentDeleteTable}
            parentChangeTablePurpose={(e) => changeTablePurpose(e, table.code)}
            parentUpdateTable={updateTable}
            parentEditTableCouple={editTableCouple}
            parentSelectToChangeGuests={() => parentSelectToChangeGuests(table.code, table)}
            parentUnselectChange={parentCancelChangeGuests}
            parentOpenImportModal={parentOpenImportModal} />
      ))}
      {/* <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
        <BtnAddTable>
          <BaseButton
            type='primary'
            icon={'plus'}
            text={'Adicionar mesa'}
            onClick={() => addTable(false)} />
        </BtnAddTable>
      </Col> */}
    </Row>
  );
};

export default React.memo(GuestTablesComponent, (props, nextProps) => {
  if (props.tables == nextProps.tables) {
    return true;
  }

  // Re-render
  // console.log('re-render tables');
  return false;

});