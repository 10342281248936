import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt';
import styled from 'styled-components';
import { StyledHeader } from '../../components/layout/LayoutStyles';
import {
  getWeddingName,
  getLocal
} from '../../infra/services/wedding/weddingUtils';
import { WeddingHeader, Information, Title, SpaceBetween } from './HeaderStyles';
import ExportationModal from '../exportation/ExportationModal';
import BaseButton from '../../components/buttons/BaseButton';

export const ScrollBtn = styled.div`
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 130px;
`;

const HeaderComponent = ({ wedding, onNavigateToSection, location }) => {
  
  return (
    <StyledHeader>
      <WeddingHeader>
        <Title>{getWeddingName(wedding?.wedding)}</Title>
        <Information>
          {moment.utc(wedding?.wedding?.date).locale('pt').format('dddd, DD MMMM YYYY')}
          <SpaceBetween>|</SpaceBetween>
          {getLocal(wedding?.wedding)}
        </Information>
        <ScrollBtn>
          {location.pathname === `/wedding/${wedding?.wedding?._id}` && <BaseButton
            type="default"
            icon="arrow-down"
            text="Sem Respostas"
            onClick={onNavigateToSection}
          />}
        </ScrollBtn>
        <ExportationModal weddingID={wedding?.wedding._id} />
      </WeddingHeader>
    </StyledHeader>
  );
};

const mapStateToProps = state => ({ wedding: state.wedding });

export default connect(mapStateToProps)(HeaderComponent);