import { inactiveColor } from "../reserves/reservesUtils";
import { PresenceStatus } from "./SchedulePresenceModal";

export const GetScheduleRowColor = (schedule) => {
    let color = '';
    if (schedule?.presence === PresenceStatus.MISSED) {
        color = inactiveColor;
    }

    return color;
}