import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Input, Popconfirm, Tooltip, Select, Spin } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { dateFormat, dateTimeFormat, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { CreateCoupleReserve, GetAttendingEmployees, GetAttendingList, GetAvailableAttendingList, GetOrganizerList, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GenerateAccessCode, GetConfirmedWeddings, GetWedding, GetWeddingsSelect, MngGuestAccess, NotifyWeddingGuest, UpdateCreateWeddingGuest } from '../../infra/requests/WeddingRequests';
import Alerts from '../../components/alert/Alert';
import { CodeInput, CodeP } from '../../process/ChoicesPage/SectionStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { bindActionCreators } from 'redux';
import { getUserLabel, getWeddingFullName, getWeddingLabelSelect, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { CheckSchedule, GetPossibleScheduleTime, GetSchedule, ValidateScheduleType } from '../../infra/requests/SchedulesRequests';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import { visitedLocals } from './SchedulesFilters';
import { TimeInput } from '../../components/inputs/InputStyles';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import DateInput from '../../components/inputs/DateInput';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import debounce from 'lodash.debounce';
import ReserveInfoModal from '../reservesAttending/ReserveInfoModal';
import { GetScheduleServicesList } from '../../infra/requests/ScheduleServiceRequests';
import XpertGoDateTimeInput from '../../components/inputs/XpertGoDateTimeInput';
import { setDataToUpdateForReserveWedding } from '../reserves/reservesUtils';

const { confirm } = Modal;

export const ScheduleTypes = {
    FIRST_VISIT: 'FV',
    INTERNAL: 'I',
    ORGANIZATION: 'O',
};

const CreateCoupleMessage = (
    <div>
        <div>Tem a certeza que pretende criar casal de reserva?</div>
        <div>
            O casal criado estará na fase de reservas e receberá email para configurar as suas palavras-passes.
        </div>
    </div>
);

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        date: 'required',
        wedding: 'required',
        type: 'required',
        language: 'required',
        attendingType: 'required',
        weddingPlace: 'required',
        // attendedUsers: 'required',
        attendedBy: 'required',
        startTime: 'required'
    })(values);

    return errors;
};

class ScheduleModal extends React.Component {
    state = {
        loadingWeddings: false,
        fetchingWeddings: false,
        weddings: [],

        loading: false,
        schedule: null,
        saving: false,

        loadingAttendingTypes: false,
        attendingTypes: [],

        loadingAttendedUsers: false,
        filtersAttended: {},
        attendedUsers: [],
        maxEndTime: null,

        loadingServices: false,
        services: [],

        specificDate: false,
        specificPlace: false,
        validateType: false,

        showReserveModal: false,
        savingReserveModal: false,
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { initialValues } = this.props;

        this.setState({
            specificDate: initialValues?.specific || false,
            specificPlace: initialValues?.specificPlace || false
        })

        if (initialValues?._id) this.getSchedule();
        this.getWeddings();
        this.getAttendingTypes();

        dispatch(
            initialize('schedule_form', {
                date: initialValues?.date || null,
                startDate: null,
                endDate: null,
                wedding: null,
                type: null,
                language: null,
                attendingType: null,
                weddingPlace: initialValues?.weddingPlace || null,
                attendedUsers: [],
                startTime: null,
            })
        );
    }

    getSchedule = async () => {
        const { dispatch, initialValues } = this.props;
        let { weddings } = this.state;

        this.setState({ loading: true });
        const result = await GetSchedule(initialValues?._id);
        const schedule = (result?.success && result?.data) || null;
        console.log('schedule', schedule);
        // await this.getAvailableAttendedUsers(schedule);
        if (schedule?.wedding) {
            weddings.push({
                ...schedule.wedding,
                label: getWeddingFullName(schedule.wedding)
            });
        }

        dispatch(
            initialize('schedule_form', {
                _id: schedule?._id || null,
                date: schedule?.date || null,
                startDate: schedule?.startDate || null,
                endDate: schedule?.endDate || null,
                wedding: schedule?.wedding?._id || schedule?.wedding || null,
                type: schedule?.type || null,
                language: schedule?.language || null,
                attendingType: schedule?.attendingType?._id || schedule?.attendingType || null,
                weddingPlace: schedule?.weddingPlace || null,
                // attendedUsers: schedule?.attendedUsers || [],
                attendedBy: schedule?.attendedBy?._id || schedule?.attendedBy || null,
                startTime: schedule?.type == ScheduleTypes.ORGANIZATION
                    ? (schedule?.organizerSchedule || null)
                    : (schedule?.startDate || null),
            })
        );

        this.setState({
            schedule,
            weddings,
            loading: false,
            cheked: true,
            validateType: true,
            date: schedule?.date || null,
            filtersAttended: {
                _id: schedule?._id || null,
                organizerSchedule: schedule?.organizerSchedule || null,
                date: schedule?.date || null,
                type: schedule?.type || null,
                language: schedule?.language || null,
                attendingType: schedule?.attendingType?._id || schedule?.attendingType || null,
                weddingPlace: schedule?.weddingPlace || null,
                attendedBy: schedule?.attendedBy?._id || schedule?.attendedBy || null,
                startTime: schedule?.type == ScheduleTypes.ORGANIZATION ? (schedule?.organizerSchedule || null) : null,
                wedding: schedule?.wedding?._id || schedule?.wedding || null,
            }
        }, () => {
            this.getTimeInfo();
            this.getAttendedUsers();
        });
    }

    getWeddings = async () => {
        const { weddings } = this.state;
        this.setState({ loadingWeddings: true });
        const result = await GetWeddingsSelect();
        let data = result?.success && result?.data ? result.data : [];

        this.setState({
            loadingWeddings: false,
            weddings: [...weddings, ...data],
        });

    }

    fetchWeddings = debounce(async (value) => {
        this.setState({ fetchingWeddings: true });
        const result = await GetWeddingsSelect(value);
        let data = result?.success && result?.data ? result.data : [];

        this.setState({
            weddings: data
        }, () => {
            this.setState({ fetchingWeddings: false });
        });

    }, 500)

    createWedding = async (values) => {
        const { change } = this.props;
        let { weddings } = this.state;
        //Check if employee attending
        if (isCurrentUserEmployeeAttending()) return;

        // if ((!values?.visitedPlace || values?.visitedPlace == false)
        //     && (!values?.placeToVisit || values?.placeToVisit == false)
        //     && (!values?.wantToVisitWithoutInfo || values?.wantToVisitWithoutInfo == false)) {
        //     Alerts.new({
        //         type: 'warning',
        //         title: 'Atenção',
        //         text: 'Indique se já visitou ou pretende visitar algum dos espaços.'
        //     });
        //     return;
        // }

        this.setState({ savingReserveModal: true });

        const data = setDataToUpdateForReserveWedding(values);
        const resultCoupleWedding = await CreateCoupleReserve(data);

        if (resultCoupleWedding?.success) {
            const wedding = resultCoupleWedding?.data?.wedding;
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Casal registado com sucesso!'
            });

            weddings.push(wedding);
            this.setState({
                showReserveModal: false,
                savingReserveModal: false,
                weddings
            }, () => {
                change('wedding', wedding?._id || null);
                change('language', wedding?.langagueToVisit || null);
                change('weddingPlace', wedding?.weddingPlaceToVisit || null);
            });
        } else {
            this.setState({ savingReserveModal: false });
        }
    }

    getAttendingTypes = async () => {
        this.setState({ loadingAttendingTypes: true });
        const result = await GetAttendingTypes();
        let data = result?.success && result?.data ? result.data : [];
        if (Array.isArray(data) && data.length > 0) {
            data = data.map(m => ({ ...m, label: m?.name?.pt }))
        }

        this.setState({
            loadingAttendingTypes: false,
            attendingTypes: data,
        });
    }

    getAttendedUsers = async () => {
        const { filtersAttended } = this.state;
        if (filtersAttended?.type == ScheduleTypes.ORGANIZATION) {
            return;
        }

        this.setState({ loadingAttendedUsers: true });

        // const result = filtersAttended?.type == ScheduleTypes.FIRST_VISIT
        //     ? await GetAttendingList(filtersAttended)
        //     : await GetOrganizerList(filtersAttended);

        const result = await GetAttendingList(filtersAttended);
        let data = result?.success && result?.data ? result.data : [];

        this.setState({
            loadingAttendedUsers: false,
            attendedUsers: data,
        });
    }

    getTimeInfo = () => {
        const { filtersAttended } = this.state;

        if (!filtersAttended?.type) {
            return;
        }

        if (filtersAttended?.type == ScheduleTypes.FIRST_VISIT) {
            this.getScheduleTime();
        } else if (filtersAttended?.type == ScheduleTypes.ORGANIZATION) {
            this.getScheduleServices();
        }
    }

    getScheduleTime = async () => {
        const { change } = this.props;
        let { filtersAttended } = this.state;

        if (!(filtersAttended?.date && filtersAttended?.weddingPlace && filtersAttended?.language
            && filtersAttended?.type && filtersAttended?.attendingType && filtersAttended?.wedding
            && filtersAttended?.wedding !== '')) {
            return;
        }

        const result = await GetPossibleScheduleTime(filtersAttended?.wedding, filtersAttended);

        if (result?.success) {
            change('startTime', result?.data?.startDate || null);
            change('attendedBy', result?.data?.attendedBy?._id || result?.data?.attendedBy || null);
            const maxEndTime = !result?.data?._id && result?.data?.endDate
                ? moment.utc(result?.data?.endDate).local().format('HH:mm')
                : null;
            this.setState({
                maxEndTime
            }, () => this.getAttendedUsers());
        } else {
            change('startTime', null);
            change('attendedBy', null);
        }
    }

    getScheduleServices = async () => {
        const { change } = this.props;
        const { filtersAttended } = this.state;
        if (!filtersAttended?.type || filtersAttended?.type == ScheduleTypes.FIRST_VISIT) {
            return;
        }

        this.setState({ loadingServices: true });

        const result = await GetScheduleServicesList(filtersAttended);
        let data = result?.success && result?.data ? result.data : [];

        if (filtersAttended?.startTime) {
            const exist = data.find(f => f?._id == filtersAttended?.startTime);
            if (!exist) change('startTime', null);
        }
        this.setState({
            loadingServices: false,
            services: data,
        });
    }

    getServiceLabel = (value) => {
        return <div>
            {value?.startDate ? moment.utc(value?.startDate).local().format('HH:mm') : ''} ({value?.user?.name || ''})
        </div>
    }

    clearFields = () => {
        const { dispatch, onClose } = this.props;
        dispatch(initialize('schedule_form', {}, false));
        onClose();
    }

    checkScheduleType = async (weddingId) => {
        const { change } = this.props;
        let { filtersAttended } = this.state;

        if (weddingId && weddingId !== '') {
            const result = await ValidateScheduleType(weddingId);

            if (result?.success) {
                filtersAttended['type'] = result?.data?.type;
                change('type', result?.data?.type);
                this.setState({
                    validateType: true,
                    filtersAttended
                }, () => this.getAttendedUsers());
            } else {
                change('type', null);
            }
        } else {
            change('type', null);
        }
    }

    // Disable dates
    disabledDate = (current) => {
        return current && current.isBefore(moment.utc().startOf('day'), 'day');
    }

    render() {
        const { isOpen } = this.props;
        const { handleSubmit, onClose, onSubmit, invalid, change, submitFailed, saving, confirming, scheduleForm } = this.props;
        const { loadingWeddings, loading, loadingAttendedUsers, loadingAttendingTypes, loadingServices, validateType } = this.state;
        const { fetchingWeddings, weddings, attendedUsers, attendingTypes, services } = this.state;
        const { schedule, specificDate, specificPlace, maxEndTime } = this.state;
        const { showReserveModal, savingReserveModal } = this.state;
        const { date } = this.state;

        const ready = loading || loadingWeddings || loadingAttendingTypes;

        return (
            <React.Fragment>
                <Modal
                    visible={isOpen}
                    title={schedule?._id ? 'Editar Atendimento' : 'Criar Atendimento'}
                    maskClosable
                    onCancel={onClose}
                    footer={[
                        <Button
                            key='cancel'
                            type='default'
                            onClick={onClose}>
                            Fechar
                        </Button>,
                        <Button
                            key='submit'
                            type='primary'
                            loading={saving}
                            disabled={ready}
                            onClick={handleSubmit(onSubmit)}>
                            Gravar
                        </Button>,
                    ]} >

                    {
                        ready
                            ? <SpinLoading />
                            : <BaseForm onSubmit={handleSubmit(onSubmit)}>
                                <Field
                                    component={DateInput}
                                    name="date"
                                    label="Data *"
                                    placeholder="Selecione a data"
                                    disabled={schedule?.type == ScheduleTypes.FIRST_VISIT || specificDate ? true : false}
                                    disabledDate={this.disabledDate}
                                    onChange={(e) => {
                                        let { filtersAttended } = this.state;
                                        filtersAttended['date'] = e;
                                        const date = e && moment.utc(e).isValid() ? e : null;
                                        this.setState({ date });

                                        if (scheduleForm?.type == ScheduleTypes.FIRST_VISIT) {
                                            const startTime = scheduleForm?.startTime && moment.utc(scheduleForm.startTime).isValid() && date
                                                ? moment.utc(scheduleForm.startTime).set('year', date.year()).set('month', date.month()).set('date', date.date())
                                                : null;

                                            change('startTime', startTime);
                                        }
                                        this.setState({ filtersAttended }, () => this.getTimeInfo());
                                    }}
                                />
                                {schedule
                                    ? <Field
                                        component={SelectInput}
                                        name={'wedding'}
                                        label={'Casal *'}
                                        placeholder={'Selecione o casal'}
                                        allowClear={true}
                                        type='select'
                                        data={weddings}
                                        disabled={schedule ? true : false}
                                        fetching={fetchingWeddings}
                                        fetchData={this.fetchWeddings}
                                        costumeLabel={getWeddingLabelSelect}
                                        withSearchAPI={true}
                                        onChange={(e) => {
                                            let { filtersAttended } = this.state;
                                            if (e && e !== '') {
                                                filtersAttended['wedding'] = e;
                                                const weddingSelected = weddings.find(f => f?._id == e);
                                                change('language', weddingSelected?.langagueToVisit || null);
                                                filtersAttended['language'] = weddingSelected?.langagueToVisit || null;

                                                if (weddingSelected?.weddingSelected?.weddingPlaceToVisit && specificPlace == null) {
                                                    change('weddingPlace', weddingSelected?.weddingPlaceToVisit || null);
                                                    filtersAttended['weddingPlace'] = weddingSelected?.weddingPlaceToVisit || null;
                                                }

                                                this.setState({ filtersAttended }, () => this.getTimeInfo());
                                            } else {
                                                filtersAttended['wedding'] = e;
                                                change('language', null);
                                                filtersAttended['language'] = null;

                                                if (specificPlace == null) {
                                                    change('weddingPlace', null);
                                                    filtersAttended['weddingPlace'] = null;
                                                }

                                                this.setState({ filtersAttended }, () => this.getTimeInfo());
                                            }
                                        }}
                                    />
                                    : <Field
                                        component={SelectCompactInput}
                                        name={'wedding'}
                                        label={'Casal *'}
                                        placeholder={'Selecione o casal'}
                                        allowClear={true}
                                        type='select'
                                        data={weddings}
                                        disabled={schedule ? true : false}
                                        fetching={fetchingWeddings}
                                        fetchData={this.fetchWeddings}
                                        costumeLabel={getWeddingLabelSelect}
                                        withSearchAPI={true}
                                        btnType={'primary'}
                                        icon="plus"
                                        btnConfirm={true}
                                        confirmTitle={CreateCoupleMessage}
                                        confirmOk={'Adicionar'}
                                        onClick={() => this.setState({ showReserveModal: true })}
                                        onChange={(e) => {
                                            let { filtersAttended } = this.state;
                                            this.checkScheduleType(e);
                                            if (e && e !== '') {
                                                filtersAttended['wedding'] = e;
                                                const weddingSelected = weddings.find(f => f?._id == e);

                                                change('language', weddingSelected?.langagueToVisit || null);
                                                filtersAttended['language'] = weddingSelected?.langagueToVisit || null;

                                                change('weddingPlace', weddingSelected?.weddingPlaceToVisit || null);
                                                filtersAttended['weddingPlace'] = weddingSelected?.weddingPlaceToVisit || null;
                                                this.setState({ filtersAttended }, () => this.getTimeInfo());
                                            } else {
                                                filtersAttended['wedding'] = null;
                                                change('language', null);
                                                filtersAttended['language'] = null;
                                                change('weddingPlace', null);
                                                filtersAttended['weddingPlace'] = null;
                                                this.setState({ filtersAttended }, () => this.getTimeInfo());
                                            }
                                        }}
                                    />}
                                <Field
                                    component={SelectInput}
                                    name={'type'}
                                    type='select'
                                    label={'Selecione o tipo'}
                                    data={[
                                        { _id: ScheduleTypes.FIRST_VISIT, name: 'Visita' },
                                        { _id: ScheduleTypes.ORGANIZATION, name: 'Organização' },
                                    ]}
                                    disabled={!validateType || schedule}
                                    onChange={(e) => {
                                        // console.warn('e', e);
                                        let { filtersAttended } = this.state;
                                        if (e && e?.trim() !== '') {
                                            if (e == ScheduleTypes.ORGANIZATION) {
                                                change('startTime', null);
                                                this.setState({ validateOrganization: false });
                                            }
                                            const weddingSelected = weddings.find(f => f?._id == scheduleForm?.wedding);
                                            const lang = e == ScheduleTypes.ORGANIZATION
                                                ? weddingSelected?.organizerLanguage
                                                : weddingSelected?.langagueToVisit;
                                            const weddingPlace = e == ScheduleTypes.ORGANIZATION
                                                ? weddingSelected?.wedding_place
                                                : weddingSelected?.weddingPlaceToVisit;
                                            change('language', lang);
                                            filtersAttended['language'] = lang;
                                            change('weddingPlace', weddingPlace || null);
                                            filtersAttended['weddingPlace'] = weddingPlace || null;
                                        }
                                        filtersAttended['type'] = e && e !== '' ? e : null;
                                        this.setState({ filtersAttended }, () => {
                                            this.getAttendedUsers();
                                            this.getTimeInfo();
                                        });
                                    }}
                                />
                                <Field
                                    component={SelectInput}
                                    name={'language'}
                                    type='select'
                                    label={scheduleForm?.type == ScheduleTypes.ORGANIZATION ? 'Selecione o idioma da reunião' : 'Selecione o idioma para a visita'}
                                    data={[
                                        { _id: 'pt', name: 'Português' },
                                        { _id: 'en', name: 'Inglês' },
                                        { _id: 'pt_en', name: 'Preferencial Inglês, mas pode ser em Portugês' },
                                    ]}
                                    disabled={!validateType}
                                    onChange={(e) => {
                                        let { filtersAttended } = this.state;
                                        filtersAttended['language'] = e;
                                        this.setState({ filtersAttended }, () => {
                                            this.getAttendedUsers();
                                            this.getTimeInfo();
                                        });
                                    }}
                                />
                                <Field
                                    component={SelectInput}
                                    name={'attendingType'}
                                    label={'Selecione o tipo de atendimento'}
                                    data={attendingTypes}
                                    dataLabel='label'
                                    disabled={!validateType}
                                    onChange={(e) => {
                                        let { filtersAttended } = this.state;
                                        filtersAttended['attendingType'] = e;
                                        this.setState({ filtersAttended }, () => {
                                            this.getAttendedUsers();
                                            this.getTimeInfo();
                                        });
                                    }}
                                />
                                {scheduleForm?.type == ScheduleTypes.FIRST_VISIT ?
                                    <React.Fragment>
                                        <Field
                                            component={SelectInput}
                                            name={'weddingPlace'}
                                            label={'Selecione o local a visitar'}
                                            data={visitedLocals}
                                            disabled={specificPlace || !validateType}
                                            onChange={(e) => {
                                                let { filtersAttended } = this.state;
                                                filtersAttended['weddingPlace'] = e;
                                                this.setState({ filtersAttended }, () => {
                                                    this.getAttendedUsers();
                                                    this.getTimeInfo();
                                                });
                                            }}
                                        />
                                        <Field
                                            component={XpertGoDateTimeInput}
                                            name={'startTime'}
                                            label={'Indique horário de ínicio'}
                                            allowClear={true}
                                            disabled={!validateType}
                                            dateRef={date}
                                        />
                                        {maxEndTime && <span>Horário final (máximo): {maxEndTime}</span>}
                                        <Field
                                            component={SelectInput}
                                            name={'attendedBy'}
                                            label={'Selecione o colaborador'}
                                            data={attendedUsers}
                                            fetching={loadingAttendedUsers}
                                            disabled={!validateType}
                                            costumeLabel={getUserLabel}
                                        />
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Field
                                            component={SelectInput}
                                            name={'startTime'}
                                            label={'Selecione o horário'}
                                            fetching={loadingServices}
                                            data={services}
                                            costumeLabel={this.getServiceLabel}
                                            disabled={!validateType}
                                        />
                                    </React.Fragment>
                                }

                            </BaseForm >}
                </Modal >

                {showReserveModal
                    && <ReserveInfoModal
                        isOpen={showReserveModal}
                        saving={savingReserveModal}
                        onClose={() => this.setState({ showReserveModal: false })}
                        onSubmit={this.createWedding}
                        initialValues={{}}
                        onlyCoupleInfo={true}
                    />
                }
            </React.Fragment>
        );
    }
};

ScheduleModal = reduxForm({
    form: 'schedule_form',
    // enableReinitialize: true,
    validate: validations,
})(ScheduleModal);

const selector = formValueSelector('schedule_form');

const mapStateToProps = state => ({
    scheduleForm: {
        date: selector(state, 'date'),
        startDate: selector(state, 'startDate'),
        endDate: selector(state, 'endDate'),
        wedding: selector(state, 'wedding'),
        type: selector(state, 'type'),
        language: selector(state, 'language'),
        attendingType: selector(state, 'attendingType'),
        weddingPlace: selector(state, 'weddingPlace'),
        attendedUsers: selector(state, 'attendedUsers'),
        attendedBy: selector(state, 'attendedBy'),
        startTime: selector(state, 'startTime'),
    }
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ change }, dispatch);
}

ScheduleModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModal)