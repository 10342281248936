import React from "react";
import PropTypes from "prop-types";
import {
  TimeInputSection,
  TimeInputLabel,
  TimeInputContainer,
  InputContainer,
  InputDiv,
  InputLabelDiv,
  TimeInputField,
} from "./InputStyles";
import { TimePicker } from "antd";
import moment from "moment";
import momentTimezone from 'moment-timezone';

const XpertGoTimeInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  timeFormat,
  allowClear = true,
  disabledSeconds
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <TimeInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={timeFormat || 'HH:mm'}
        value={input?.value ? moment.utc(input.value, (timeFormat || 'HH:mm')).local() : null}
        onChange={time => {
          time ? input.onChange(moment(time, (timeFormat || 'HH:mm')).utc()) : input.onChange('')
        }}
        disabledSeconds={disabledSeconds}
        allowClear={allowClear}
      />
    </InputDiv>
  );
};

XpertGoTimeInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default XpertGoTimeInput;