import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { bindActionCreators } from 'redux';
import {
  SectionContentStyle,
  SectionTitle,
  QuestionTitleContainer,
  QuestionTitle,
  QuestionIcon,
  Counting
} from './SectionStyles';
import SectionResponse from './SectionResponse';
import SectionModal from './SectionModal';
import { AnswerQuestion, CheckUpdateCoupleTable, DeleteAnswerFile } from '../../infra/requests/WeddingProcessRequests';
import { updateQuestionAction } from '../../redux/wedding/wedding.actions';
import { CreateOptionAndUpdateAnswer } from '../../infra/requests/OptionsRequests';
import ManageItemByWeddingModal from '../../weddingday/questionOptions/ManageItemByWeddingModal';
import { correctResponseValues } from './components/OptionUtils';
import { getAnswerPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { Modal } from 'antd';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';
import Alerts from '../../components/alert/Alert';

const { confirm } = Modal;

const SectionContent = ({ printed, section = { chapters: [] }, dispatch, wedding, location: { hash }, notesOpen, history }) => {
  const [showModal, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editQuestion, setEditQuestion] = useState(undefined);
  const [showOptionModal, toggleOptionModal] = useState(false);
  const [editOption, setEditOption] = useState(undefined);

  useEffect(() => {
    //If there's a ID, we have to scroll to that question
    if (hash) {
      const questionID = hash.substr(1);

      if (questionID) {
        const questionTop = document.getElementById(questionID) ? document.getElementById(questionID).scrollIntoView(true) : 0;
        window.scrollTo({ top: questionTop, behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = question => {
    dispatch(initialize('manage_answers_form', {}));

    if (question?.answer) {
      dispatch(
        initialize('manage_answers_form', {
          ...question?.answer,
          question_obs: question?.answer?.observations !== ''
        })
      );
    }

    setEditQuestion(question);
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
    setEditQuestion(undefined);
  };

  const onModalSubmit = async values => {
    if (printed) {
      confirm({
        ...getAnswerPrintedConfirmation(),
        onOk: () => { confirmSubmitModal(values); },
        onCancel: () => { closeModal(); },
      });
    } else confirmSubmitModal(values);
  };

  const confirmSubmitModal = async (values) => {
    // const answer = correctResponseValues(values);

    // Check if couple table question
    if (inRoomPlanTest() && editQuestion?._id == '5e18723686fdaa6d7aeedd91') {
      const data = {
        newTable: values?.response?.[0]?.id
      };
      const resultCheck = await CheckUpdateCoupleTable(wedding.wedding._id, data);
      if (resultCheck?.success) {
        if (resultCheck?.data?.showWarning) {
          const ovalLWarning = resultCheck?.data?.showOvalLWarning
            ? resultCheck?.data?.ovalLWarning == 'TO_REMOVE_TABLES_BY_OVALL'
              ? `Serão removidas as mesas ${resultCheck?.data?.tablesToRemove} devido ao espaço ocupado pela nova mesa.`
              : resultCheck?.data?.ovalLWarning == 'TO_RESTORE_TABLES_BY_OVALL'
                ? `Serão recuperadas as mesas ${resultCheck?.data?.tablesToRemove} devido ao espaço ocupado pela nova mesa.`
                : null
            : null;

          confirm({
            title: 'Têm a certeza que pretende alterar a mesa do casal?',
            content: (<div>
              <p>A nova mesa não têm o mesmo número de lugares, por isso serão removidos os convidados.</p>
              {resultCheck?.data?.showOvalLWarning && ovalLWarning && <p><b>{ovalLWarning}</b></p>}
            </div>
            ),
            okText: 'Alterar mesa',
            cancelText: 'Cancelar',
            onOk: () => { saveModal(values); },
            onCancel: () => null,
          });
        } else saveModal(values);
      } else return;
    }
    // Check if question hasOnlyOneOptions and has options selected with onlyOneAvailable tag
    // else if (editQuestion?.hasOnlyOneOptions && answer?.response?.length > 0) {
    //   const respIds = answer?.response?.map(m => m?.id);
    //   const onlyOneOptionsSelected = respIds?.length > 0 && editQuestion?.options?.filter(f => f?.onlyOneAvailable)?.length > 0
    //     ? editQuestion?.options?.filter(f => f?.onlyOneAvailable).filter(f => respIds.includes(f?._id))
    //     : [];
    //   const hasOnlyOneOptionSelected = onlyOneOptionsSelected?.length > 0;

    //   if (hasOnlyOneOptionSelected) {
    //     confirm({
    //       title: 'Itens com disponibilidade limitada!',
    //       content: (<div>
    //         <p>Caso pretenda continuar com a seleção, a equipa de organização entrará em contacto com o casal para analisar a disponibilidade desses itens.</p>
    //         <p>Pretende continuar com a seleção?</p>
    //       </div>
    //       ),
    //       okText: 'Continuar',
    //       cancelText: 'Cancelar',
    //       onOk: () => saveModal(values),
    //       onCancel: () => null,
    //     });
    //   } else saveModal(values);
    // } 
    else saveModal(values);
  }

  const saveModal = async values => {
    setLoading(true);
    values = correctResponseValues(values);
    const payload = FlattenToFormData({ ...values, question: editQuestion._id });

    const { data, success, message } = await AnswerQuestion(wedding.wedding._id, payload);

    if (success) {
      dispatch(updateQuestionAction(section.tag, data));
      setLoading(false);
      closeModal();
    } else if (!success && message == "SERVER_OPTION_NOT_AVAILABLE") {
      Alerts.new({
        type: 'warning',
        title: 'Itens com disponibilidade limitada!',
        text: 'A equipa de organização entrará em contacto com o casal para analisar a disponibilidade desses items.'
      });

      closeModal();
      setLoading(false);
    }
    else setLoading(false);
  };

  const handleFileDelete = async (question) => {
    const { data, success } = await DeleteAnswerFile(wedding.wedding._id, question);

    if (success) {
      dispatch(updateQuestionAction(section.tag, data));
    }
  };

  const openOptionModal = (values, question) => {
    setEditOption(values);
    setEditQuestion(question);
    toggleOptionModal(true);
  };

  const onOptionModalSubmit = async (values) => {
    const optionOriginal = editQuestion.options.find(f => f._id === values._id);

    // If cost_price changed from default option, then create an copy based on default option
    if (optionOriginal && values.cost_price !== optionOriginal.cost_price) {
      const payload = FlattenToFormData({
        ...values,
        question: editQuestion.id,
        defaultOption: values.defaultOption ? values.defaultOption : values._id,
        wedding: wedding.wedding._id,
      });

      const resultQuestion = await CreateOptionAndUpdateAnswer(payload);
      // console.warn('resultQuestion', resultQuestion);

      if (resultQuestion.success && resultQuestion.data) {
        const question = { ...resultQuestion.data.question, answer: resultQuestion.data.answer };

        const chapterIndex = section.chapters.findIndex(f => f._id === editQuestion.chapter);
        if (chapterIndex >= 0) {
          const questionIndex = section.chapters[chapterIndex].questions.findIndex(f => f.id === editQuestion.id);
          if (questionIndex >= 0) {
            section.chapters[chapterIndex].questions.splice(questionIndex, 1, question);
          }
        }

        if (question?.answer) {
          dispatch(
            initialize('manage_answers_form', {
              ...question?.answer,
              question_obs: question?.answer?.observations !== ""
            })
          );
        }

        setEditQuestion(question);
        closeOptionModal();
      }
    } else closeOptionModal();
  }

  const closeOptionModal = () => {
    toggleOptionModal(false);
    setEditOption(undefined);
  };

  const navigateTo = () => {
    history.push(`/wedding/${wedding?.wedding?._id}/guestMap`)
  }

  return (
    <SectionContentStyle id="section-container" name="section-container" printed={printed} notesOpen={notesOpen}>
      {section.chapters.map((chapter, cIndex) => (
        <Element key={`anchor-${cIndex}`} name={`anchor-${cIndex}`}>
          <SectionTitle>
            {TranslateValue(chapter.name)}
            <Counting>
              {cIndex + 1} de {section.chapters.length}
            </Counting>
          </SectionTitle>
          {chapter.questions.map((question, qIndex) => (
            <Element
              key={`anchor-${cIndex}-${qIndex}`}
              name={`anchor-${cIndex}-${qIndex}`}
              id={question._id}
            >
              <QuestionTitleContainer>
                <QuestionTitle>{TranslateValue(question.title)}</QuestionTitle>
                {(question.type !== 9 || question.observations)
                  && question?._id !== '5e1e58a76c30747c0329e56d'
                  && (
                    <QuestionIcon
                      type="edit"
                      title="Editar resposta"
                      onClick={() => openModal(question)}
                    />
                  )}
              </QuestionTitleContainer>
              <SectionResponse
                weddingPlace={wedding.wedding.wedding_place}
                upgrade={wedding.wedding.upgrade}
                question={question}
                handleFileDelete={handleFileDelete}
                printed={printed}
                navigateTo={navigateTo} />
            </Element>
          ))}
        </Element>
      ))}
      {showModal && (
        <SectionModal
          open={showModal}
          loading={loading}
          onSubmit={onModalSubmit}
          closeModal={closeModal}
          question={editQuestion}
          editOption={openOptionModal}
        />
      )}

      {
        showOptionModal &&
        <ManageItemByWeddingModal
          open={showOptionModal}
          initialValues={editOption}
          // loading={loading}
          onSubmit={onOptionModalSubmit}
          closeModal={closeOptionModal}
        />
      }
    </SectionContentStyle>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding
});

const mapActionToProps = dispatch =>
  bindActionCreators({ updateQuestionAction, dispatch }, dispatch);

export default withRouter(connect(mapStateToProps, mapActionToProps)(SectionContent));