//Check if a option still exists in the question
export const checkOption = (value, question, weddingPlace) => {
  let res = false;

  let id = value;
  if (value.id) {
    id = value.id;
  }

  if (
    question &&
    (question.type === 4 || question.type === 5 || question.type === 6) &&
    question.options &&
    question.options.length > 0 &&
    question.options.find(x => x._id === id && (!x.local || x.local === weddingPlace))) {
    res = true;
  }
  else if (question && question.type === 10 && (id === 'true' || id === 'false')) {
    res = true;
  }

  return res;
}

//Check if a food option still exists in the question
//We pass directly the Food group (meat, fish, etc...)
export const checkFoodOption = (value, food) => {
  let res = false;

  let id = value;
  if (value.id) {
    id = value.id;
  }

  if (food && food.length > 0 && food.find(x => x._id === id)) {
    res = true;
  }

  return res;
}

//Check if a color still exists in the colors
export const checkColorOption = (value, colors) => {
  let res = false;

  let id = value;
  if (value.id) {
    id = value.id;
  }

  if (colors && colors.length > 0 && colors.find(x => x._id === id)) {
    res = true;
  }

  return res;
}

//Get the current food selection
//We pass directly the Food group (meat, fish, etc...)
export const getFoodOption = (value, food) => {
  let res = '';

  let id = value;
  if (value.id) {
    id = value.id;
  }

  if (food && Array.isArray(food) && food.length > 0) {
    res = food.find(x => x._id === id);
  }

  return res;
}

//Get the current food selection
//We pass directly the Food group (meat, fish, etc...)
export const getFoodOptionWithSidedishes = (value, food, sidedishes) => {
  let res = {
    food: '',
    sidedishes: []
  };

  let id = value;
  if (value?.id) {
    id = value.id;
  }

  if (food && Array.isArray(food) && food.length > 0) {
    res.food = food.find(x => x._id === id);
  }

  if (sidedishes && Array.isArray(sidedishes) && sidedishes.length > 0) {
    res.sidedishes = value?.sidedishes && Array.isArray(value?.sidedishes)
      ? value.sidedishes?.map(m => sidedishes.find(x => x._id === m))
      : [];
  }

  return res.food ? res : null;
}