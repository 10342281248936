import client from '../config/AxiosConfig';

export const GetOption = async id => client.get(`/options/${id}`);

export const CreateOption = async data => client.post('/options', data);

export const CreateOptionAndUpdateAnswer = async data => client.post('/options/createOptionAndUpdateAnswer', data);

export const UpdateOption = async (id, data) => client.put(`/options/${id}`, data);

export const UpdateOptionOrder = async data => client.post(`/options/order`, data);

export const DeleteOption = async (id, data) => client.delete(`/options/${id}`, data);

export const SoftDeleteOption = async (id, deleted) =>
    client.delete(`/options/softDelete/${id}/${deleted}`);

export const ActivateOption = async (id, active) =>
    client.put(`/options/activate/${id}/${active}`);


/* Category Orders */

export const GetOptionsPagination = async (page, limit, filter = "") => client.get(`/options/orders/${page}/${limit}?filter=${filter}`);

export const GetOptionOrders = async id => client.get(`/options/orders/${id}`);

export const UpdateOptionOrders = async (id, data) => client.put(`/options/orders/${id}`, data);