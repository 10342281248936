/*eslint no-useless-escape: "off"*/

import * as moment from 'moment';
import { Languages } from '../translations/AvailableTranslations';

class FormRules {
  static required(label, rule, value) {
    if (!value || value === '') {
      return { _error: `${label}_${rule}`.toUpperCase() };
    }
    return undefined;
  }
  static array(label, rule, value) {
    if (!Array.isArray(value) || (Array.isArray(value) && value.length < 1)) {
      return { _error: `${label}_${rule}_EMPTY`.toUpperCase() };
    }
    return undefined;
  }

  static isInteger(label, rule, value) {
    // if (!Number.isInteger(parseInt(value))) {
    if (value && !Number.isInteger(Number(value))) {
      return { _error: `${label}_${rule}_INVALID_NUMBER`.toUpperCase() };
    }
    return undefined;
  }

  static minNumber(label, rule, value, params) {
    if (params.length > 0) {
      const minValue = params[0];
      if (value && value < minValue) {
        return { _error: `${label}_${rule}_ERROR`.toUpperCase() };
      }
    }
    return undefined;
  }

  static maxNumber(label, rule, value = '', params) {
    if (params.length > 0) {
      const maxValue = params[0];
      if (value && value > maxValue) {
        return { _error: `${label}_${rule}_ERROR`.toUpperCase() };
      }
    }
    return undefined;
  }

  static min(label, rule, value, params) {
    if (params.length > 0) {
      const minLength = params[0];
      if (!value || value.length < minLength) {
        return { _error: `${label}_${rule}_ERROR`.toUpperCase() };
      }
    }
    return undefined;
  }

  static max(label, rule, value = '', params) {
    if (params.length > 0) {
      const maxLength = params[0];
      if (!value || value.length > maxLength) {
        return { _error: `${label}_${rule}_ERROR`.toUpperCase() };
      }
    }
    return undefined;
  }

  static email(label, rule, value) {
    const expression = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
    const isInvalid = expression.test(value) === false;
    if (isInvalid) {
      return { _error: `${label}_${rule}_INVALID`.toUpperCase() };
    }
    return undefined;
  }


  static mobilePhoneNumber(label, rule, value) {
    const expression = /^ $|^2\d{8}$|^9(1|2|3|6)\d{7}$/;
    const isInvalid = expression.test(value) === false;
    if (isInvalid) {
      return { _error: `${label}_${rule}_INVALID`.toUpperCase() };
    }
    return undefined;
  }

  static zipCode(label, rule, value) {
    const expression = /^\d{4}-\d{3}?$/;
    const isInvalid = expression.test(value) === false;
    if (isInvalid) {
      return { _error: `${label}_${rule}_INVALID`.toUpperCase() };
    }
    return undefined;
  }


  static date(label, rule, value) {
    const isInValid = moment(value).isValid() === false;
    if (isInValid) {
      return { _error: `${label}_${rule}_INVALID`.toUpperCase() };
    }
    return undefined;
  }

  static languages(label, rule, value) {
    let error = {};
    Languages.map(lang => {
      if (value && value[lang.code] && value[lang.code] !== '') {
      } else {
        error[lang.code] = {
          _error: `${label}_${rule}_REQUIRED_${lang.code}`.toUpperCase()
        };
      }
      return lang;
    });
    if (Object.keys(error).length) return error;
    return undefined;
  }

  static languagesNotRequired(label, rule, value) {
    let error = {};
    let isAnyLanguageFilled = false;

    Languages.forEach(lang => {
      if (value && value[lang.code] && value[lang.code] !== '') {
        isAnyLanguageFilled = true;
      }
    });

    if (isAnyLanguageFilled) {
      Languages.forEach(lang => {
        if (!value[lang.code] || value[lang.code] === '') {
          error[lang.code] = {
            _error: `${label}_${rule}_REQUIRED_${lang.code}`.toUpperCase()
          };
        }
      });
    }

    return Object.keys(error).length ? error : undefined;
  }


  static languagesNoSpaces(label, rule, value) {
    let error = {};
    Languages.map(lang => {
      if (value && value[lang.code] && value[lang.code] !== '') {
        if (value[lang.code] && value[lang.code].indexOf(' ') >= 0) {
          error[lang.code] = {
            _error: `${label}_${rule}_${lang.code}_INVALID`.toUpperCase()
          };
        }
      } else {
        error[lang.code] = {
          _error: `${label}_${rule}_REQUIRED_${lang.code}`.toUpperCase()
        };
      }
      return lang;
    });
    if (Object.keys(error).length) return error;
    return undefined;
  }

  static noSpaces(label, rule, value) {
    if (!value) return { _error: `${label}_${rule}`.toUpperCase() };
    if (value && value.indexOf(' ') >= 0) {
      return { _error: `${label}_${rule}`.toUpperCase() };
    }
    return undefined;
  }

  static domain(label, rule, value) {
    const expression = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    const isInvalid = expression.test(value) === false;
    if (isInvalid) {
      return { _error: `${label}_${rule}_INVALID`.toUpperCase() };
    }
    return undefined;
  }

  static hexColour(label, rule, value) {
    if (!value) return undefined;
    const expression = /^#([0-9A-F]{3}){1,2}$/i;
    const isInvalid = expression.test(value) === false;
    if (isInvalid) {
      return { _error: `${label}_${rule}_INVALID`.toUpperCase() };
    }
    return undefined;
  }

  static emptyLanguage(label, rule, value) {
    if (!value || value === '')
      return { _error: { en: `${label}_${rule}_INVALID`.toUpperCase() } };
    if (Object.keys(value).length === 0)
      return { _error: { en: `${label}_${rule}_EMPTY`.toUpperCase() } };

    if (!value?.en)
      return { _error: { en: `${label}_${rule}_PRIMARY`.toUpperCase() } };

    const errors = [];
    Object.keys(value).map(key => {
      if (!value[key] || value[key] === '')
        errors.push({ key, _error: `${label}_${rule}_EMPTY`.toUpperCase() });
      return key;
    });

    if (errors.length) {
      const result = { _error: {} };
      errors.map(error => {
        result._error[error.key] = `${label}_${rule}_EMPTY`.toUpperCase();
        return error;
      });
      return result;
    }

    return undefined;
  }
}

export default FormRules;
