import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Radio } from 'antd';
import { TableFilterSection } from '../../../styles/BasicStyles';
import TextInput from '../../../components/inputs/TextInput';
import SelectInput from '../../../components/inputs/SelectInput';
import XpertGoRangePickerInput from '../../../components/inputs/XpertGoRangePickerInput';
import DateInput from '../../../components/inputs/DateInput';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import RadioGroup from 'antd/lib/radio/group';
import { InputDiv, InputLabelDiv } from '../../../components/inputs/InputStyles';

export const ReserveLogType = {
  RESERVE: 'R', // Reserves
  WAITING_DATE: 'WD', // Waiting Dates
  INTEREST: 'ID', // Interest Dates
  SCHEDULE: 'S', // Schedules
};
class ReserveLogsFilters extends Component {
  state = {
    search: '',
    local: '',
    searchUser: '',
    dates: [],
    date: '',
    logType: ReserveLogType.RESERVE,
    loading: false,
  };

  componentDidMount = () => {

  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (key !== 'dates' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      } else if (key == 'dates' && this.state[key] != []) {
        query[key] = this.state[key];
      }
      return key;
    });

    queryChange(JSON.stringify(query));
  };

  render() {
    const { users } = this.props;
    const { search, searchUser, local, user, dates, date, logType, loading } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: searchUser,
                onChange: event =>
                  this.onInputChange('searchUser', event.target.value, 1000)
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar por utilizador"
              placeholder="Procurar por utilizador"
            />
          </Col>
          <Col xs={24} md={6}>
            <XpertGoRangePickerInput
              label="Intervalo de datas"
              allowClear={true}
              placeholder="Escolher datas"
              input={{
                value: dates,
                onChange: value => this.onInputChange('dates', value)
              }}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 1000)
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar por noivos"
              placeholder="Procurar por noivos"
            />
          </Col>
          {/* <Col xs={24} md={6}>
            <SelectInput
              label="Utilizador"
              allowClear
              placeholder="Escolher utilizador"
              data={users}
              dataKey='id'
              dataValue='name'
              input={{
                value: user,
                onChange: value => this.onInputChange('user', value)
              }}
              meta={{valid: true}}
            />
          </Col> */}
          <Col xs={24} md={4}>
            <SelectInput
              label={logType === ReserveLogType.WAITING_DATE
                ? 'Local Lista de Espera'
                : logType === ReserveLogType.INTEREST
                  ? 'Local Interesse'
                  : logType === ReserveLogType.SCHEDULE
                    ? 'Local da Visita'
                    : 'Local da Reserva'}
              allowClear
              placeholder="Escolher local"
              data={logType === ReserveLogType.SCHEDULE
                ? [
                  { _id: 1, name: 'Solar da Levada' },
                  { _id: 2, name: 'Quinta Lago dos Cisnes' }
                ] : [
                  { _id: 1, name: 'Solar da Levada' },
                  { _id: 2, name: 'Quinta Lago dos Cisnes' },
                  { _id: 3, name: 'Ambos os espaços' },
                ]}
              input={{
                value: local,
                onChange: value => this.onInputChange('local', value)
              }}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <DateInput
              label={logType === ReserveLogType.WAITING_DATE
                ? 'Data Lista de Espera'
                : logType === ReserveLogType.INTEREST
                  ? 'Data Interesse'
                  : logType === ReserveLogType.SCHEDULE
                    ? 'Data do Atendimento'
                    : 'Data da Reserva'}
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputDiv first={false}>
              <InputLabelDiv>Tipo de Logs</InputLabelDiv>
              <RadioGroup style={{ marginTop: 6 }}
                disabled={loading}
                onChange={(e) => this.onInputChange('logType', e.target.value)}
                value={logType}>
                <Radio value={ReserveLogType.RESERVE}>Reservas</Radio>
                <Radio value={ReserveLogType.WAITING_DATE}>Lista de Espera</Radio>
                <Radio value={ReserveLogType.INTEREST}>Datas de Interesse</Radio>
                <Radio value={ReserveLogType.SCHEDULE}>Atendimentos</Radio>
              </RadioGroup>
            </InputDiv>
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(ReserveLogsFilters);