import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, initialize, FieldArray, formValueSelector, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  GetNewsDetail,
  UpdateNews,
  SaveImage,
  DeleteImage,
  SaveFile,
  DeleteFile
} from '../../infra/requests/NewsRequests';
import TitleInput from '../../components/inputs/TitleInput';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import ImageInput from '../../components/inputs/ImageInput';
import DateInput from '../../components/inputs/DateInput';
import ImageGalleryInput from '../../components/inputs/ImageGalleryInput';
import FilesGallery from '../../components/inputs/FilesGallery';
import SelectInput from '../../components/inputs/SelectInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { GetCategoryOptions } from '../../infra/services/NewsCategories/NewsCategories';
import { NewsPlatform } from './NewsPage';

const validations = (values) => {
  let errors = {};

  const rules = {
    title: 'required|languages',
    image: 'required',
    category: 'required',
    date: 'required',
    platform: 'required',
    description: 'required|languages'
  };

  if (values?.platform && (values?.platform === NewsPlatform.CONFIRMED || values?.platform === NewsPlatform.BOTH)) {
    rules['weddingPlace'] = 'required';
  }
  errors = FormValidator.make(rules)(values);

  return errors;
};

class ManageUpgradePage extends Component {
  state = {
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;
    const { data } = await GetNewsDetail(params.id);
    //console.warn('Data', data);
    dispatch(initialize('manage_news_form', data));
    this.setState({ loading: false });
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      if (values.image && !values.image.blob) {
        delete values.image;
      }
      const payload = FlattenToFormData(values);
      const { success } = await UpdateNews(params.id, payload);
      if (success) return history.push('/news');
      return this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/news');
  };

  saveNewImage = async image => {
    const {
      match: { params }
    } = this.props;
    const payload = new FormData();
    payload.append('image', image.blob);
    return await SaveImage(params.id, payload);
  };

  deleteImage = async image => {
    const {
      match: { params }
    } = this.props;
    return await DeleteImage(params.id, image);
  };

  saveNewFile = async file => {
    const {
      match: { params }
    } = this.props;
    const payload = new FormData();
    payload.append('file', file);
    return await SaveFile(params.id, payload);
  };

  deleteFile = async file => {
    const {
      match: { params }
    } = this.props;
    return await DeleteFile(params.id, file);
  };

  render() {
    const { handleSubmit } = this.props;
    const { newsForm } = this.props;
    const { loading } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Editar Notícia</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Col xs={6} offset={8}>
                <Field
                  name="image"
                  label="Imagem de capa"
                  component={ImageInput}
                  ratio={0.6}
                />
              </Col>
              <Col xs={10} offset={7}>
                <Field
                  component={SelectInput}
                  name={'category'}
                  label={'Categoria'}
                  placeholder={'Escolha a categoria'}
                  type="select"
                  data={GetCategoryOptions()}
                  dataKey={'_id'}
                  dataLabel={'name'}
                />
                <Field
                  component={SelectInput}
                  data={[
                    { _id: NewsPlatform.CONFIRMED, name: 'Noivos' },
                    { _id: NewsPlatform.RESERVE, name: 'Reservas Não Confirmadas' },
                    { _id: NewsPlatform.BOTH, name: 'Ambas' }
                  ]}
                  label='Plataforma *'
                  name={`platform`}
                  placeholder={'Selecione a plataforma'}
                  onChange={(value) => {
                    if (value === NewsPlatform.RESERVE) {
                      this.props.change('weddingPlace', 3);
                    }
                  }}
                />
                <Field
                  component={SelectInput}
                  data={[
                    { _id: 1, name: 'Solar da Levada' },
                    { _id: 2, name: 'Quinta Lago dos Cisnes' },
                    { _id: 3, name: 'Ambas' }
                  ]}
                  label='Local'
                  name={`weddingPlace`}
                  placeholder={'Selecione o local'}
                  disabled={newsForm?.platform && newsForm?.platform === NewsPlatform.RESERVE}
                />
                <Field
                  component={TitleInput}
                  name={'title'}
                  type="text"
                  label={'Título'}
                  placeholder={'Título da notícia'}
                />
                <Field
                  component={DateInput}
                  name="date"
                  label="Data"
                  placeholder="Data da notícia"
                />
                <Field
                  component={LanguagesInput}
                  name={'description'}
                  type="draft"
                  label={'Descrição'}
                  placeholder={'Descrição do Upgrade'}
                />
              </Col>
              <Col xs={20} offset={2}>
                <SectionTitle subsection>Galeria de Imagens</SectionTitle>
                <FieldArray
                  name="gallery"
                  component={ImageGalleryInput}
                  handleAdd={this.saveNewImage}
                  handleDelete={this.deleteImage}
                />
              </Col>
              <Col xs={20} offset={2}>
                <SectionTitle subsection>Ficheiros</SectionTitle>
                <FieldArray
                  name="attachments"
                  component={FilesGallery}
                  handleAdd={this.saveNewFile}
                  handleDelete={this.deleteFile}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageUpgradePage = reduxForm({
  form: 'manage_news_form',
  validate: validations
})(ManageUpgradePage);

const selector = formValueSelector('manage_news_form');

const mapStateToProps = state => ({
  newsForm: {
    title: selector(state, 'title'),
    date: selector(state, 'date'),
    category: selector(state, 'category'),
    platform: selector(state, 'platform'),
    weddingPlace: selector(state, 'weddingPlace'),
  }
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(ManageUpgradePage));
