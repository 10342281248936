import { Button } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

const XpertGoLeavePageBlocker = ({ when, message, }) => {

  useEffect(() => {
    if (!when) return () => { };
    const beforeUnloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };
    window.addEventListener("beforeunload", beforeUnloadCallback);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadCallback);
    };
  }, [when, message]);


  // TOD: Check url queryparams edit, if exists let reload, if not show prompt
  return <Prompt
    when={when}
    message={(location, action) => {
      // console.warn('action', action);
      // console.warn('location', location);

      const queryEdit = new URLSearchParams(location.search).get('edit') !== null
        && new URLSearchParams(location.search).get('edit') !== undefined
        ? true : false;
      const queryDate = new URLSearchParams(location.search).get('date');

      // if (action === 'POP') {
      //   console.log("Backing up...")
      // }

      return queryEdit && queryDate !== null
        ? true
        : message;
    }} />;
};

XpertGoLeavePageBlocker.propTypes = {
  when: PropTypes.bool.isRequired,
};

export default XpertGoLeavePageBlocker;
