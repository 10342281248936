export const Costumizations = {
  original: {
    timeline: true,
    food: true,
    drinks: true,
    staff: true,
    decoration: true,
    style: true,
    ceremony: true,
    party: true,
    upgrade: true,
    budget: false,
    pending_and_budget: true,
    unanswered: false,
    photo: true,
    public_notes: true,
    private_notes: true,
    guests: true,
    contacts: false,
    about_us: true
  },
  noivos: {
    timeline: true,
    food: true,
    drinks: true,
    staff: true,
    decoration: true,
    style: true,
    ceremony: true,
    party: true,
    upgrade: false,
    budget: false,
    pending_and_budget: true,
    unanswered: true,
    photo: true,
    public_notes: true,
    private_notes: false,
    guests: true,
    contacts: false,
    about_us: true
  },
  budget_noivos: {
    timeline: false,
    food: false,
    drinks: false,
    staff: false,
    decoration: false,
    style: false,
    ceremony: false,
    party: false,
    upgrade: false,
    budget: true,
    pending_and_budget: false,
    unanswered: false,
    photo: false,
    public_notes: true,
    private_notes: false,
    guests: false,
    contacts: false,
    about_us: false
  },
  budget_intern: {
    timeline: false,
    food: false,
    drinks: false,
    staff: false,
    decoration: false,
    style: false,
    ceremony: false,
    party: false,
    upgrade: false,
    budget: true,
    pending_and_budget: false,
    unanswered: false,
    photo: false,
    public_notes: true,
    private_notes: true,
    guests: false,
    contacts: false,
    about_us: false
  },
  cozinha: {
    timeline: true,
    food: true,
    drinks: true,
    staff: false,
    decoration: false,
    style: false,
    ceremony: false,
    party: false,
    upgrade: false,
    budget: false,
    pending_and_budget: false,
    unanswered: false,
    photo: false,
    public_notes: true,
    private_notes: true,
    guests: true,
    contacts: false,
    about_us: false
  },
  decor: {
    timeline: true,
    food: false,
    drinks: false,
    staff: false,
    decoration: true,
    style: true,
    ceremony: true,
    party: true,
    upgrade: false,
    budget: false,
    pending_and_budget: false,
    unanswered: false,
    photo: false,
    public_notes: true,
    private_notes: true,
    guests: false,
    contacts: false,
    about_us: false
  },
  coordenadores: {
    timeline: true,
    food: true,
    drinks: true,
    staff: true,
    decoration: true,
    style: true,
    ceremony: true,
    party: true,
    upgrade: true,
    budget: false,
    pending_and_budget: true,
    unanswered: false,
    photo: true,
    public_notes: true,
    private_notes: true,
    guests: true,
    contacts: true,
    about_us: true
  },
  party: {
    timeline: true,
    food: false,
    drinks: false,
    staff: false,
    decoration: false,
    style: false,
    ceremony: true,
    party: true,
    upgrade: false,
    budget: false,
    pending_and_budget: false,
    unanswered: false,
    photo: false,
    public_notes: true,
    private_notes: true,
    guests: false,
    contacts: false,
    about_us: false
  }
};

export const DefaultCustomizations = {
  timeline: false,
  food: false,
  drinks: false,
  staff: false,
  decoration: false,
  style: false,
  ceremony: false,
  party: false,
  upgrade: false,
  budget: false,
  pending_and_budget: false,
  unanswered: false,
  photo: false,
  public_notes: false,
  private_notes: false,
  guests: false,
  contacts: false,
  about_us: false
};

export const CostumeDefinitions = [
  { _id: 'original', name: 'ORIGINAL', drive: false },
  { _id: 'cozinha', name: 'Cozinha', drive: true },
  { _id: 'decor', name: 'Decor e outros', drive: true },
  { _id: 'coordenadores', name: 'Coordenadores', drive: true },
  { _id: 'noivos', name: 'Noivos', drive: false },
  { _id: 'budget_noivos', name: 'Orçamento Noivos', drive: false },
  { _id: 'party', name: 'Cerimónia e party', drive: true },
  // { _id: 'budget_intern', name: 'Orçamento interno' },
];

export const CustomizationsForm = [
  'timeline',
  'food',
  'drinks',
  'staff',
  'decoration',
  'style',
  'ceremony',
  'party',
  'upgrade',
  'budget',
  'pending_and_budget',
  'unanswered',
  'photo',
  'public_notes',
  'private_notes',
  'guests',
  'contacts',
  'about_us'
];