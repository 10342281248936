import styled from 'styled-components';

export const WeddingHeader = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  height: 64px;
  position: relative;
  width: calc(100% - 100px);
`;

export const Title = styled.div`
  color: white;
  font-size: 24px;
  line-height: 24px;
`;

export const Information = styled.div`
  color: white;
  font-size: 14px;
  line-height: 14px;
  margin-top: 5px;
`;

export const SpaceBetween = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  margin: 0 10px;
`;
