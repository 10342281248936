import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import { getOrganizers } from '../../infra/requests/AdminsRequests';
import { isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees } from '../../infra/requests/UsersRequests';
import YearInput from '../../components/inputs/YearInput';
import XpertGoRangePickerInput from '../../components/inputs/XpertGoRangePickerInput';
import BaseButton from '../../components/buttons/BaseButton';
import Alerts from '../../components/alert/Alert';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import moment from 'moment';

const attendingScheduleStatus = [
  {
    _id: 1,
    name: 'Presentes',
    value: 'P'
  },
  {
    _id: 2,
    name: 'Faltaram',
    value: 'M'
  },
];

class AttendingSchedulesFilters extends Component {
  timeout = 0;
  state = {
    search: '',
    // attendedBy: '',
    status: '',
    weddingPlace: '',
    date: '',
    dates: [],
    year: null,
    employees: [],
    status: [],
    loading: false
  };

  componentDidMount = async () => {
    // if (!isCurrentUserEmployeeAttending()) {
    //   const { data } = await GetAttendingEmployees();
    //   this.setState({ employees: data || [] });
    // }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (key !== 'employees' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    // queryChange(JSON.stringify(query));
    queryChange(query);
  };

  // Disable dates
  disabledDate = (current) => {
    return current && current.isSameOrBefore(moment.utc().subtract(7, 'days').startOf('day'), 'day');
  }

  disabledYear = (current) => {
    return current && current.year() <= moment.utc().year();
  }

  render() {
    const { locals } = this.props;
    const { search, weddingPlace, date, dates, year, status, loading } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              input={{
                value: search,
                onChange: event => {
                  this.onInputChange('search', event.target.value, 600)
                }
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar"
              placeholder="Procurar por nomes ou emails"
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Data"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: date,
                onChange: value => {
                  this.onInputChange('date', value);
                  this.onInputChange('dates', []);
                  this.onInputChange('year', null);
                }
              }}
              disabledDate={this.disabledDate}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={6}>
            <XpertGoRangePickerInput
              label="Intervalo de datas"
              allowClear={true}
              placeholder="Escolher datas"
              input={{
                value: dates,
                onChange: value => {
                  this.onInputChange('dates', value);
                  this.onInputChange('date', null);
                  this.onInputChange('year', null);
                }
              }}
              meta={{ valid: true }}
              // disabledDate={this.disabledDate}
              disabled={loading}
            />
          </Col>
          {/* <Col xs={24} sm={12} md={5} lg={3} xl={2}>
            <YearInput
              label="Ano"
              disabled={loading}
              placeholder="Escolher ano"
              input={{
                value: year,
                onChange: value => {
                  this.onInputChange('dates', []);
                    this.onInputChange('date', null);
                    this.onInputChange('year', null);
                  }
              }}
              meta={{ valid: true }}
            />
          </Col> */}
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Local"
              disabled={loading}
              allowClear
              placeholder="Escolher local"
              data={locals}
              input={{
                value: weddingPlace,
                onChange: value => this.onInputChange('weddingPlace', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Estado"
              disabled={loading}
              allowClear
              placeholder="Escolher estado"
              data={attendingScheduleStatus}
              dataKey={'value'}
              input={{
                value: status,
                onChange: value => this.onInputChange('status', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          {/* <Col xs={24} sm={12} md={5} lg={1} xl={1} style={{ height: 58, display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <BaseButton
              type={'primary'}
              icon={'filter'}
              text={'Filtrar'}
              disabled={loading}
              onClick={() => {
                if (search && search.trim() !== '') {
                  this.constructQuery();
                } else {
                  Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Insira o email do/a noivo/noiva'
                  });
                }
              }}
            />
          </Col> */}
        </Row>
      </TableFilterSection >
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(AttendingSchedulesFilters);
