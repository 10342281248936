import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import BaseButton from '../../components/buttons/BaseButton';
import { GetArchived, GetArchivedByWedding, GetArchivedQuestionForBudget } from '../../infra/requests/ArchivedRequests';
import BasicInfoComponent from '../../process/dashboard/components/BasicInfoComponent';
import {
  DashboardContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  SpinLoading,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import BudgetComponent from '../../process/dashboard/components/BudgetComponent';
import UnansweredQuestionsComponent from '../../process/dashboard/components/UnansweredQuestionsComponent';
import StyleComponent from '../../process/dashboard/components/StyleComponent';
import OurDayComponent from '../../process/dashboard/components/OurDayComponent';
import AboutUsComponent from '../../process/dashboard/components/AboutUsComponent';
import CeremonyComponent from '../../process/dashboard/components/CeremonyComponent';
import StaffComponent from '../../process/dashboard/components/StaffComponent';
import GuestsComponent from '../../process/dashboard/components/GuestsComponent';
import FoodComponent from '../../process/dashboard/components/FoodComponent';
import DrinksComponent from '../../process/dashboard/components/DrinksComponent';
import SimpleDecorationComponent from '../../process/dashboard/components/SimpleDecorationComponent';
import PartyComponent from '../../process/dashboard/components/PartyComponent';
import PrivateNotesComponent from '../../process/dashboard/components/PrivateNotesComponent';
import ExportationModal from './components/ExportationModal';
import LogsComponent from './components/LogsComponent';
import { GetConfigByArchived, GetConfigByWedding } from '../../infra/requests/ConfigsRequests';
import { GetQuestionForBudget } from '../../infra/requests/QuestionsRequests';

class ArchivedDetails extends Component {
  state = {
    openModal: false,
    info: {},
    venueConfig: null,
    questionsPaymentMethods: [],
    paymentPhases: [],
    ready: false
  };

  componentDidMount() {
    this.getArchived();
  }

  getArchived = async () => {
    const {
      match: { params }
    } = this.props;
    const searchByWedding = this.props.match.url.includes('/archived/wedding/') ? true : false;
    const { data, success } = searchByWedding
      ? await GetArchivedByWedding(params.id)
      : await GetArchived(params.id);
    const resultVenueConfig = await GetConfigByArchived(data._id)
    // const resultPaymentMethods = await GetArchivedQuestionForBudget(params.id);
    this.setState({
      ready: true,
      info: data || {},
      questionsPaymentMethods: success && data?.budget?.questions
        ? data?.budget?.questions
        : [],
      paymentPhases: success && data?.budget?.paymentPhases
        ? data?.budget?.paymentPhases
        : [],
      venueConfig: success && resultVenueConfig?.data
        ? resultVenueConfig?.data
        : null
    });
  };

  handleOnCancel = () => {
    const { history, location } = this.props;
    const fromReserveAgenda = new URLSearchParams(location.search).get('page') === 'RA' ? true : false;
    const fromReservePriceAgenda = new URLSearchParams(location.search).get('page') === 'RP' ? true : false;
    const fromReserve = new URLSearchParams(location.search).get('page') === 'R' ? true : false;
    const fromWaitingDateList = new URLSearchParams(location.search).get('page') === 'WD' ? true : false;
    const fromInterestDateList = new URLSearchParams(location.search).get('page') === 'ID' ? true : false;
    const fromSchedules = new URLSearchParams(location.search).get('page') === 'R' ? true : false;

    if (fromReservePriceAgenda) history.push('/reserves-prices');
    else if (fromReserveAgenda || fromReserve) return history.push('/reserves');
    else if (fromWaitingDateList) return history.push('/waiting-dates');
    else if (fromInterestDateList) return history.push('/interest-dates');
    else if (fromSchedules) return history.push('/schedules');
    else history.push('/archived');
  };

  openExportModal = () => {
    this.setState({
      openModal: true
    });
  }

  closeExportModal = () => {
    this.setState({
      openModal: false
    });
  }

  render() {
    const { match: { params }, history } = this.props;
    const { ready, info, openModal } = this.state;
    const { venueConfig, questionsPaymentMethods, paymentPhases } = this.state;
    const weddingInfo = info && info.about ? info?.about : info?.wedding;

    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{info?.name}</PageTitle>
            {moment.utc(info?.date).format('DD-MM-YYYY')}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Exportar"
              onClick={this.openExportModal}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <DashboardContainer>
          <Row>
            <Col xs={24}>
              <BasicInfoComponent weddingInfo={weddingInfo} />
            </Col>
            <Col xs={24}>
              <PrivateNotesComponent
                weddingInfo={weddingInfo}
                canEdit={false}
              />
            </Col>
            <Col xs={24}>
              <AboutUsComponent
                archived={true}
                weddingInfo={weddingInfo}
                structureInfo={info?.structure}
                notes={info?.notes}
                />
            </Col>
            <Col xs={24}>
              <GuestsComponent
                archived
                guests={info?.guests}
                minPeople={weddingInfo?.min_people}
                notes={info?.notes}
                weddingInfo={weddingInfo}
              />
            </Col>
            <Col xs={24}>
              <OurDayComponent
                archived
                day={info?.day}
                notes={info?.notes} />
            </Col>
            <Col xs={24}>
              <FoodComponent
                archived={true}
                structureInfo={info?.structure}
                foodInfo={info?.food}
                colorsInfo={info?.colours}
                weddingInfo={info}
                notes={info?.notes}
              />
            </Col>
            <Col xs={24}>
              <DrinksComponent
                archived={true}
                structureInfo={info?.structure}
                foodInfo={info?.food}
                colorsInfo={info?.colours}
                weddingInfo={info}
                notes={info?.notes}
              />
            </Col>
            <Col xs={24}>
              <StaffComponent
                archived
                structureInfo={info?.structure}
                staffInfo={info?.staff?.list}
                weddingInfo={info}
                notes={info?.notes}
              />
            </Col>
            <Col xs={24}>
              <SimpleDecorationComponent
                archived={true}
                structureInfo={info?.structure}
                foodInfo={info?.food}
                colorsInfo={info?.colours}
                weddingInfo={info}
                notes={info?.notes}
              />
            </Col>
            <Col xs={24}>
              <StyleComponent
                archived={true}
                style={info?.style}
                notes={info?.notes} />
            </Col>
            <Col xs={24}>
              <CeremonyComponent
                archived={true}
                structureInfo={info?.structure}
                foodInfo={info?.food}
                colorsInfo={info?.colours}
                weddingInfo={weddingInfo}
                notes={info?.notes}
              />
            </Col>
            <Col xs={24}>
              <PartyComponent
                archived={true}
                structureInfo={info?.structure}
                foodInfo={info?.food}
                colorsInfo={info?.colours}
                weddingInfo={weddingInfo}
                notes={info?.notes}
              />
            </Col>
            <Col xs={24}>
              <BudgetComponent
                archived={true}
                weddingInfo={weddingInfo}
                budgetInfo={info?.budget}
                notes={info?.notes}
                upgrade={info?.upgrade}
                venueConfig={venueConfig}
                questionsPaymentMethods={questionsPaymentMethods}
                paymentPhases={paymentPhases}
              />
            </Col>
            <Col xs={24}>
              <UnansweredQuestionsComponent
                archived={true}
                guestsInfo={info?.guests}
                structureInfo={info?.structure}
                foodInfo={info?.food}
                colorsInfo={info?.colours}
                weddingInfo={weddingInfo}
              />
            </Col>
            <Col xs={24}>
              <LogsComponent
                logs={info?.logs}
              />
            </Col>
          </Row>
        </DashboardContainer>
        {
          openModal &&
          <ExportationModal
            weddingID={info?._id}
            onCancel={this.closeExportModal}
            openModal={openModal}
            language={weddingInfo.language || 'pt'}
          />
        }
      </React.Fragment>
    );
  }
}

export default ArchivedDetails;
