import WeddingIcon from '../../assets/menu/ic_wedding.svg';
import ChurchIcon from '../../assets/menu/ic_church.svg';
import CateringIcon from '../../assets/menu/ic_catering.svg';
import UsersIcon from '../../assets/menu/ic_users.svg';
import AdminsIcon from '../../assets/menu/ic_admins.svg';
import InformationIcon from '../../assets/menu/ic_information.svg';
import ColoursIcon from '../../assets/menu/ic_colours.svg';
import SectionsIcon from '../../assets/menu/ic_sections.svg';
import InspirationsIcon from '../../assets/menu/ic_inspirations.svg';
import PagesIcon from '../../assets/menu/ic_pages.svg';
import HomeIcon from '../../assets/menu/ic_home.svg';
import NewsIcon from '../../assets/menu/ic_news.svg';
import TranslationIcon from '../../assets/menu/ic_translations.svg';
import SuppliersIcon from '../../assets/menu/ic_catering_report.svg';
import ArchivedIcon from '../../assets/menu/ic_archived.svg';
import SystemIcon from '../../assets/menu/ic_system.svg';
import ContentIcon from '../../assets/menu/ic_content.svg';
import SupplierIcon from '../../assets/menu/ic_supplier.svg';
import FlowerIcon from '../../assets/menu/ic_flower.svg';
import ReportsIcon from '../../assets/menu/ic_reports.svg';
import PackageIcon from '../../assets/menu/ic_package.svg';
import IngredientsIcon from '../../assets/menu/ic_ingredients.svg';
import OptionsIcon from '../../assets/menu/ic_options.svg';
import QuestionsIcon from '../../assets/menu/ic_questions.svg';
import WaiterIcon from '../../assets/icons/waiter_menu.svg';
import LogsIcon from '../../assets/menu/ic_logs.svg';
import EmployeesIcon from '../../assets/menu/ic_employees.svg';
import CalendarIcon from '../../assets/menu/ic_calendar.svg';
import PaymentIcon from '../../assets/menu/ic_payment.svg';
import ReceiptIcon from '../../assets/menu/ic_receipt.svg';
import ReserveIcon from '../../assets/menu/ic_reserve.svg';
import ReservePriceIcon from '../../assets/menu/ic_calendar_price.svg';
import ReserveTimeIcon from '../../assets/menu/ic_calendar_time.svg';
import WaitingListIcon from '../../assets/menu/ic_waiting_list.svg';
import InterestListIcon from '../../assets/menu/ic_interest_list.svg';
import PaymentsTeamComplete from '../../assets/icons/payment_team_complete.svg';
import { isCurrentUserOrganizer } from '../../infra/helpers/Helpers';
import { PermissionsEnum } from '../../infra/helpers/Permissions';

export default [
  {
    type: 'menu',
    to: '/',
    external_icon: true,
    size: 20,
    icon: HomeIcon,
    name: 'Dashboard',
    exact: true
  },
  {
    type: 'submenu',
    external_icon: true,
    size: 20,
    icon: ChurchIcon,
    name: 'Casamentos',
    to: [
      {
        type: 'menu',
        to: '/weddings',
        external_icon: true,
        size: 20,
        icon: WeddingIcon,
        name: 'Casamentos',
        exact: false,
        permissions: [PermissionsEnum.LIST_WEDDING]
      },
      {
        type: 'menu',
        to: '/weddings-calendar',
        external_icon: true,
        size: 20,
        icon: CalendarIcon,
        name: 'Calendário',
        exact: false,
        permissions: [PermissionsEnum.CALENDAR_WEDDING],
      },
      {
        type: 'menu',
        to: '/logs',
        external_icon: true,
        size: 20,
        icon: LogsIcon,
        name: 'Logs',
        exact: true,
        permissions: [PermissionsEnum.LOGS],
      },
      {
        type: 'menu',
        to: '/users',
        external_icon: true,
        size: 20,
        icon: UsersIcon,
        name: 'Utilizadores',
        exact: false,
        permissions: [PermissionsEnum.LIST_USER],
      },
      {
        type: 'menu',
        to: '/weddings-payments',
        external_icon: true,
        size: 20,
        icon: PaymentsTeamComplete,
        name: 'Pagamentos às Equipas',
        exact: false,
        permissions: [PermissionsEnum.WEDDING_PAYMENTS],
      },
      {
        type: 'menu',
        to: '/archived',
        external_icon: true,
        size: 20,
        icon: ArchivedIcon,
        name: 'Arquivo',
        exact: false,
        permissions: [PermissionsEnum.LIST_ARCHIVE],
      }
    ]
  },
  {
    type: 'submenu',
    external_icon: true,
    size: 20,
    icon: ReserveIcon,
    name: 'Reservas',
    to: [
      {
        type: 'menu',
        to: '/reserves-agenda',
        external_icon: true,
        size: 20,
        icon: CalendarIcon,
        name: 'Agenda',
        exact: false,
        permissions: [PermissionsEnum.RESERVES_AGENDA],
      },
      {
        type: 'menu',
        to: '/reserves',
        external_icon: true,
        size: 20,
        icon: ReserveIcon,
        name: 'Reservas',
        exact: false,
        permissions: [PermissionsEnum.LIST_RESERVE],
      },
      {
        type: 'menu',
        to: '/reserves-prices',
        external_icon: true,
        size: 20,
        icon: ReservePriceIcon,
        name: 'Preços',
        exact: false,
        permissions: [PermissionsEnum.RESERVE_PRICES_AGENDA],
      },
      {
        type: 'menu',
        to: '/waiting-dates',
        external_icon: true,
        size: 20,
        icon: WaitingListIcon,
        name: 'Lista de Espera',
        exact: false,
        permissions: [PermissionsEnum.LIST_WAITING_DATES],
      },
      {
        type: 'menu',
        to: '/interest-dates',
        external_icon: true,
        size: 20,
        icon: InterestListIcon,
        name: 'Datas de Interesse',
        exact: false,
        permissions: [PermissionsEnum.LIST_INTEREST_DATES],
      },
      {
        type: 'menu',
        to: '/schedules',
        external_icon: true,
        size: 20,
        icon: ReserveTimeIcon,
        name: 'Atendimentos',
        exact: true,
        permissions: [PermissionsEnum.LIST_SCHEDULE],
      },
      {
        type: 'menu',
        to: '/schedule-agenda',
        external_icon: true,
        size: 20,
        icon: CalendarIcon,
        name: 'Horários',
        exact: false,
        permissions: [PermissionsEnum.ORGANIZER_SCHEDULE_SERVICES],
      },
      {
        type: 'menu',
        to: '/reserves-logs',
        external_icon: true,
        size: 20,
        icon: LogsIcon,
        name: 'Logs',
        exact: true,
        permissions: [PermissionsEnum.RESERVES_LOGS],
      },
    ]
  },
  {
    type: 'submenu',
    external_icon: true,
    size: 20,
    icon: PackageIcon,
    name: 'Encomendas',
    to: [
      {
        type: 'menu',
        name: 'Categorias',
        to: '/categories',
        external_icon: true,
        size: 20,
        icon: SupplierIcon,
        exact: true,
        permissions: [PermissionsEnum.LIST_CATEGORY],
      },
      {
        type: 'menu',
        name: 'Categorias: Opções',
        to: '/order-options',
        external_icon: true,
        size: 20,
        icon: OptionsIcon,
        exact: true,
        permissions: [PermissionsEnum.LIST_CATEGORY_OPTIONS],
      },
      {
        type: 'menu',
        name: 'Categorias: Perguntas',
        to: '/order-questions',
        external_icon: true,
        size: 20,
        icon: QuestionsIcon,
        exact: true,
        permissions: [PermissionsEnum.CATEGORIES_QUESTIONS],
      },
      {
        type: 'menu',
        name: 'Ingredientes',
        to: '/ingredients',
        external_icon: true,
        size: 20,
        icon: IngredientsIcon,
        exact: true,
        permissions: [PermissionsEnum.LIST_INGREDIENT],
      },
      {
        type: 'menu',
        name: 'Refeições do Staff',
        to: '/staff-meals',
        external_icon: true,
        size: 20,
        icon: WaiterIcon,
        exact: true,
        permissions: [PermissionsEnum.STAFF_MEALS],
      },
      {
        type: 'menu',
        name: 'Fornecedores',
        to: '/suppliers',
        external_icon: true,
        size: 20,
        icon: SuppliersIcon,
        exact: true,
        permissions: [PermissionsEnum.LIST_SUPPLIER],
      },
      {
        type: 'menu',
        name: 'Cores & Flores',
        to: '/flowers',
        external_icon: true,
        size: 20,
        icon: FlowerIcon,
        exact: true,
        permissions: [PermissionsEnum.LIST_COLOR_FLOWER],
      },
      {
        type: 'menu',
        name: 'Encomendas',
        to: '/orders',
        external_icon: true,
        size: 20,
        icon: ReportsIcon,
        exact: true,
        permissions: [PermissionsEnum.LIST_ORDER],
      }
    ]
  },
  {
    type: 'submenu',
    external_icon: true,
    size: 20,
    icon: ContentIcon,
    name: 'Conteúdos',
    to: [
      {
        type: 'menu',
        to: '/sections',
        external_icon: true,
        size: 20,
        icon: SectionsIcon,
        name: 'Secções',
        exact: false,
        permissions: [PermissionsEnum.LIST_SECTION],
      },
      {
        type: 'menu',
        to: '/food',
        external_icon: true,
        size: 20,
        icon: CateringIcon,
        name: 'Catering',
        exact: false,
        permissions: [PermissionsEnum.LIST_MNG_FOOD],
      },
      {
        type: 'menu',
        to: '/inspirations',
        external_icon: true,
        size: 20,
        icon: InspirationsIcon,
        name: 'Inspirações',
        exact: false,
        permissions: [PermissionsEnum.LIST_INSPIRATION],
      },
      {
        type: 'menu',
        to: '/information',
        external_icon: true,
        size: 20,
        icon: InformationIcon,
        name: 'Informações',
        exact: false,
        permissions: [PermissionsEnum.LIST_INFORMATION],
      },
      {
        type: 'menu',
        to: '/news',
        external_icon: true,
        size: 20,
        icon: NewsIcon,
        name: 'Notícias',
        exact: false,
        permissions: [PermissionsEnum.LIST_NEW],
      },
      {
        type: 'menu',
        to: '/colours',
        external_icon: true,
        size: 20,
        icon: ColoursIcon,
        name: 'Palete de cores',
        exact: false,
        permissions: [PermissionsEnum.LIST_COLOR],
      }
    ]
  },
  {
    type: 'submenu',
    external_icon: true,
    size: 20,
    icon: SystemIcon,
    name: 'Sistema',
    to: [
      {
        type: 'menu',
        to: '/admins',
        external_icon: true,
        size: 20,
        icon: AdminsIcon,
        name: 'Administradores',
        exact: false,
        permissions: [PermissionsEnum.LIST_ADMIN],
      },
      {
        type: 'menu',
        to: '/dynamic-pages',
        external_icon: true,
        size: 20,
        icon: PagesIcon,
        name: 'Páginas dinamicas',
        exact: false,
        permissions: [PermissionsEnum.LIST_DYNAMIC_PAGE],
      },
      {
        type: 'menu',
        to: '/translations',
        external_icon: true,
        size: 20,
        icon: TranslationIcon,
        name: 'Traduções',
        exact: false,
        permissions: [PermissionsEnum.LIST_TRANSLATION],
      }, {
        type: 'menu',
        to: '/settings',
        external_icon: true,
        size: 20,
        icon: SystemIcon,
        name: 'Configurações',
        exact: false,
        permissions: [PermissionsEnum.LIST_SETTING],
      }
    ]
  },
  {
    type: 'submenu',
    external_icon: true,
    size: 20,
    icon: EmployeesIcon,
    name: 'Funcionários',
    to: [
      {
        type: 'menu',
        to: '/employees',
        external_icon: true,
        size: 20,
        icon: EmployeesIcon,
        name: 'Funcionários',
        exact: false,
        permissions: [PermissionsEnum.LIST_EMPLOYEE],
      },
      {
        type: 'menu',
        to: '/payments',
        external_icon: true,
        size: 20,
        icon: PaymentIcon,
        name: 'Pagamentos',
        exact: false,
        permissions: [PermissionsEnum.LIST_PAYMENT],
      },
      {
        type: 'menu',
        to: '/receipts',
        external_icon: true,
        size: 20,
        icon: ReceiptIcon,
        name: 'Recibos',
        exact: false,
        permissions: [PermissionsEnum.LIST_RECEIPT],
      }
    ]
  }
];
