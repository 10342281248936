import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetAdmin,
  UpdateAdmin,
  CreateAdmin
} from '../../infra/requests/AdminsRequests';
import TextInput from '../../components/inputs/TextInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { visitedLocals } from '../../weddingday/schedules/SchedulesFilters';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import SelectInput from '../../components/inputs/SelectInput';
import { locals } from '../../weddingday/reserves/ReserveAgenda';
import { hasPermissions } from '../../infra/helpers/Helpers';
import { PermissionsEnum, permissionList } from '../../infra/helpers/Permissions';
import TreeInput from '../../components/inputs/TreeInput';

const validations = (values) => {
  let errors = {};

  const validate = {
    name: 'required',
    email: 'required',
    notificationEmail: 'required'
  };

  if (values?.organizer == true || values?.organizer == 'true') {
    validate['weddingPlace'] = 'required';
    validate['attendingType'] = 'required';
    validate['attendingLanguages'] = 'required';
  }

  errors = FormValidator.make(validate)(values);

  if (values?.organizer == true || values?.organizer == 'true') {
    errors['attendingType'] = !values?.attendingType || values?.attendingType?.length === 0;
    errors['attendingLanguages'] = !values?.attendingLanguages || values?.attendingLanguages?.length === 0;
  }

  return errors;
};

class ManageAdminsPage extends Component {
  state = {
    isNew: false,
    loading: true,

    loadingAttendingTypes: false,
    attendingTypes: [],
    permissions: [],
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    this.getAttendingTypes();

    if (hasPermissions([PermissionsEnum.MNG_PERMISSIONS])) {
      this.setState({
        permissions: permissionList
      });
    }

    if (params.id) {
      const result = await GetAdmin(params.id);
      dispatch(initialize('manage_admin_form', result.data));
      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false
      });
  };

  getAttendingTypes = async () => {
    this.setState({ loadingAttendingTypes: true });
    const result = await GetAttendingTypes();
    let data = result?.success && result?.data ? result.data : [];
    if (Array.isArray(data) && data.length > 0) {
      data = data.map(m => ({ ...m, label: m?.name?.pt }))
    }

    this.setState({
      loadingAttendingTypes: false,
      attendingTypes: data,
    });
  }

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { isNew } = this.state;

      if ((values?.organizer == true || values?.organizer == 'true')
        && (values?.attendingOrganizer == null || values?.attendingOrganizer == undefined)) {
        values.attendingOrganizer = false;
      }

      const { success } = isNew
        ? await CreateAdmin(values)
        : await UpdateAdmin(params.id, values);
      if (success) return history.push('/admins');
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/admins');
  };

  render() {
    const { handleSubmit, adminForm } = this.props;
    const { isNew, loading } = this.state;
    const { attendingTypes, loadingAttendingTypes } = this.state;
    const { permissions } = this.state;
    const title = isNew ? 'Adicionar' : 'Editar';

    if (loading || loadingAttendingTypes) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title} Administrador</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label="Nome *"
              placeholder="Primeiro e último nome"
            />
            <Field
              component={TextInput}
              name="email"
              type="email"
              label="E-mail *"
              placeholder="E-mail"
            />
            <Field
              component={TextInput}
              name="notificationEmail"
              type="email"
              label="E-mail para notificações *"
              placeholder="E-mail"
            />
            {hasPermissions([PermissionsEnum.MNG_PERMISSIONS]) && <Field
              component={TreeInput}
              name="permissions"
              data={permissions}
              label="Permissões (seleção múltipla)"
            />}
            <Field
              component={CheckboxInput}
              name="organizer"
              label="Organizador de Casamentos"
              positiveLabel="Sim"
            />
            {adminForm?.organizer && <Field
              component={SelectInput}
              name={'weddingPlace'}
              label={'Local de reuniões *'}
              data={locals}
            />}
            {adminForm?.organizer && <Field
              component={SelectInput}
              mode="multiple"
              allowClear
              name="attendingLanguages"
              data={[
                { name: 'Português', _id: 'portuguese' },
                { name: 'Espanhol', _id: 'spanish' },
                { name: 'Inglês', _id: 'english' },
                { name: 'Francês', _id: 'french' },
                { name: 'Alemão', _id: 'deutch' }
              ]}
              label="Idioma (seleção múltipla) *"
              placeholder="Selecione os idiomas"
            />}
            {adminForm?.organizer && <Field
              component={SelectInput}
              mode="multiple"
              name={'attendingType'}
              label={'Tipo de atendimento *'}
              placeholder={'Selecione o tipo de atendimento'}
              data={attendingTypes}
              dataLabel='label'
            />}
            {adminForm?.organizer && <Field
              component={CheckboxInput}
              name="attendingOrganizer"
              label="Realizará atendimentos?"
              positiveLabel="Sim"
            />}
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageAdminsPage = reduxForm({
  form: 'manage_admin_form',
  validate: validations
})(ManageAdminsPage);

const selector = formValueSelector('manage_admin_form');

const mapStateToProps = state => ({
  user: state.user,
  adminForm: {
    name: selector(state, 'name'),
    email: selector(state, 'email'),
    organizer: selector(state, 'organizer'),
    permissions: selector(state, 'permissions')
  }
});

export default withLocalize(connect(mapStateToProps)(ManageAdminsPage));
