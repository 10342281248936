import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  margin: 0;
  
  &&& .ant-pagination {
    float: left;
  }
  
  .ant-table {
    overflow: auto;
  }

  table {
    background-color: #fff;
    tr {
      th {
        color: ${p => p.secondary ? '#565656' : p.theme.textColor};
        background-color: ${p => p.secondary ? p.theme.tableSecondaryBg : p.theme.secondaryColor} !important;
        padding: ${p => p.secondary ? p.theme.tableSecondaryPadding : '5px'} !important;
        line-height: ${p => p.secondary ? p.theme.tableSecondaryLineHeight : 'inherit'};
      }
    }
    
    td {
      max-width: 400px;
      vertical-align: ${({ alignContentTop }) => alignContentTop ? 'top' : 'inherit'};
      padding: ${p => p?.secondary ? p.theme.tableSecondaryPadding : p?.noPadding ? '0px' : '5px'} !important;
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr, .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
    border-bottom: 1px solid ${p => p.theme.tableBorder} !important;
  }

  .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid ${p => p.theme.tableBorder} !important;
}

  .ant-table-body {
    min-width: auto !important;
    width: auto;
  }

  .ant-table-tbody > tr {
    cursor: ${p => (p.onPressRow ? 'default' : 'pointer')};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${p => p.theme.tableBorder} !important;
    position: relative;
  }

  .ant-table-tbody > tr > td.align-right {
    text-align: right;
  }

  .ant-table-tbody > tr > td.align-center {
    text-align: center;
  }

  .ant-table-expand-icon-th, .ant-table-row-expand-icon-cell {
    width: 30px !important;
    min-width: 30px !important;
    text-align: center !important;
    padding: 5px !important;
  }

  ${({ fixedHeader, secondary }) => fixedHeader && !secondary
    ? `.ant-table-thead {
        position: sticky;
        top: 150px;
        z-index: 99;
      }`
    : `.ant-table-thead {
      position: relative !important;
      top: unset;
      z-index: unset;
    }`}

  .ant-table-thead > tr > th.header-align-center {
    text-align: center;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px;
  }

  .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: 0px;
    display: inline-block;
    width: 100%;
  }
`;
