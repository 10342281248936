import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { Modal, Button, Row, Col } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import TitleInput from '../../components/inputs/TitleInput';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import SelectInput from '../../components/inputs/SelectInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import ImageInput from '../../components/inputs/ImageInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import { GetTableTypeList } from '../../infra/requests/TableTypeRequests';
import { GetFoodRestrictionList } from '../../infra/requests/FoodRestrictionsRequests';

const CostType = [
  { _id: 'GLOBAL', name: 'Global' },
  { _id: 'TABLE', name: 'Por mesa' },
  { _id: 'GUEST', name: 'Por PAX' },
  { _id: 'QUANTITY', name: 'Por quantidade escolhida' }
];

const Locals = [
  { _id: 1, name: 'Solar da Levada' },
  { _id: 2, name: 'Quinta Lago dos Cisnes' }
];

const validations = values => {
  const validations = {
    title: 'required|languages',
    type: 'required'
  };

  if (values?.cost) {
    validations['cost_price'] = 'required';
    validations['cost_type'] = 'required';
  }

  if (values?.tableForRoomPlan == true) {
    validations['tableType'] = 'required';
  }

  return FormValidator.make(validations)(values);
};

let ManageOptionModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  questionType,
  question,
  hasCost,
  isPremium,
  local,
  tableType,
  supplierCategories,
  change,
}) => {
  const [tableTypes, setTableTypes] = useState([]);
  const [foodRestrictions, setFoodRestrictions] = useState([]);

  useEffect(() => {
    if (question?.tableForRoomPlan) {
      change('tableForRoomPlan', true);
      getTableTypes();
    } else if (question?.isFoodRestriction) {
      getFoodRestrictions();
    }
  }, [question, open])

  useEffect(() => {
    if (question?.tableForRoomPlan) {
      getTableTypes();
    }
  }, [local]);

  const getTableTypes = async () => {
    const result = await GetTableTypeList({});
    let data = result?.success ? (result?.data || []) : [];
    if (data?.length > 0) data = data.map(m => ({ ...m, name: `${m?.designation?.pt} (${m?.weddingPlace == 3 ? 'Ambos' : m?.weddingPlace == 2 ? 'QLC' : 'SLE'})` }));

    if (question?.isCoupleTable) data = data.filter(f => f?.coupleTable);
    else data = data.filter(f => !f?.coupleTable);

    if (local && local !== '') {
      data = data.filter(f => (f?.weddingPlace == local || f?.weddingPlace == 3));
      if (tableType && !data.find(f => f?._id == tableType)) change('tableType', null);
    }

    setTableTypes(data);
  }

  const getFoodRestrictions = async () => {
    const result = await GetFoodRestrictionList();
    let data = result?.success ? (result?.data || []) : [];
    data = data.map(m => ({...m, label: `${m?.acronym?.pt || ''} - ${m?.subtitle?.pt || ''}`}));
    setFoodRestrictions(data);
  }

  return (
    <Modal
      visible={open}
      title={edit ? 'Editar opção' : 'Adicionar opção'}
      maskClosable={false}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Field
                component={ImageInput}
                name="image"
                label={'Imagem'}
                width="100%"
                ratio={0.6}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Field
                component={TitleInput}
                name={'title'}
                type="text"
                label={'Titulo'}
                placeholder={'Titulo da opção'}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Field
                component={LanguagesInput}
                name={'description'}
                type="text"
                label={'Descrição'}
                placeholder={'Descrição da opção'}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} style={{ padding: 0 }}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'cost'}
                  type="text"
                  label={'Tem custo acrescido?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              {hasCost && (
                <Fragment>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Field
                      component={SelectInput}
                      name={'cost_type'}
                      label={'Tipo de custo'}
                      placeholder={'Escolha o tipo de custo'}
                      type="select"
                      data={CostType}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Field
                      component={CurrencyInput}
                      name={'cost_price'}
                      label={'Preço'}
                      placeholder={'Insira o preço para o tipo de custo'}
                    />
                  </Col>
                </Fragment>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} style={{ padding: 0 }}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'quantity'}
                  type="text"
                  label={'Sujeito a disponibilidade?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'onlyOneAvailable'}
                  type="text"
                  label={'Limitado por local e data?'}
                  positiveLabel={'Sim, limitado a 1 local e data'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'isPremiumOption'}
                  type="text"
                  label={'Opção do Serviço Premium?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'isBreakfast'}
                  type="text"
                  label={'Opção de pequeno-almoço?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'hasHost'}
                  label={'Requisitar hospedeira?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'isSeatNumber'}
                  label={'Identificador de mesa?'}
                  positiveLabel={'Sim'}
                />
              </Col>
              {question?.isCoupleTable && <Col xs={24} sm={24} md={8} lg={8}>
                <Field
                  component={CheckboxInput}
                  name={'blockForCouple'}
                  label={'Bloquear para os noivos?'}
                  positiveLabel={'Sim'}
                />
              </Col>}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Field
                component={SelectInput}
                name={'local'}
                label={'Local'}
                allowClear
                placeholder={'Restrito apenas a um local'}
                type="select"
                data={Locals}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Field
                component={SelectInput}
                name={'supplier_category'}
                label={'Categoria de Encomenda'}
                placeholder={'Escolha uma categoria'}
                type="select"
                data={supplierCategories}
                allowClear
                mode='multiple'
              />
            </Col>
            {question?.tableForRoomPlan && <Col xs={24} sm={24} md={12} lg={12}>
              <Field
                component={SelectInput}
                name={'tableType'}
                label={'Tipo de mesa'}
                allowClear
                placeholder={'Selecione o tipo'}
                type="select"
                data={tableTypes}
              />
            </Col>}
            {question?.isFoodRestriction && <Col xs={24} sm={24} md={24} lg={24}>
              <Field
                component={SelectInput}
                name={'foodRestrictions'}
                mode='multiple'
                label={'Restrições alimentares'}
                allowClear
                placeholder={'Selecione as restrições'}
                type="select"
                data={foodRestrictions}
                dataLabel='label'
              />
            </Col>}
          </Row>
        </BaseForm>
      )}
    </Modal>
  );
};

ManageOptionModal = reduxForm({
  form: 'manage_option_form',
  validate: validations
})(ManageOptionModal);

const selector = formValueSelector('manage_option_form');

const mapStateToProps = state => ({
  local: selector(state, 'local'),
  hasCost: selector(state, 'cost'),
  isPremium: selector(state, 'isPremiumOption'),
  tableType: selector(state, 'tableType'),
});

export default connect(mapStateToProps)(ManageOptionModal);
