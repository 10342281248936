import InterestDatesPage from "../../weddingday/interestDates/InterestDatesPage";
import AppointmentCalendar from "../../weddingday/schedules/scheduleService/ScheduleServiceAgenda";
import ReserveLogsPage from "../../weddingday/reserves/logs/ReserveLogsPage";
import ReserveAgenda from "../../weddingday/reserves/ReserveAgenda";
import ReservePrice from "../../weddingday/reserves/ReservePrice";
import ReservesPage from "../../weddingday/reserves/ReservesPage";
import ReservesAttending from "../../weddingday/reservesAttending/ReservesAttending";
import SchedulesPage from "../../weddingday/schedules/SchedulesPage";
import WaitingDatesPage from "../../weddingday/waitingDates/WaitingDatesPage";
import { PermissionsEnum } from "../../infra/helpers/Permissions";

export default [
  {
    path: '/reserves-agenda',
    component: ReserveAgenda,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.RESERVES_AGENDA],
  },
  {
    path: '/waiting-agenda',
    component: ReserveAgenda,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.WAITING_LIST_AGENDA],
  },
  {
    path: '/interest-agenda',
    component: ReserveAgenda,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.INTEREST_AGENDA],
  },
  {
    path: '/agenda',
    component: ReservesAttending,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    onlyEmployeeAttending: true,
    permissions: [PermissionsEnum.RESERVES_AGENDA],
  },
  {
    path: '/waiting',
    component: ReservesAttending,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    onlyEmployeeAttending: true,
    permissions: [PermissionsEnum.WAITING_LIST_AGENDA],
  },
  {
    path: '/interest',
    component: ReservesAttending,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    onlyEmployeeAttending: true,
    permissions: [PermissionsEnum.INTEREST_AGENDA],
  },
  {
    path: '/reserves-prices',
    component: ReserveAgenda,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.RESERVE_PRICES_AGENDA],
  },
  {
    path: '/reserves',
    component: ReservesPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    permissions: [PermissionsEnum.LIST_RESERVE],
  },
  {
    path: '/waiting-dates',
    component: WaitingDatesPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    permissions: [PermissionsEnum.LIST_WAITING_DATES],
  },
  {
    path: '/interest-dates',
    component: InterestDatesPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    permissions: [PermissionsEnum.LIST_INTEREST_DATES],
  },
  {
    path: '/reserves-logs',
    component: ReserveLogsPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.RESERVES_LOGS],
  },
  {
    path: '/schedules',
    component: SchedulesPage,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: true,
    permissions: [PermissionsEnum.LIST_SCHEDULE],
  },
  {
    path: '/schedule-agenda',
    component: AppointmentCalendar,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.ORGANIZER_SCHEDULE_SERVICES],
  },
  {
    path: '/schedule-agenda/:id',
    component: AppointmentCalendar,
    isAuthenticated: true,
    employeeAllowed: false,
    chiefAllowed: false,
    humanResoursesAllowed: false,
    employeeAttendingAllowed: false,
    permissions: [PermissionsEnum.ORGANIZER_SCHEDULE_SERVICES],
  },
];
