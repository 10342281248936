import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import { GetAttendingEmployees } from '../../infra/requests/UsersRequests';
import { VisitBlockedTypes, WeddingStatus } from '../reserves/ReserveAgenda';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({})(values);

    return errors;
};

let ScheduleNotesModal = ({
    isOpen,
    saving,
    handleSubmit,
    initialValues,
    onlyNotes = false,
    onSubmit,
    onClose,
    scheduleNotesForm
}) => {
    useEffect(() => {
    }, [isOpen]);

    return (
        <Modal
            visible={isOpen}
            title='Notas do atendimento'
            maskClosable
            onCancel={onClose}
            footer={[
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>,
                <Button
                    key='submit'
                    type='primary'
                    loading={saving}
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            ]}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Field
                    component={DraftInput}
                    label={onlyNotes ? 'Notas' : 'Notas de reserva'}
                    name={`notes`}
                    placeholder={'Introduza aqui as notas'}
                    minRows={10}
                    toolbarOnFocus={false}
                    toolbar='notes'
                />
                {!onlyNotes && <React.Fragment>
                    <Field
                        component={DraftInput}
                        label='Visitas'
                        name={`visits`}
                        placeholder={'Introduza aqui as informações das visitas'}
                        minRows={10}
                        toolbarOnFocus={false}
                        toolbar='notes'
                    />
                    {initialValues?.wedding?.status == WeddingStatus.CONFIRMED
                        && initialValues?.wedding?.visitsBlocked == VisitBlockedTypes.COMPLETED
                        && <Field
                            component={DraftInput}
                            label='Reuniões com o organizador'
                            name={`meetings`}
                            placeholder={'Introduza aqui os horários e locais das reuniões'}
                            minRows={10}
                            toolbarOnFocus={false}
                            toolbar='notes'
                        />
                    }
                </React.Fragment>}
            </BaseForm>
        </Modal>
    );
};

ScheduleNotesModal = reduxForm({
    form: 'schedule_notes_form',
    enableReinitialize: true,
    validate: validations,
})(ScheduleNotesModal);

const selector = formValueSelector('schedule_notes_form');

const mapStateToProps = state => ({
    scheduleNotesForm: {
        notes: selector(state, 'notes'),
    }
});

ScheduleNotesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ScheduleNotesModal)