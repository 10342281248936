import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, message, Input, Popconfirm, Tooltip, Select, Spin } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm, SpinLoading } from '../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DraftInput from '../../components/inputs/DraftInput';
import SelectInput from '../../components/inputs/SelectInput';
import { dateFormat, dateTimeFormat, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { CreateCoupleReserve, GetAttendingEmployees, GetAttendingList, GetAvailableAttendingList, GetOrganizerList, GetWeddingGuests } from '../../infra/requests/UsersRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectCompactInput from '../../components/inputs/SelectCompactInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GenerateAccessCode, GetConfirmedWeddings, GetWedding, GetWeddingsSelect, MngGuestAccess, NotifyWeddingGuest, UpdateCreateWeddingGuest } from '../../infra/requests/WeddingRequests';
import Alerts from '../../components/alert/Alert';
import { CodeInput, CodeP } from '../../process/ChoicesPage/SectionStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { bindActionCreators } from 'redux';
import { getWeddingFullName, getWeddingLabelSelect, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import styledComponents from 'styled-components';
import { CheckSchedule, GetSchedule, ValidateScheduleType } from '../../infra/requests/SchedulesRequests';
import { GetAttendingTypes } from '../../infra/requests/AttendingTypeRequests';
import { visitedLocals } from './SchedulesFilters';
import { TimeInput } from '../../components/inputs/InputStyles';
import XpertGoTimeInput from '../../components/inputs/XpertGoTimeInput';
import DateInput from '../../components/inputs/DateInput';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import debounce from 'lodash.debounce';
import ReserveInfoModal from '../reservesAttending/ReserveInfoModal';
import XpertGoDateTimeInput from '../../components/inputs/XpertGoDateTimeInput';
const { confirm } = Modal;

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        date: 'required',
        startTime: 'required',
        endTime: 'required',
        name: 'required',
        attendingType: 'required',
    })(values);

    return errors;
};

class ScheduleInternalModal extends React.Component {
    state = {
        loading: false,
        schedule: null,
        loadingAttendingTypes: false,
        attendingTypes: [],
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { initialValues } = this.props;

        if (initialValues?._id) this.getSchedule();
        this.getAttendingTypes();

        dispatch(
            initialize('schedule_form', {
                date: initialValues?.date || null,
                startTime: null,
                endTime: null,
                name: '',
                attendingType: null,
                organizersMeeting: false,
            })
        );
    }

    getSchedule = async () => {
        const { dispatch, initialValues } = this.props;

        this.setState({ loading: true });
        const result = await GetSchedule(initialValues?._id);
        const schedule = (result?.success && result?.data) || null;

        dispatch(
            initialize('schedule_form', {
                _id: schedule?._id || null,
                date: schedule?.date || null,
                startTime: schedule?.startDate || null,
                endTime: schedule?.endDate || null,
                name: schedule?.name || null,
                attendingType: schedule?.attendingType?._id || schedule?.attendingType || null,
                organizersMeeting: schedule?.organizersMeeting || false
            })
        );

        this.setState({
            schedule,
            loading: false,
            date: schedule?.date || null,
        });
    }

    getAttendingTypes = async () => {
        this.setState({ loadingAttendingTypes: true });
        const result = await GetAttendingTypes();
        let data = result?.success && result?.data ? result.data : [];
        if (Array.isArray(data) && data.length > 0) {
            data = data.map(m => ({ ...m, label: m?.name?.pt }))
        }

        this.setState({
            loadingAttendingTypes: false,
            attendingTypes: data,
        });
    }

    clearFields = () => {
        const { dispatch, onClose } = this.props;
        dispatch(initialize('schedule_form', {}, false));
        onClose();
    }

    // Disable dates
    disabledDate = (current) => {
        return current && current.isBefore(moment.utc().startOf('day'), 'day');
    }

    render() {
        const { isOpen } = this.props;
        const { handleSubmit, onClose, onSubmit, invalid, change, submitFailed, saving, confirming, scheduleForm } = this.props;
        const { loading, loadingAttendingTypes } = this.state;
        const { attendingTypes } = this.state;
        const { schedule, date } = this.state;

        const ready = loading || loadingAttendingTypes;

        return (
            <React.Fragment>
                <Modal
                    visible={isOpen}
                    title='Reunião Interna'
                    maskClosable
                    onCancel={onClose}
                    footer={[
                        <Button
                            key='cancel'
                            type='default'
                            onClick={onClose}>
                            Fechar
                        </Button>,
                        <Button
                            key='submit'
                            type='primary'
                            loading={saving}
                            disabled={ready}
                            onClick={handleSubmit(onSubmit)}>
                            Gravar
                        </Button>
                    ]} >

                    {
                        ready
                            ? <SpinLoading />
                            : <BaseForm onSubmit={handleSubmit(onSubmit)}>
                                <Field
                                    component={DateInput}
                                    name="date"
                                    label="Data *"
                                    placeholder="Selecione a data"
                                    disabledDate={this.disabledDate}
                                    onChange={(e) => {
                                        const date = e && moment.utc(e).isValid() ? e : null;
                                        this.setState({ date });

                                        const startTime = scheduleForm?.startTime && moment.utc(scheduleForm.startTime).isValid() && date
                                            ? moment.utc(scheduleForm.startTime).set('year', date.year()).set('month', date.month()).set('date', date.date())
                                            : null;

                                        const endTime = scheduleForm?.endTime && moment.utc(scheduleForm.endTime).isValid() && date
                                            ? moment.utc(scheduleForm.endTime).set('year', date.year()).set('month', date.month()).set('date', date.date())
                                            : null;

                                        change('startTime', startTime);
                                        change('endTime', endTime);
                                    }}
                                />
                                <Field
                                    component={XpertGoDateTimeInput}
                                    name={'startTime'}
                                    label={'Indique horário de ínicio'}
                                    allowClear={true}
                                    dateRef={date}
                                />
                                <Field
                                    component={XpertGoDateTimeInput}
                                    name={'endTime'}
                                    label={'Indique horário de fim'}
                                    allowClear={true}
                                    dateRef={date}
                                />
                                <Field
                                    component={TextInput}
                                    name={'name'}
                                    label={'Nome da reunião'}
                                />
                                <Field
                                    component={SelectInput}
                                    name={'attendingType'}
                                    label={'Selecione o tipo de reunião'}
                                    data={attendingTypes}
                                    dataLabel='label'
                                />
                                <Field
                                    component={CheckboxInput}
                                    name={'organizersMeeting'}
                                    label={'Reunião com os organizadores?'}
                                    positiveLabel={'Sim'}
                                />
                            </BaseForm >}
                </Modal >
            </React.Fragment>
        );
    }
};

ScheduleInternalModal = reduxForm({
    form: 'schedule_form',
    // enableReinitialize: true,
    validate: validations,
})(ScheduleInternalModal);

const selector = formValueSelector('schedule_form');

const mapStateToProps = state => ({
    scheduleForm: {
        date: selector(state, 'date'),
        startDate: selector(state, 'startDate'),
        endDate: selector(state, 'endDate'),
        wedding: selector(state, 'wedding'),
        type: selector(state, 'type'),
        language: selector(state, 'language'),
        attendingType: selector(state, 'attendingType'),
        weddingPlace: selector(state, 'weddingPlace'),
        attendedUsers: selector(state, 'attendedUsers'),
        attendedBy: selector(state, 'attendedBy'),
        startTime: selector(state, 'startTime'),
        endTime: selector(state, 'endTime'),
    }
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ change }, dispatch);
}

ScheduleInternalModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleInternalModal)