import React, { useState, useEffect, useRef } from 'react';
import { PageContainer, PageTitle, HeaderContainer, HeaderButtonsContainer, HeaderTitle, SpinLoadingCalendar } from '../../../styles/BasicStyles';
import { Row, Col, Icon, DatePicker, Dropdown, Menu, Button, Modal, Select } from 'antd';
import { GetAllColorSystem } from '../../../infra/requests/ColorSystemRequests';
import { GetAdminCalendar, GetAllWeddings } from '../../../infra/requests/WeddingRequests';
import { CreateUnavailable, GetAllCompanyRest, GetCalendarCompanyRest, GetCalendarEmployeeRest, SaveUpdateAvailable, UpdateUnavailable } from '../../../infra/requests/UnavailableRequests';
import { GetAllPaymentDays, GetCalendarPaymentDays } from '../../../infra/requests/PaymentDaysRequests';
import BaseButton from '../../../components/buttons/BaseButton';
import { withRouter } from 'react-router-dom';
import moment, { now } from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployee, isCurrentUserEmployeeAttending, isCurrentUserHumanResources, isCurrentUserOrganizer, timeFormat } from '../../../infra/helpers/Helpers';
import { CreateScheduleService, CreateRecursiveScheduleService, DeleteScheduleService, GetScheduleService, GetCalendarScheduleServices, UpdateScheduleService, UpdateRecursiveScheduleService } from '../../../infra/requests/ScheduleServiceRequests';
import Alerts from '../../../components/alert/Alert';
import AppointmentModal, { ScheduleServiceType } from './ScheduleServiceModal';
import styledComponents from 'styled-components';
import { GetEmployeeByUser } from '../../../infra/requests/EmployeeRequests';
import AppointmentActionModal from './ServiceActionModal';
import { GetTextColor } from '../../../infra/services/text/textUtils';
import ScheduleServiceModal from './ScheduleServiceModal';
import ServiceActionModal from './ServiceActionModal';
import { checkDSTChanged, isDST, getDSTOffset } from './utilScheduleService';
import { GetOrganizer } from '../../../infra/requests/AdminsRequests';


const { confirm } = Modal;

export const ColorItem = styledComponents.div`
    display: inline-flex;
    userSelect: none;
    padding: 0 15px;
`;

export const ColorBox = styledComponents.div`
    width: 25px;
    height: 25px;
    margin: 5px;
    background-color: ${({ color }) => color};
    border-radius: 3px; 
`;

export const ColorLabel = styledComponents.div`
    margin: auto 0px; 
    fontWeight: bold;
`;

// main component
let ScheduleServiceAgenda = (props): React$Element<React$FragmentType> => {

    const [loading, setLoading] = useState(false);
    const [defaultDate, setDefaultDate] = useState(null);
    const [colorSystem, setColorSystem] = useState([]);
    const [events, setEvents] = useState([]);
    const [searchStartDate, setSearchStartDate] = useState(moment.utc().startOf('month'));
    const [searchEndDate, setSearchEndDate] = useState(moment.utc().endOf('month'));
    const [searchSpecificDate, setSearchSpecificDate] = useState(false);

    const [organizer, setOrganizer] = useState(null);

    const [showServiceModal, toggleServiceModal] = useState(false);
    const [dataServiceModal, setDataServiceModal] = useState(null);
    const [savingService, toggleSavingService] = useState(false);

    const [showActionModal, toggleActionModal] = useState(false);
    const [dataActionModal, setDataActionModal] = useState(null);

    const [showAttendingModal, toggleAttendingModal] = useState(false);
    const [dataAttendingModal, setDataAttendingModal] = useState(null);

    const calendarComponentRef = useRef();

    const { history, match: { params } } = props;

    useEffect(() => {
        // if (!isCurrentUserOrganizer()) history.push('404');
        getOrganizer();
    }, []);

    useEffect(() => {
        let calendarApi = calendarComponentRef.current.getApi();
        if (defaultDate) {
            calendarApi.changeView('timeGridDay');
            calendarApi.gotoDate(defaultDate.format('YYYY-MM-DD'));
        } else {
            calendarApi.changeView('timeGridWeek');
            calendarApi.gotoDate(moment.utc(new Date()).format('YYYY-MM-DD'));
        }
    }, [defaultDate]);

    const getOrganizer = async () => {
        if (!isCurrentUserOrganizer() && !params?.id) {
            history.push('404');
        }

        let organizer = null;
        if (isCurrentUserOrganizer() && params.id) {
            const resultOrganizer = await GetOrganizer(params.id);

            if (resultOrganizer?.success && resultOrganizer?.data) {
                organizer = resultOrganizer?.data;
            } else {
                history.push('404');
            }
        }

        setOrganizer(organizer)
        getAllColorSystem();
    }

    const getAllColorSystem = async () => {
        setLoading(true);
        const result = await GetAllColorSystem();
        if (result.success && result.data) {
            result.data.sort((a, b) => a.type < b.type ? -1 : 1);
            setColorSystem(result.data)
        } else {
            setColorSystem([]);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getEvents = async () => {
        setLoading(true);

        let filter = {
            startDate: searchStartDate,
            endDate: searchEndDate,
            specificDate: searchSpecificDate
        };
        if (searchSpecificDate) filter['date'] = defaultDate;
        if (params?.id) filter['user'] = params.id;
        const result = await GetCalendarScheduleServices(JSON.stringify(filter));

        // let weddings = result.data.sort((a, b) => (a.wedding_place > b.wedding_place) ? 1 : -1);

        // Get appointments
        let evts = [];
        if (result?.success && result?.data?.length > 0) {
            for (let i = 0; i < result.data.length; i++) {
                let obj = {
                    source: result.data[i],
                    id: result.data[i].key,
                    title: result.data[i].title,
                    start: moment.utc(result.data[i].start).toDate(),
                    end: moment.utc(result.data[i].end).toDate(),
                    backgroundColor: result.data[i].backgroundColor,
                    borderColor: result.data[i].borderColor,
                    textColor: GetTextColor(result.data[i].backgroundColor),
                    // display: isCurrentUserOrganizer() ? 'none' : 'block',
                    type: 1,

                }
                evts.push(obj);
            }
        }

        // Get company rest days
        let filterUnavailable = {
            startDate: searchStartDate,
            endDate: searchEndDate,
            specificDate: searchSpecificDate,
            total: true,
        };
        if (searchSpecificDate) filterUnavailable['date'] = defaultDate;
        const result2 = await GetCalendarCompanyRest(JSON.stringify(filterUnavailable));
        if (result2?.success && result2?.data?.length > 0) {
            for (let i = 0; i < result2.data.length; i++) {
                let obj = {
                    id: result2.data[i]._id,
                    title: result2.data[i].title,
                    allDay: result2.data[i].allDay,
                    start: moment.utc(result2.data[i].start).toDate(),
                    end: moment.utc(result2.data[i].end).toDate(),
                    color: result2.data[i].color,
                    display: 'block',
                    className: result2.data[i].allDay ? 'hideTime' : '',
                    type: 2,
                    weddingPlace: result2?.data[i]?.weddingPlace
                }
                evts.push(obj);
            }
        }

        setEvents(evts);
        setLoading(false);
    }

    useEffect(() => {
        if (colorSystem.length > 0)
            getEvents();
    }, [colorSystem])

    useEffect(() => {
        if (colorSystem.length > 0) getEvents();
    }, [searchStartDate, searchEndDate, searchSpecificDate]);

    const blockUserActions = () => {
        if (!isCurrentUserOrganizer() || (isCurrentUserOrganizer() && params?.id && getUserId() != organizer?._id)) {
            return true;
        } else {
            return false;
        }
    }

    const handleEventClick = async (e) => {
        if (blockUserActions()) return;

        // If unavailable
        if (e.event.backgroundColor === colorSystem.find(x => x.type === 3).color) return;
        else {
            const event = events.find(f => f.id === e.event.id);
            if (!event?.source?.hasSchedule && !event?.source?.internal && !event?.source?.wedding) {
                const actionType = event?.source?.type == ScheduleServiceType.RECURSIVE
                    ? 0
                    : 1;
                setDataActionModal({ ...event, actionType });
                toggleActionModal(true);
                // onEditEvent(event);
            }
        }
    }

    const handleDateClick = (e) => {
        if (isCurrentUserEmployeeAttending()) return;
        if (blockUserActions()) return;

        if (events && Array.isArray(events)) {
            const unavailableByDate = events.filter(f => f?.type && f.type === 2 && moment.utc(e.date).isBetween(moment.utc(f.start), moment.utc(f.end), 'day', '[]'));
            // console.warn('unavailableByDate', unavailableByDate);

            // Check if unavailables for both spaces
            if (unavailableByDate && Array.isArray(unavailableByDate) &&
                (
                    (unavailableByDate.find(f => f?.allDay)) ||
                    (
                        (unavailableByDate.find(f => f?.weddingPlace && f.weddingPlace === 3)
                            || (
                                unavailableByDate.find(f => f?.weddingPlace && f.weddingPlace === 1)
                                && unavailableByDate.find(f => f?.weddingPlace && f.weddingPlace === 2)
                            )
                        )
                    )
                )
            ) {
                return Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Dia Indisponível'
                });
            } else {
                // Open modal for that specific date
                setDataServiceModal({
                    startDate: moment.utc(e.date).startOf('day'),
                    type: ScheduleServiceType.CUSTOM,
                    time: moment(e.date)
                });
                toggleServiceModal(true);
            }
        } else {
            // Open modal for that specific date, has an allDay event
            setDataServiceModal({
                startDate: moment.utc(e.date).startOf('day'),
                type: ScheduleServiceType.CUSTOM,
                time: moment(e.date)
            });
            toggleServiceModal(true);
        }
    }

    const closeActionModal = () => {
        toggleActionModal(false);
        setDataActionModal(null);
    }

    // Create/Edit Event
    const onEditEvent = (event) => {
        closeActionModal();

        if (event?.actionType == 0 && event.source.type === ScheduleServiceType.RECURSIVE) {
            getRecursiveEvent(event.source.type === ScheduleServiceType.RECURSIVE ? event.source._id : event.source.recursive);
        } else openEventModal(event);
    }

    const getRecursiveEvent = async (eventId) => {
        const result = await GetScheduleService(eventId);
        if (result?.success && result?.data) {
            const startDate = result?.data?.relatedId
                ? result?.data?.relatedCompleteInfo?.startDate
                : result?.data?.startDate;
            const endDate = result?.data?.relatedId
                ? result?.data?.relatedCompleteInfo?.endDate
                : result?.data?.endDate;
            const date = moment.utc(result?.data?.startDate);
            const time = moment.utc(result?.data?.time, timeFormat)
                .set('year', date.year())
                .set('month', date.month())
                .set('date', date.date());

            setDataServiceModal({
                ...result.data,
                date: date?.toISOString(),
                startDate,
                endDate,
                time: time
            });
            toggleServiceModal(true);
        }
    }

    const openEventModal = (event) => {
        if (event.source.type === ScheduleServiceType.RECURSIVE) {
            setDataServiceModal({
                _id: null,
                type: ScheduleServiceType.CUSTOM,
                recursive: event.source._id,
                startDate: event.source.startDate,
                endDate: event.source.endDate,
                allWeekdays: false,
                time: moment.utc(event.source.time, 'HH:mm'),
            });
        } else {
            setDataServiceModal({
                ...event.source,
            });
        }
        toggleServiceModal(true);
    }

    // Save schedule service
    const onSubmitEventModal = (values) => {
        if (blockUserActions()) return;

        if (values.type === ScheduleServiceType.RECURSIVE) {
            saveRecursiveEvent(values);
        } else saveEvent(values);
    }

    const saveEvent = async (values) => {
        if (blockUserActions()) return;

        const time = moment.utc(values.time).format('HH:mm');

        toggleSavingService(true);
        const data = {
            _id: values._id,
            type: values.type,
            startDate: moment.utc(values.startDate).startOf('day').toISOString(),
            endDate: moment.utc(values.startDate).endOf('day').toISOString(),
            time: moment.utc(values.time).format('HH:mm'),
        };
        if (values?.recursive) data['recursive'] = values.recursive;

        const result = values?._id
            ? await UpdateScheduleService(values?._id, data)
            : await CreateScheduleService(data);

        if (result?.success && result?.data) {
            if (result?.data?.shortPeriod) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Duração entre horários muito curta!'
                });
            }
            toggleSavingService(false);
            getEvents();
            closeEventModal();
        } else toggleSavingService(false);
    }

    const saveRecursiveEvent = async (values) => {
        if (blockUserActions()) return;

        toggleSavingService(true);

        const data = { ...values };
        const startDate = moment.utc(data.startDate).startOf('day');
        const endDate = moment.utc(data.endDate).startOf('day');
        const diffDays = endDate.diff(startDate, 'days');



        if (diffDays < 1) {
            toggleSavingService(false);
            return Alerts.new({
                type: 'warning',
                title: 'Atenção',
                text: 'Deve selecionar um intervalo de datas!'
            });
        }

        const isDSTDifferent = checkDSTChanged(startDate, endDate, data?.time);
        if (isDSTDifferent) {
            if (isDST(moment.utc(data.time)) && moment.utc(data.time).isValid()) {
                const standardOffset = getDSTOffset() * 60; // <- Este é o número mágico de minutos numa hora, porque todos os dias, uma hora tem 60 minutos
                data.time = moment.utc(data.time).add(standardOffset, 'minutes');
            }
        }

        data.startDate = startDate.toISOString();
        data.endDate = endDate.endOf('day').toISOString();
        if (moment.utc(data.time).isValid()) data.time = moment.utc(data.time).format('HH:mm');

        if (!data.allWeekdays && data.weekday?.length === 7) {
            data.allWeekdays = true;
            data.weekday = [];
        }
        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        data['timezone'] = timezoneName;

        const result = data && data?._id
            ? await UpdateRecursiveScheduleService(data?._id, data)
            : await CreateRecursiveScheduleService(data);

        if (result?.success && result?.data) {
            if (result?.data?.shortPeriod) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Duração entre horários muito curta!'
                });
            }
            getEvents();
            toggleSavingService(false);
            closeEventModal();
        } else toggleSavingService(false);
    }

    const closeEventModal = () => {
        toggleServiceModal(false);
        setDataServiceModal(null);
        toggleAttendingModal(false);
        setDataAttendingModal(null);
    }

    // Delete schedule service
    const showDeleteConfirm = async (values) => {
        if (blockUserActions()) return;

        closeActionModal();
        let data = {
            ...values?.source,
            actionType: values?.actionType
        };

        if (values?.actionType == 0 && values?.source?.type == ScheduleServiceType.RECURSIVE) {
            const result = await GetScheduleService(values?.source?._id);
            if (result?.success && result?.data) {
                const startDate = result?.data?.relatedId
                    ? result?.data?.relatedCompleteInfo?.startDate
                    : result?.data?.startDate;
                const endDate = result?.data?.relatedId
                    ? result?.data?.relatedCompleteInfo?.endDate
                    : result?.data?.endDate;
                const date = moment.utc(result?.data?.startDate);
                const time = moment.utc(result?.data?.time, timeFormat)
                    .set('year', date.year())
                    .set('month', date.month())
                    .set('date', date.date());
                data = {
                    ...result.data,
                    startDate,
                    endDate,
                    time,
                    actionType: values?.actionType
                };
            }
        }

        const content = values?.actionType == 0 && values?.source?.type == ScheduleServiceType.RECURSIVE
            ? <React.Fragment>
                <div><b>Intervalo:</b>{moment.utc(data.startDate).format('DD/MM/YY')} - {moment.utc(data?.endDate).format('DD/MM/YY')}</div>
                <div><b>Horário:</b>{moment.utc(data?.time).local().format(timeFormat)}</div>
            </React.Fragment>
            : <React.Fragment>
                <div><b>Data:</b>{moment.utc(data?.startDate).format('DD/MM/YY')}</div>
                <div><b>Horário:</b>{moment.utc(data?.startDate).local().format(timeFormat)}</div>
            </React.Fragment>

        confirm({
            title: 'Tem a certeza que pretende eliminar este horário?',
            content,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: () => deleteScheduleService(data),
            onCancel: () => closeEventModal(),
        });
    }

    const deleteScheduleService = async (values) => {
        if (blockUserActions()) return;

        const data = {
            deleteCount: values?.actionType == 0 ? values?.actionType : 1,
        };
        if (values?.actionType == 1 && values?.type == ScheduleServiceType.RECURSIVE) {
            data['date'] = values.startDate
        };
        const result = await DeleteScheduleService(values?._id, data);
        // console.warn('Res', result);

        if (result?.success) {
            getEvents();
            closeEventModal();
        }
    }

    const createScheduleService = () => {
        if (blockUserActions()) return;

        setDataServiceModal({
            allDay: true,
            allWeekdays: true,
        });
        toggleServiceModal(true);
    }

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle buttons={organizer ? 2 : 1}>
                    <PageTitle>{organizer ? organizer?.name : 'Horários de Organizadores'}</PageTitle>
                    {organizer && 'Horários de Organizadores'}
                </HeaderTitle>
                <HeaderButtonsContainer buttons={organizer ? 2 : 1}>
                    {organizer &&
                        <BaseButton
                            type="default"
                            icon="arrow-left"
                            text="Voltar"
                            onClick={() => history.push('/admins')}
                        />
                    }
                    {isCurrentUserOrganizer() && !organizer &&
                        <BaseButton
                            type="primary"
                            icon="calendar"
                            text="Criar Horário"
                            onClick={createScheduleService}
                        />
                    }
                </HeaderButtonsContainer>
            </HeaderContainer>
            <PageContainer buttons={organizer ? 2 : 1} id="pageContainerCalendarAdmin">
                <Row style={{ marginBottom: 5 }}>
                    <Col xs={20} sm={10} md={5} lg={4} style={{ textAlign: 'left' }}>
                        <DatePicker
                            className='mobile-full-width'
                            value={defaultDate}
                            format={'DD/MM/YYYY'}
                            placeholder='Selecione Data'
                            disabled={loading}
                            onChange={(e) => {
                                if (e == null) {
                                    setDefaultDate(null)
                                    setSearchSpecificDate(false);
                                } else {
                                    setDefaultDate(e);
                                    setSearchSpecificDate(true);
                                    setSearchStartDate(moment.utc(e));
                                    setSearchEndDate(moment.utc(e));
                                }
                            }} />
                    </Col>
                    {/* <Col xs={4} sm={14} md={19} lg={20} style={{ textAlign: 'right', paddingTop: 5 }}>
                        <Icon type="unordered-list" style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => { history.push('/weddings') }} />
                    </Col> */}
                </Row>
                <Row>
                    <div id="calendar">
                        {loading === true ? <SpinLoadingCalendar /> : null}

                        <FullCalendar
                            initialView="timeGridWeek"
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                            handleWindowResize={true}
                            themeSystem="default"
                            height="calc(100vh - 260px)"
                            allDaySlot={true}
                            nowIndicator={true}
                            slotMinTime={'05:00:00'}
                            slotEventOverlap={false}
                            eventOrder='-title,start,-duration,allDay'
                            locale="pt"
                            buttonText={{
                                today: 'Hoje',
                                month: 'Mês',
                                week: 'Semana',
                                day: 'Dia',
                                list: 'Lista',
                                prev: 'Anterior',
                                next: 'Seguinte'
                            }}
                            headerToolbar={{
                                left: 'prev,today,next',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay' //listMonth
                            }}
                            editable={false}
                            selectable={true}
                            droppable={false}
                            events={events}
                            datesSet={($event) => {
                                setEvents([]);
                                setSearchStartDate(moment.utc($event.start));
                                setSearchEndDate(moment.utc($event.end));
                                // if (searchSpecificDate && defaultDate && moment.utc($event?.start).isSame(defaultDate, 'day')) {
                                //     setSearchSpecificDate(false);
                                //     setDefaultDate(null);
                                // }
                            }}
                            ref={calendarComponentRef}
                            eventClick={handleEventClick}
                            dateClick={handleDateClick}
                        // drop={handleDrop}
                        />
                    </div>
                </Row>
                <div style={{ marginTop: 10 }}>
                    {colorSystem.map((x, index) => {
                        return [9, 10, 18].includes(x.type) ?
                            <ColorItem>
                                <ColorBox color={x.color} />
                                <ColorLabel>{x.description}</ColorLabel>
                            </ColorItem>
                            : null
                    })}
                </div>

                {showServiceModal &&
                    <ScheduleServiceModal
                        isOpen={showServiceModal}
                        loading={savingService}
                        initialValues={dataServiceModal}
                        onSubmit={onSubmitEventModal}
                        onClose={closeEventModal} />
                }

                {showActionModal &&
                    <ServiceActionModal
                        isOpen={showActionModal}
                        initialValues={dataActionModal}
                        onEdit={onEditEvent}
                        onClose={closeActionModal}
                        onDelete={showDeleteConfirm} />
                }

                {/* {showAttendingModal &&
                    <AttendingModal
                        isOpen={showAttendingModal}
                        initialValues={dataAttendingModal}
                        onSubmit={saveEvent}
                        onClose={closettendingModal}
                        onDelete={showDeleteConfirm} />
                } */}
            </PageContainer>
        </React.Fragment>
    );
};

export default withRouter(ScheduleServiceAgenda);