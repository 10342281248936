import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Menu, Dropdown, Input } from 'antd';
import { BtnAddTable, DeleteSeatButton, SeatAge, SeatFoodRestrict, SeatName, SeatRow, StatsContent, StatsContentRow, StatsTotal, TableGuests, TableGuestsHeader, TableGuestsModal, TableGuestsOptions, TableGuestsStats, TableGuestsType, TableName, TableSeatCode } from '../../../process/guests/RoomPlanStyles';
import { convertNumberToLetter } from '../../../process/guests/utilRoomPlan';
import { TableCategory, TablePurpose } from '../../../process/guests/RoomPlanStyles';
import ReactSVG from 'react-svg';
import CoupleIcon from '../../../assets/icons/ic_about.svg';
import StaffIcon from '../../../assets/icons/ic_staff.svg';
import { GuestAgeEnum, TablePurposeEnum } from '../../../components/guestMap/GuestTablesComponent';
import { StatsEnum } from '../../../process/guests/ManageWeddingGuestMapPage';
import { Element } from 'react-scroll';

const ArchivedGuestTablesComponent = ({
  tables,
}) => {

  const checkCoupleTable = (table) => {
    return table?.tablePurpose == TablePurposeEnum.COUPLE;
  }

  const checkStaffTable = (table) => {
    return table?.tablePurpose == TablePurposeEnum.STAFF;
  }

  const checkGuestTable = (table) => {
    return table?.tablePurpose == TablePurposeEnum.GUEST || table?.tablePurpose == TablePurposeEnum.CHILDREN;
  }

  const formatTablePurpose = (table) => {
    let tablePurpose = '';
    if (table?.tablePurpose == TablePurposeEnum.COUPLE) {
      tablePurpose = 'Casal';
    } else if (table?.tablePurpose == TablePurposeEnum.STAFF) {
      tablePurpose = 'Staff';
    } else if (table?.tablePurpose == TablePurposeEnum.CHILDREN) {
      tablePurpose = 'Crianças';
    } else {
      tablePurpose = 'Convidados';
    }

    return tablePurpose;
  }

  const getSeatCode = (seat) => {
    return (seat?.number || 0) + 1;
  }

  const formatGuestAge = (seat) => {
    let age = '';
    if (seat?.guestAge == GuestAgeEnum.NEWBORN) {
      age = '0 a 5 meses';
    } else if (seat?.guestAge == GuestAgeEnum.BABY) {
      age = '6 meses a 2 anos';
    } else if (seat?.guestAge == GuestAgeEnum.CHILD) {
      age = '3 aos 7 anos';
    } else {
      age = '';
    }

    return age;
  }

  const formatFoodRestrictions = (seat) => {
    return seat?.foodRestrictions?.length > 0
      ? seat?.foodRestrictions.map(m => m?.acronym?.pt).join(', ')
      : '';
  }

  const getStats = (type, table) => {
    if (type == StatsEnum.ADULT) {
      return table?.seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.ADULT)?.length || 0;
    } else if (type == StatsEnum.CHILD) {
      return table?.seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.CHILD)?.length || 0;
    } else if (type == StatsEnum.BABY) {
      return table?.seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.BABY)?.length || 0;
    } else if (type == StatsEnum.NEWBORN) {
      return table?.seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.NEWBORN)?.length || 0;
    } else {
      return 0;
    }
  }

  return (
    <Row id="section-tables" gutter={[12, 12]} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>

      {tables.filter(f => !f?.removeBecauseOfOvalL)?.map((table, index) => (
        <Col key={`anchor-${table?.code}`} xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} style={{ minHeight: '100%', display: 'flex' }}>
          <Element
            name={`anchor-${table?.code}`}
            key={`anchor-${table?.code}`}
            id={`anchor-${table?.code}`}
            style={{ width: '100%', height: '100%' }}
          >
            <TableGuests staff={checkStaffTable(table)} couple={checkCoupleTable(table)}>
              {(checkCoupleTable(table) || checkStaffTable(table)) &&
                <TableCategory staff={checkStaffTable(table) ? true : false}>
                  <ReactSVG
                    className='category-icon'
                    src={checkStaffTable(table) ? StaffIcon : CoupleIcon} />
                  <span>{checkStaffTable(table) ? 'Mesa de staff' : 'Mesa do casal'}</span>
                </TableCategory>
              }

              <TableGuestsHeader archived={true}>
                <TableSeatCode seat={false} couple={checkCoupleTable(table)}>{convertNumberToLetter(table?.code || 0) || ''}</TableSeatCode>
                <TableName>{table?.name || ''}</TableName>
              </TableGuestsHeader>

              {!checkCoupleTable(table) && <TablePurpose>
                Mesas para: {formatTablePurpose(table)}
              </TablePurpose>}

              {table?.seats?.map((seat, sindex) => (
                <SeatRow key={sindex} archived={true}>
                  <TableSeatCode archived={true} couple={checkCoupleTable()} seat={true}>{getSeatCode(seat) || 'X'}</TableSeatCode>
                  <SeatName>{seat?.guestName || ''}</SeatName>
                  <SeatAge staff={checkStaffTable()}>{formatGuestAge(seat)}</SeatAge>
                  <SeatFoodRestrict staff={checkStaffTable()}>{formatFoodRestrictions(seat)}</SeatFoodRestrict>
                </SeatRow>
              ))}

              <TableGuestsStats>
                <StatsContent>
                  <StatsContentRow>
                    <div className='stats-description'>Maior de 8 anos</div>
                    <div className='stats-value'>{getStats(StatsEnum.ADULT, table)}</div>
                  </StatsContentRow>
                  <StatsContentRow>
                    <div className='stats-description'>3 aos 7 anos</div>
                    <div className='stats-value'>{getStats(StatsEnum.CHILD, table)}</div>
                  </StatsContentRow>
                  <StatsContentRow>
                    <div className='stats-description'>6 meses a 2 anos</div>
                    <div className='stats-value'>{getStats(StatsEnum.BABY, table)}</div>
                  </StatsContentRow>
                  <StatsContentRow>
                    <div className='stats-description'>0 a 5 meses</div>
                    <div className='stats-value'>{getStats(StatsEnum.NEWBORN, table)}</div>
                  </StatsContentRow>
                </StatsContent>
                <StatsTotal couple={checkCoupleTable()}>
                  <div className='pax'>{getStats(StatsEnum.ADULT, table) + getStats(StatsEnum.CHILD, table)}</div>
                  {getStats(StatsEnum.BABY, table) > 0 &&
                    <div className='stroller'>{getStats(StatsEnum.BABY, table)} Cadeira Bebé</div>}
                  {getStats(StatsEnum.NEWBORN, table) > 0 &&
                    <div className='stroller'>{getStats(StatsEnum.NEWBORN, table)} Carrinho</div>}
                </StatsTotal>
              </TableGuestsStats>
            </TableGuests>
          </Element>
        </Col>
      ))}
    </Row>
  );
};

export default ArchivedGuestTablesComponent;