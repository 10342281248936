import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import {
  BasicInfoSection,
  SectionTitle,
  InfoText,
  UnansweredQuestion,
  QuestionsContainer,
  SectionContainer,
  QuestionCol
} from '../ProcessStyles';
import { findPageByTag } from '../../../infra/services/sections/Sections';
import {
  findUnansweredQuestions,
  findUnansweredGuests,
  findUnansweredStaff,
  findUnansweredAboutUs
} from '../../../infra/services/questions/UnansweredQuestions';
import TranslateValue from '../../../infra/services/translations/TranslateValue';

const UnansweredQuestionsComponent = ({
  guestsInfo,
  structureInfo,
  foodInfo,
  colorsInfo,
  weddingInfo,
  staffInfo,
  history,
  match: { params },
  archived
}) => {
  let section = findPageByTag(structureInfo, 'ABOUT_US');
  const aboutUs = findUnansweredAboutUs(section, weddingInfo);

  section = findPageByTag(structureInfo, 'CEREMONY');
  const ceremony = findUnansweredQuestions(
    section,
    foodInfo,
    colorsInfo,
    weddingInfo.wedding_place
  );

  section = findPageByTag(structureInfo, 'STAFF');
  const staff = findUnansweredStaff(section, weddingInfo.wedding_place);

  section = findPageByTag(structureInfo, 'GUESTS');
  const guests = findUnansweredGuests(section, guestsInfo);

  section = findPageByTag(structureInfo, 'FOOD');
  const food = findUnansweredQuestions(
    section,
    foodInfo,
    colorsInfo,
    weddingInfo.wedding_place
  );

  section = findPageByTag(structureInfo, 'DRINKS');
  const drinks = findUnansweredQuestions(
    section,
    foodInfo,
    colorsInfo,
    weddingInfo.wedding_place
  );

  section = findPageByTag(structureInfo, 'DECORATION');
  const decoration = findUnansweredQuestions(
    section,
    foodInfo,
    colorsInfo,
    weddingInfo.wedding_place
  );

  section = findPageByTag(structureInfo, 'PARTY');
  const party = findUnansweredQuestions(
    section,
    foodInfo,
    colorsInfo,
    weddingInfo.wedding_place
  );

  const handleClick = link => {
    if (!archived) history.push(link);
  };

  return (
    <BasicInfoSection>
      <SectionTitle>Perguntas sem resposta</SectionTitle>
      {aboutUs?.length === 0 &&
        ceremony?.length === 0 &&
        staff?.length === 0 &&
        guests?.length === 0 &&
        food?.length === 0 &&
        drinks?.length === 0 &&
        decoration?.length === 0 &&
        party?.length === 0 && <div>Não existem registos para apresentar!</div>}
      {aboutUs?.length > 0 && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Sobre nós</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {aboutUs.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`aboutUs_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/about#${question._id}`
                  )
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)} *
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
      {ceremony?.length > 0 && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Cerimónia</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {ceremony.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`ceremony_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/ceremony#${question._id}`
                  )
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)}
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
      {(staff?.length > 0 || staffInfo?.length === 0) && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Staff</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {
              staffInfo?.length === 0 &&
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`staff_externo`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/staff#externo`
                  )
                }>
                <UnansweredQuestion>
                  Staff Externo *
                </UnansweredQuestion>
              </QuestionCol>
            }
            {staff?.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`staff_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/staff#${question._id}`
                  )
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)}
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
      {guests?.length > 0 && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Convidados</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {guests.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`guests_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/guests#${question._id}`
                  )
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)}
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
      {food?.length > 0 && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Food Selection</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {food.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`food_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(`/wedding/${params.wedding}/food#${question._id}`)
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)}
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
      {drinks?.length > 0 && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Drink Selection</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {drinks.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`drinks_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/drinks#${question._id}`
                  )
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)}
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
      {decoration?.length > 0 && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Decoração</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {decoration.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`decoration_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/decoration#${question._id}`
                  )
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)}
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
      {party?.length > 0 && (
        <SectionContainer>
          <Row>
            <Col xs={24}>
              <InfoText isTitle>Party Selection</InfoText>
            </Col>
          </Row>
          <QuestionsContainer>
            {party.map((question, index) => (
              <QuestionCol
                archived={archived ? 1 : 0}
                key={`party_${index}`}
                xs={24}
                sm={12}
                md={6}
                onClick={() =>
                  handleClick(
                    `/wedding/${params.wedding}/party#${question._id}`
                  )
                }>
                <UnansweredQuestion>
                  {TranslateValue(question.title)}
                </UnansweredQuestion>
              </QuestionCol>
            ))}
          </QuestionsContainer>
        </SectionContainer>
      )}
    </BasicInfoSection>
  );
};

export default withRouter(UnansweredQuestionsComponent);
