import React, { Component, Fragment } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Icon, InputNumber, Menu, Modal, Popconfirm, Radio, Row, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
    PageContainer,
    PageTitle,
    HeaderContainer,
    HeaderTitle,
    HeaderButtonsContainer,
    TableButton,
    TableFilterSection,
    SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import { connect } from 'react-redux';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { CreateReservePrice, DeleteReservePrice, GetReservePriceByDateAndLocal, GetReservePriceByMonth, UpdateMultipleReservePrice, UpdateReservePrice } from '../../infra/requests/ReservePriceRequests';
import NumberInput from '../../components/inputs/NumberInput';
import debounce from 'lodash.debounce';
import { getUserId, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { InputGroupField, InputNumberField, WaitingIcon } from '../../components/inputs/InputStyles';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import Alert from '../../components/alert/Alert';
import MonthInput from '../../components/inputs/MonthInput';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import styled from 'styled-components';
import YearView from '../../components/YearView/YearView';
import { CheckDatesAvailable, GetReserve, GetReserveAgenda, GetReservesByWedding, GetReservesWithOrder, MngMultipleReserves, UpdateReserveRelatedInfo } from '../../infra/requests/ReserveRequests';
import { CalendarBox, CalendarPicker, LinkAcceptPolicy, RadioPlace, RadioYear } from '../../components/YearView/CalendarStyles';
import ReservePriceModal from './ReservePriceModal';
import AgendaActions from '../../infra/enum/agendaAction';
import MultipleReservePriceModal from './MultipleReservePriceModal';
import { CreateCompanyRest, CreateUnavailable, GetUnavailableByDate, RemoveUnavailable, RemoveUnavailableByAgenda, UpdateMultipleCompanyRest } from '../../infra/requests/UnavailableRequests';
import Alerts from '../../components/alert/Alert';
import YearInput from '../../components/inputs/YearInput';
import { Subtitle } from '../../process/budget/components/DetailBlockStyles';
import { ReserveContent, ReserveItem, ReserveList, ReserveSubtitle, ReserveTitle } from './ReserveStyles';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import PriceView from '../../components/YearView/PriceView';
import ReserveInfoModal from '../reservesAttending/ReserveInfoModal';
import ReserveDateWarningModal from '../reservesAttending/ReserveDateWarningModal';
import { CreateCoupleReserve } from '../../infra/requests/UsersRequests';
import { GetWedding, GetWeddingsNoWaitingDates, UpdateWaitingDatePeriodNotification, UpdateWaitingInfo } from '../../infra/requests/WeddingRequests';
import { CreateMultipleWaitingDate, CreateWaitingDate, GetWaitingDateByWedding, SoftDeleteMultipleWaitingDate, SoftDeleteWaitingDate, SoftDeleteWaitingDateAgenda } from '../../infra/requests/WaitingDatesRequests';
import Bookmark from "../../assets/icons/bookmark.svg";
import Star from "../../assets/icons/star.svg";
import { WaitingActionType } from '../waitingDates/WaitingDatesPage';
import { CreateInterestDate, DeleteInterestDateAgenda, GetInterestDateByWedding } from '../../infra/requests/InterestDatesRequests';
import WaitingInterestPolicyModal from '../waitingDates/WaitingInterestPolicyModal';
import { getLocal, getWeddingName, getWeddingTitle } from '../../infra/services/wedding/weddingUtils';
import { setDataToUpdateForReserveWedding } from './reservesUtils';

export const WeddingStatus = {
    ARCHIVED: 'A',
    CONFIRMED: 'C',
    RESERVE: 'R',
    RESERVE_SINALIZATION: 'RS'
}

export const VisitBlockedTypes = {
    NONE: 'NONE',
    SEMI: 'SEMI',
    COMPLETED: 'COMPLETED',
}

export const ReserveStatus = {
    OCCUPIED: 'O',
    SINALIZATION: 'S',
    RSC: 'RSC',
    INACTIVE: 'I',
    ARCHIVED: 'A'
};

export const AgendaType = {
    AGENDA: 'reserves-agenda', // Reserve Agenda
    WAITING_DATE: 'waiting-agenda', // Waiting Dates Agenda
    INTEREST: 'interest-agenda', // Interest Dates Agenda
};

const removeMessage = <div>
    <div>Têm a certeza que quer remover esta reserva?</div>
    <div>Toda a informação desta reserva será removida da plataforma.</div>
</div>

export const Legend = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
  padding-inline-start: 0;
`;

export const LegendItem = styled.li`
  padding: 10px;
`;

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
localizer.formats.yearHeaderFormat = 'YYYY'
// localizer.messages.year = 'Year'

export const locals = [
    { _id: 1, name: 'Solar da Levada' },
    { _id: 2, name: 'Quinta Lago dos Cisnes' }
];

const fields = [
    { _id: 'minPax', name: 'PAX Mínimo' },
    { _id: 'minPrice', name: 'Preço PAX Mínimo' },
    { _id: 'maxPax', name: 'PAX Máximo' },
    { _id: 'maxPrice', name: 'Preço PAX Máximo' }
];

const actions = [
    { _id: '+', name: '+' },
    { _id: '=', name: '' },
    { _id: '-', name: '-' }
];

class ReserveAgenda extends Component {
    state = {
        loading: false,
        year: moment().year(),
        yearFilter: null,
        local: 1,
        agendaType: AgendaType.AGENDA,
        viewType: 1,
        dataSource: [],

        waitingDatesLoading: false,
        waitingDates: [],
        selectingWaitingDate: false,

        interestDatesLoading: false,
        interestDates: [],
        selectingInterestDate: false,

        reservesLoading: false,
        reserveList: [],
        removeReserveList: [],

        editReserve: null,
        wedding: null,

        untilPeriodNotificationWaitingDate: 2,
        acceptedWaitingPolicy: false,
        showWaitingPolicyModal: false,

        acceptedInterestPolicy: false,
        savingWeddingInterest: false,
        showInterestPolicyModal: false,

        showReserveModal: false,
        valuesReserveModal: {},

        showDateModal: false,
        dataDateModal: null,

        showMultiplePriceModal: false,
        multiplePriceModal: null,
        submittingMultiplePriceModal: false,

        loadingPriceModal: false,
        showPriceModal: false,
        priceModal: null

        // showReserveModal: false,
        // reserveModal: null,
        // showUnavailableModal: false,
        // unavailableModal: false,
    };

    componentDidMount = async () => {
        const { match, location, history } = this.props;
        let { agendaType } = this.state;

        const isReservePriceRoute = match.url.includes('/reserves-prices') ? true : false;
        const waitingUrl = location?.pathname && location?.pathname === '/waiting-agenda' ? true : false;
        const interestUrl = location?.pathname && location?.pathname === '/interest-agenda' ? true : false;
        if (waitingUrl) agendaType = AgendaType.WAITING_DATE;
        else if (interestUrl) agendaType = AgendaType.INTEREST;
        else agendaType = AgendaType.AGENDA;


        const reserveId = new URLSearchParams(location.search).get('reserve') !== undefined ? new URLSearchParams(location.search).get('reserve') : null;
        const weddingId = new URLSearchParams(location.search).get('wedding') !== undefined ? new URLSearchParams(location.search).get('wedding') : null;

        if ((agendaType === AgendaType.INTEREST || agendaType === AgendaType.WAITING_DATE) && !weddingId) {
            history.push('/404');
            return;
        }

        if (isReservePriceRoute) {
            if (reserveId || weddingId) history.push('/reserves-prices');
            this.setState({ viewType: isReservePriceRoute ? 2 : 1 }, () => {
                this.getAgenda();
            });
        } else {
            this.setState({
                agendaType,
                // year: agendaType == AgendaType.INTEREST ? moment.utc().year() + 1 : moment.utc().year()
            }, async () => {
                if (reserveId && weddingId) {
                    history.push('/reserves-agenda');
                    this.getAgenda();
                } else if (weddingId) await this.getWedding(weddingId);
                else if (reserveId) await this.getReserve(reserveId);
                else await this.getAgenda();
            });
        }
    };

    componentDidUpdate = (prevProps, props) => {
        const oldReserveId = new URLSearchParams(prevProps.location.search).get('reserve') !== undefined ? new URLSearchParams(prevProps.location.search).get('reserve') : null;
        const newReserveId = new URLSearchParams(prevProps.history.location.search).get('reserve') !== undefined ? new URLSearchParams(prevProps.history.location.search).get('reserve') : null;
        if (oldReserveId && !newReserveId) {
            this.cancel();
        }
    }

    getAgenda = async (fromReserve = false, weddingId = null) => {
        const { year, local, agendaType } = this.state;
        let { reserveList, editReserve, wedding } = this.state;

        if (fromReserve) this.setState({ reservesLoading: true });
        else this.setState({ loading: true, reservesLoading: (weddingId || wedding?._id) ? true : false });

        try {
            const filters = { year, local };
            if (agendaType == AgendaType.INTEREST) {
                filters['agendaType'] = 'INTEREST';
              }
            const result = await GetReserveAgenda(filters);
            const data = result && result?.data ? result.data : [];

            if (weddingId) {
                const resultReserve = await GetReservesByWedding(weddingId);
                reserveList = resultReserve?.success && resultReserve?.data ? resultReserve.data : [];
            }

            if (weddingId || wedding?._id) {
                if (agendaType === AgendaType.WAITING_DATE) await this.getWaitingDates(weddingId);
                if (agendaType === AgendaType.INTEREST) await this.getInterestDates(weddingId);
            }

            // Select dates that are in reserve list
            reserveList.forEach((reserve) => {
                const index = data.findIndex(f => reserve?.event && f.id === reserve?.event?.id && reserve?.weddingPlace === local);
                if (index > -1) {
                    data[index].resource.selected = true;

                    if ((editReserve?.event && reserve?.event?.id === editReserve?.event?.id) ||
                        (!editReserve?.event && reserve?.event?.resource?.weddingReserve && reserve?.event?.resource?.weddingReserve?._id === editReserve?._id)) {
                        data[index].resource.edit = true;
                        editReserve = reserve;
                    }
                }
            });

            this.setState({
                dataSource: data,
                reserveList,
                editReserve,
                loading: false,
                reservesLoading: false
            });
        } catch (e) {
            this.setState({ loading: false, reservesLoading: false });
            console.error(e);
        }
    };

    getWaitingDates = async (weddingId = null) => {
        const { year, local } = this.state;
        const filters = { year, local };

        let { waitingDates, wedding } = this.state;
        this.setState({ waitingDatesLoading: true });

        if (weddingId || wedding?._id) {
            const id = weddingId ? weddingId : wedding?._id;
            const resultWaitingDates = await GetWaitingDateByWedding(id, JSON.stringify(filters));
            waitingDates = resultWaitingDates?.success && resultWaitingDates?.data ? resultWaitingDates.data : [];
        }

        this.setState({
            waitingDates,
            waitingDatesLoading: false
        });
    }

    getInterestDates = async (weddingId = null) => {
        const { year, local } = this.state;
        const filters = { year, local };

        let { interestDates, wedding } = this.state;
        this.setState({ interestDatesLoading: true });

        if (weddingId || wedding?._id) {
            const id = weddingId ? weddingId : wedding?._id;
            const resultInteresrDates = await GetInterestDateByWedding(id, JSON.stringify(filters));
            interestDates = resultInteresrDates?.success && resultInteresrDates?.data ? resultInteresrDates.data : [];
        }

        this.setState({
            interestDates,
            interestDatesLoading: false
        });
    }

    getReserve = async (reserveId) => {
        const { history } = this.props;
        const { year } = this.state;

        this.setState({ loading: true });
        const result = await GetReserve(reserveId, isCurrentUserEmployeeAttending());
        // console.warn('result', result);

        if (result?.success) {
            const reserveYear = result?.data?.date && moment(result?.data?.date).isValid()
                ? moment(result?.data?.date).year()
                : null;

            this.setState({
                editReserve: result?.data,
                wedding: result?.data?.wedding,
                acceptedWaitingPolicy: result?.data?.acceptedWaitingPolicy || false,
                untilPeriodNotificationWaitingDate: result?.data?.wedding?.untilPeriodNotificationWaitingDate || 2,
                local: result?.data?.weddingPlace,
                year: reserveYear && reserveYear !== year ? reserveYear : year
            }, () => this.getAgenda(false, result?.data?.wedding?._id));
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result?.data
            });
            this.setState({
                editReserve: null,
                wedding: null,
                acceptedWaitingPolicy: false,
                untilPeriodNotificationWaitingDate: 2
            }, () => {
                history.push('/reserves-agenda');
                this.getAgenda();
            });
        }
    }

    getWedding = async (weddingId) => {
        const { history } = this.props;
        const { agendaType } = this.state;

        this.setState({ loading: true });
        const result = await GetWedding(weddingId);
        // console.warn('result', result);

        if (result?.success) {
            if ((agendaType === AgendaType.AGENDA || agendaType === AgendaType.INTEREST)
                && result?.data?.status !== WeddingStatus.RESERVE) {
                history.push('/404');
                return;
            }

            this.setState({
                editReserve: null,
                wedding: result?.data,
                acceptedWaitingPolicy: result?.data?.acceptedWaitingPolicy || false,
                untilPeriodNotificationWaitingDate: result?.data?.untilPeriodNotificationWaitingDate || 2,
                acceptedInterestPolicy: result?.data?.acceptedInterestPolicy || false,
            }, () => this.getAgenda(false, result?.data?._id));
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result?.data
            });
            this.setState({
                editReserve: null,
                wedding: null,
                acceptedWaitingPolicy: false,
                untilPeriodNotificationWaitingDate: 2,
                acceptedInterestPolicy: false,
            }, () => {
                history.push('/reserves-agenda');
                this.getAgenda();
            });
        }
    }

    navigateToAgenda = (event) => {
        const { location, history } = this.props;
        const { wedding } = this.state;
        if (!wedding?._id) return;

        const page = new URLSearchParams(location.search).get('page');
        let returnPage = page
            ? `&page=${page}`
            : '';
        return history.push(`/${event?.target.value}?wedding=${wedding?._id}${returnPage}`);
    }

    changeTab = (event) => {
        this.setState({ local: event?.target?.value }, this.getAgenda);
    }

    changeYear = (event) => {
        const { year } = this.state;
        this.setState({ year: year + parseInt(event?.target?.value) }, this.getAgenda);
    }

    changeView = (event) => {
        const { history } = this.props;
        history.push(event?.target?.value === 2 ? '/reserves-prices' : '/reserves-agenda');
        // this.setState({ viewType: event.target.value }, this.getAgenda);
    }

    /** Waiting Dates */
    onMultipleWaitingClick = async (value) => {
        const { wedding, local, year } = this.state;
        // console.warn('value', value);
        this.setState({ selectingWaitingDate: true });

        if (value?.month >= 0) {
            if (moment.utc().set('year', year).set('month', value?.month).startOf('month').isBefore(moment.utc().startOf('month'), 'date')) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar meses anteriores à data atual.'
                });
                this.setState({ selectingWaitingDate: false });
                return;
            }

            const data = {
                type: value.type,
                weekday: value?.weekday >= 0 ? value.weekday : null,
                year,
                month: value.month,
                weddingPlace: local,
                wedding: wedding?._id ? wedding?._id : wedding,
            };

            const result = value?.selected
                ? await SoftDeleteMultipleWaitingDate(true, data)
                : await CreateMultipleWaitingDate(data);
            if (result?.success) {
                Alerts.new({
                    type: 'success',
                    title: 'Sucesso',
                    text: value?.selected
                        ? 'Datas eliminadas da lista de espera.'
                        : 'Datas adicionadas à lista de espera.'
                });
                this.setState({
                    selectingWaitingDate: false,
                    waitingDatesLoading: true
                }, () => this.getWaitingDates());
            } else {
                const errorMsg = result?.data == 'ERROR_DATE_FREE'
                    ? 'Ação inválida. Só pode adicionar semanas com datas ocupadas.'
                    : result?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorMsg
                });
                this.setState({ selectingWaitingDate: false });
            }
        }
    }

    onWaitingClick = async (value) => {
        const { wedding, local } = this.state;
        // console.warn('value', value);
        if (wedding?.status === WeddingStatus.RESERVE_SINALIZATION || wedding?.status === WeddingStatus.CONFIRMED
            && wedding?.date && value?.date && moment.utc(wedding.date).isSame(moment.utc(value.date), 'date')) {
            Alerts.new({
                type: 'warning',
                title: 'Atenção',
                text: 'Ação inválida. Não pode adicionar a data do casamento à lista de espera.'
            });
            return;
        }

        this.setState({ selectingWaitingDate: true });

        if (value?.date) {
            if (moment.utc().startOf('date').isSameOrAfter(moment(value?.date).startOf('date'), 'date')) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar datas inferiores à data atual.'
                });
                this.setState({ selectingWaitingDate: false });
                return;
            }

            const data = {
                type: WaitingActionType.DAY,
                date: value?.date,
                weddingPlace: local,
                wedding: wedding?._id ? wedding?._id : wedding,
            };
            const result = value?.selectedWaitingDate
                ? await SoftDeleteWaitingDateAgenda(true, data)
                : await CreateWaitingDate(data);
            if (result?.success) {
                Alerts.new({
                    type: 'success',
                    title: 'Sucesso',
                    text: value?.selectedWaitingDate
                        ? 'Data eliminada da lista de espera.'
                        : 'Data adicionada à lista de espera.'
                });
                this.setState({
                    selectingWaitingDate: false,
                    waitingDatesLoading: true
                }, () => this.getWaitingDates());
            } else {
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: result?.data
                });
                this.setState({ selectingWaitingDate: false });
            }
        }
    }

    /** Interest Dates */
    onInterestClick = async (value) => {
        const { wedding, local } = this.state;
        // console.warn('value', value);

        this.setState({ selectingInterestDate: true });

        if (value?.date) {
            if (moment.utc().startOf('date').isSameOrAfter(moment(value?.date).startOf('date'), 'date')) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar datas inferiores à data atual.'
                });
                this.setState({ selectingInterestDate: false });
                return;
            }

            const data = {
                date: value?.date,
                weddingPlace: local,
                price: value?.event?.resource?.price?._id,
                wedding: wedding?._id ? wedding?._id : wedding,
            };
            const result = value?.selectedInterestDate
                ? await DeleteInterestDateAgenda(true, data)
                : await CreateInterestDate(data);
            if (result?.success) {
                Alerts.new({
                    type: 'success',
                    title: 'Sucesso',
                    text: value?.selectedInterestDate
                        ? 'Data de interesse eliminada.'
                        : 'Data de interesse adicionada.'
                });
                this.setState({
                    selectingInterestDate: false,
                    interestDatesLoading: true
                }, () => this.getInterestDates());
            } else {
                const errorTxt = result?.data === 'EXISTS_RESERVES_FOR_DATE'
                    ? 'Ação inválida. A data encontra-se reservada.'
                    : result?.data === 'EXISTS_INTEREST_DATE'
                        ? 'Essa data já se encontra nas datas de interesse.'
                        : result?.data;
                Alerts.new({
                    type: 'error',
                    title: 'Erro',
                    text: errorTxt
                });
                this.setState({ selectingInterestDate: false });
            }
        }
    }

    /** Reserve Dates */
    onDayClick = async (value) => {
        const { local, reserveList, viewType, agendaType, wedding } = this.state;
        let { dataSource } = this.state;
        const returnPage = viewType === 2
            ? 'RP'
            : agendaType === AgendaType.WAITING_DATE
                ? 'WD'
                : agendaType === AgendaType.INTEREST
                    ? 'ID'
                    : 'RA';

        // Check if wedding/couple is in reserve phase
        if (wedding?.status && wedding?.status !== WeddingStatus.RESERVE && value?.action === AgendaActions.SELECT) {
            return;
        }

        // Check if user is trying to select or mng price for past date
        if (value?.action === AgendaActions.SELECT || value?.action === AgendaActions.MNG_PRICE) {
            if (value?.date && moment(value?.date).isBefore(moment().startOf('date'))) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar datas inferiores à data atual.'
                });
                return;
            }
        }

        // Select Data
        if (value?.action === AgendaActions.SELECT) {
            if (wedding && (!value?.event?.resource?.price || (value?.event?.resource?.price
                && (value?.event?.resource?.price?.minPax <= 0 || value?.event?.resource?.price?.minPrice <= 0)))) {
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível selecionar datas com preços por definir.'
                });
                return;
            }
            this.selectDate(value);
        }
        // Manage Price
        else if (value?.action === AgendaActions.MNG_PRICE) {
            this.setState({ showPriceModal: true, loadingPriceModal: true });

            const result = await GetReservePriceByDateAndLocal(value?.date?.toISOString(), local);
            // console.warn('Res Price', result);

            if (result.success) {
                this.setState({
                    loadingPriceModal: false,
                    priceModal: result.success && result.data ? result.data :
                        {
                            id: null,
                            month: value?.date.month() + 1,
                            dateTimestamp: value?.date.valueOf(),
                            date: value?.date,
                            weddingPlace: local,
                            minPax: 140
                        }
                });
            }
        }
        // Create Unavailable
        else if (value?.action === AgendaActions.CREATE_UNAVAILABLE) {
            this.createUnavailable(value);
        }
        // Remove Unavailable
        else if (value?.action === AgendaActions.REMOVE_UNAVAILABLE) {
            this.removeUnavailable(value);
        }
        // Edit Wedding
        else if (value?.action === AgendaActions.EDIT_WEDDING) {
            this.props.history.push(`/weddings/${value?.weddingId}?page=${returnPage}`);
        }
        // Access Wedding Information
        else if (value?.action === AgendaActions.INFO_WEDDING) {
            this.props.history.push(`/wedding/${value?.weddingId}?page=${returnPage}`);
        }
        // Access Archived Information
        else if (value?.action === AgendaActions.ARCHIVED_WEDDING) {
            this.props.history.push(`/archived/wedding/${value?.weddingId}?page=${returnPage}`)
        }
        // Mng Waiting Date
        else if (value?.action === WaitingActionType.DAY) {
            this.onWaitingClick(value);
        }
        // Mng Interest Date
        else if (value?.action === 'INTEREST_DATE') {
            this.onInterestClick(value);
        }
    }

    selectDate = (value) => {
        const { local, editReserve } = this.state;
        let { dataSource, reserveList } = this.state;

        // Edit date selected
        if (editReserve) {
            // console.warn('editReserve', editReserve);
            // console.warn('value', value);

            //Check if the selected date in already in reserve list
            const index = reserveList?.findIndex(f => f?.weddingPlace && f?.weddingPlace === local && value && value.event && f.event.id === value.event.id);
            if (index > -1) {
                return Alerts.new({
                    type: 'warning',
                    title: 'Atenção',
                    text: 'Essa data já se encontra selecionada!'
                });
            }

            this.setState({
                showDateModal: true,
                dataDateModal: {
                    original: editReserve,
                    new: { ...value, weddingPlace: local }
                }
            });
        }
        // select a new date
        else {
            this.setState({ reservesLoading: true });

            const index = dataSource?.findIndex(f => value?.event && f?.id === value?.event?.id);
            if (index > -1) {
                value.event.resource.selected = true;
                dataSource[index] = value?.event;

                const indexReserve = reserveList.findIndex(f => f?.event && value?.event && f?.event?.id === value?.event?.id && f?.weddingPlace === local);
                if (indexReserve === -1) reserveList.push({ ...value, weddingPlace: local });
            }
            this.setState({ reserveList, dataSource }, () => {
                setTimeout(() => this.setState({ reservesLoading: false }), 200);
            });
        }
    }

    removeDate = (reserve) => {
        this.setState({ reservesLoading: true });
        let { reserveList, removeReserveList, dataSource } = this.state;
        const indexData = dataSource?.findIndex(f => f.id === reserve?.event?.id);
        if (indexData > -1) {

            reserve.event.resource.selected = false;
            reserve.event.resource.edit = false;
            dataSource[indexData] = reserve.event;
        }

        const indexReserve = reserveList.findIndex(f => f?.event && reserve?.event && f?.event?.id === reserve?.event?.id && f?.weddingPlace === reserve?.weddingPlace);
        if (indexReserve > -1) {
            // if reserve that it's already in database add to removeReserveList
            if (reserveList[indexReserve] && reserveList[indexReserve]?.event && reserveList[indexReserve]?.event?.resource && reserveList[indexReserve]?.event?.resource?.weddingReserve) {
                const indexDelete = removeReserveList.findIndex(f => f?._id === reserveList[indexReserve].event.resource.weddingReserve?._id);
                if (indexDelete === -1) removeReserveList.push(reserveList[indexReserve].event.resource.weddingReserve);
            }

            reserveList.splice(indexReserve, 1);
        }

        this.setState({ reserveList, dataSource, removeReserveList }, () => {
            setTimeout(() => this.setState({ reservesLoading: false }), 200);
        });
    }

    editReserveDate = (value) => {
        const { local, editReserve } = this.state;
        let { dataSource, reserveList } = this.state;

        this.setState({ showDateModal: false, dataDateModal: null, reservesLoading: true });

        // Unselect editReserve date
        const indexEdit = dataSource.findIndex(f => editReserve && editReserve.event && f?.id === editReserve.event?.id && editReserve?.weddingPlace === local);
        if (indexEdit > -1) {
            editReserve.event.resource.selected = false;
            editReserve.event.resource.edit = false;
            dataSource[indexEdit] = editReserve.event;
        }

        // Select new date
        const indexData = dataSource.findIndex(f => value && value.event && f?.id === value.event?.id);
        if (indexData > -1) {
            value.event.resource.selected = true;
            dataSource[indexData] = value.event;
        }

        // Update reserve list with new date information
        const indexReserve = reserveList.findIndex(f => f?.event && editReserve?.event && f?.event?.id === editReserve?.event?.id && f?.weddingPlace === editReserve?.weddingPlace);
        if (indexReserve > -1) {
            const weddingReserve = reserveList[indexReserve].event && reserveList[indexReserve].event.resource && reserveList[indexReserve].event.resource.weddingReserve ? reserveList[indexReserve].event.resource.weddingReserve : null;
            const updateReserve = { ...value, weddingPlace: local };
            if (weddingReserve && updateReserve && updateReserve.event && updateReserve.event.resource) updateReserve.event.resource['weddingReserve'] = weddingReserve;
            // console.warn('updateReserve', updateReserve);
            reserveList[indexReserve] = updateReserve;
        }

        this.setState({
            reserveList,
            dataSource,
            editReserve: null
        }, () => {
            setTimeout(() => this.setState({ reservesLoading: false }), 200);
        });
    }

    editDate = (reserve) => {
        const { local, editReserve, year } = this.state;
        let { dataSource } = this.state;
        // console.warn('reserve', reserve);
        // console.warn('editReserve', editReserve);

        // Remove edit color on editReserve date
        const indexEdit = dataSource.findIndex(f => editReserve && editReserve.event && f?.id === editReserve.event?.id && editReserve?.weddingPlace === local);
        if (indexEdit > -1) {
            editReserve.event.resource.edit = false;
            dataSource[indexEdit] = editReserve.event;
        }

        // Set edit color
        const indexData = dataSource.findIndex(f => f?.id === reserve?.event?.id);
        if (indexData > -1) {
            reserve.event.resource.edit = true;
            dataSource[indexData] = reserve.event;
        }

        const reserveYear = reserve?.date && moment.utc(reserve.date).isValid()
            ? moment.utc(reserve.date).year()
            : null;

        this.setState({
            dataSource,
            editReserve: reserve,
            local: reserve?.weddingPlace,
            year: reserveYear && reserveYear !== year ? reserveYear : year
        }, () => this.getAgenda(false));
    }

    cancelEditDate = () => {
        const { local, editReserve } = this.state;
        let { dataSource } = this.state;

        // Remove edit color on editReserve date
        const indexEdit = dataSource.findIndex(f => editReserve && editReserve.event && f?.id === editReserve.event?.id && editReserve?.weddingPlace === local);
        if (indexEdit > -1) {
            editReserve.event.resource.edit = false;
            dataSource[indexEdit] = editReserve.event;
        }

        this.setState({ dataSource, editReserve: null }, () => this.getAgenda(false));
    }

    cancel = () => {
        const { history } = this.props;
        this.setState({
            reserveList: [],
            removeReserveList: [],
            editReserve: null,
            wedding: null,
            acceptedWaitingPolicy: false,
            untilPeriodNotificationWaitingDate: 2,
            acceptedInterestPolicy: false,
            showReserveModal: false,
            valuesReserveModal: {},
            showDateModal: false,
            dataDateModal: null
        }, () => {
            history.push('/reserves-agenda');
            this.getAgenda();
        });
    }

    goBack = () => {
        const { history, location } = this.props;
        const fromReserve = new URLSearchParams(location.search).get('page') === 'R' ? true : false;
        const fromWaitingDateList = new URLSearchParams(location.search).get('page') === 'WD' ? true : false;
        const fromInterestDateList = new URLSearchParams(location.search).get('page') === 'ID' ? true : false;
        const fromSchedules = new URLSearchParams(location.search).get('page') === 'S' ? true : false;

        if (fromReserve) return history.push('/reserves');
        else if (fromWaitingDateList) return history.push('/waiting-dates');
        else if (fromInterestDateList) return history.push('/interest-dates');
        else if (fromSchedules) return history.push('/schedules');
        else {
            history.push('/reserves-agenda');
            this.getAgenda();
        }
    };

    /** Reserve */
    showModalReserve = async () => {
        const { reserveList, editReserve, wedding } = this.state;

        if (reserveList.length === 0) {
            return Alerts.new({
                type: 'error',
                title: 'Erro',
                text: 'Selecione as datas pretendidas.'
            });
        }

        const result = await GetReservesWithOrder(reserveList);
        if (result?.success) {
            const valuesReserveModal = wedding ? {
                ...wedding,
                bridename: wedding.bride.name,
                brideemail: wedding.bride.email,
                bridecontact: wedding.bride.contact,
                groomname: wedding.groom.name,
                groomemail: wedding.groom.email,
                groomcontact: wedding.groom.contact,
                alternativename: wedding?.alternativeGuest?.name || '',
                alternativemail: wedding?.alternativeGuest?.email || '',
                alternativecontact: wedding?.alternativeGuest?.contact || '',
                alternativerelationcouple: wedding?.alternativeGuest?.relationToCouple || null
            } : {};

            this.setState({
                showReserveModal: true,
                valuesReserveModal,
                reserveList: result.data
            });
        }
    }

    finishReserve = async (values) => {
        const { history } = this.props;
        const { wedding, editReserve, reserveList, removeReserveList } = this.state;
        // console.warn('values', values);

        // if ((!values?.visitedPlace || values?.visitedPlace == false)
        //     && (!values?.placeToVisit || values?.placeToVisit == false)
        //     && (!values?.wantToVisitWithoutInfo || values?.wantToVisitWithoutInfo == false)) {
        //     Alerts.new({
        //         type: 'warning',
        //         title: 'Atenção',
        //         text: 'Indique se já visitou ou pretende visitar algum dos espaços.'
        //     });
        //     return;
        // }

        // Check if all dates selected are still available
        const dataCheck = {
            wedding,
            reserves: reserveList,
            removeReserves: removeReserveList
        };
        const resultCheckDates = await CheckDatesAvailable(dataCheck);
        if (!resultCheckDates?.success) {
            if (resultCheckDates?.data === 'ERROR_DATE_OCCUPIED' || resultCheckDates?.data === 'ERROR_DATE_UNAVAILABLE'
                || resultCheckDates?.data === 'DUPLICATED_DATES') {
                const message = resultCheckDates?.data === 'ERROR_DATE_OCCUPIED'
                    ? 'Não é possivel realizar a operação pois já existe uma data ocupada ou em sinalização.'
                    : resultCheckDates?.data === 'ERROR_DATE_UNAVAILABLE'
                        ? 'Função indisponível, data indisponível para reserva.'
                        : resultCheckDates?.data === 'DUPLICATED_DATES'
                            ? 'Datas duplicadas!'
                            : '';
                Alerts.new({
                    type: 'warning',
                    title: 'Atenção!',
                    text: message
                });
            } else {
                Alerts.new({
                    type: 'error',
                    title: 'Erro!',
                    text: resultCheckDates?.data
                });
            }
            return;
        }

        const data = setDataToUpdateForReserveWedding(values);
        const resultCoupleWedding = wedding
            ? await UpdateReserveRelatedInfo(wedding._id, data)
            : await CreateCoupleReserve(data);

        if (resultCoupleWedding.success) {
            this.mngReserves(wedding ? resultCoupleWedding.data : resultCoupleWedding.data.wedding);
        } else return;
    }

    mngReserves = async (wedding) => {
        const { history } = this.props;
        const { reserveList, removeReserveList } = this.state;

        const data = {
            wedding,
            reserves: reserveList,
            removeReserves: removeReserveList
        };
        const resultReserves = await MngMultipleReserves(data);
        // console.warn('resultReserves', resultReserves);

        if (resultReserves?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Reservas Criadas'
            });

            this.setState({
                reserveList: [],
                removeReserveList: []
            }, () => history.push('/reserves'));
        }
    }
    /** end Reserve */

    /** Reserve Price */
    deletePrice = async (row) => {
        const { selectedRow, selectedRowIndex, rows } = this.state;

        if (selectedRow !== null && moment.utc(selectedRow.date).startOf('day').isSame(moment.utc(row.date).startOf('day'))) {

            if (selectedRow?.id) {
                const result = await DeleteReservePrice(selectedRow.id);
                // console.warn('REs', result);
                if (result.data.ok == 1) {
                    rows[selectedRowIndex].id = null;
                    rows[selectedRowIndex]._id = null;
                    rows[selectedRowIndex].minPax = 0;
                    rows[selectedRowIndex].minPrice = 0;
                    rows[selectedRowIndex].maxPax = 0;
                    rows[selectedRowIndex].maxPrice = 0;
                }
            } else {
                rows[selectedRowIndex].id = null;
                rows[selectedRowIndex]._id = null;
                rows[selectedRowIndex].minPax = 0;
                rows[selectedRowIndex].minPrice = 0;
                rows[selectedRowIndex].maxPax = 0;
                rows[selectedRowIndex].maxPrice = 0;
            }
            this.setState({ rows });
        }

    }

    updateMultiplePrices = async () => {
        const { edit, filters } = this.state;
        let { rows } = this.state;
        const rowsNotSelected = rows.filter(f => !f.selected);
        const rowsSelected = rows.filter(f => f.selected);

        // if (!edit?.field || edit?.field === '' || (Array.isArray(edit?.field) && edit?.field?.length === 0)) {
        //     Alert.new({
        //         type: "error",
        //         title: "Atenção",
        //         text: "É necessário selecionar pelo menos um campo a alterar."
        //     });
        //     return;
        // }

        if (!edit.startDate && !edit.endDate && Array.isArray(rowsSelected) && rowsSelected?.length === 0) {
            Alert.new({
                type: 'error',
                title: 'Atenção',
                text: 'É necessário selecionar uma data ou selecionar linhas!'
            });
            return;
        }

        const result = await UpdateMultipleReservePrice(edit, filters, rowsNotSelected, getUserId());
        // console.warn('Res Multiple', result);

        this.getAgenda();
    }

    onSubmitPrice = async (data) => {
        data.user = getUserId();

        const result = data?._id ? await UpdateReservePrice(data) : await CreateReservePrice(data);
        // console.warn('Res', result);

        if (result.success) {
            this.setState({
                showPriceModal: false,
                priceModal: null
            }, this.getAgenda);
        }
    }

    onSubmitMultiplePrice = async (data) => {
        const { year, local, reserveList } = this.state;
        this.setState({ submittingMultiplePriceModal: true });

        const reserves = data?.checkReserveList ? reserveList : [];

        const result = await UpdateMultipleReservePrice(data, year, local, getUserId(), reserves);
        // console.warn('Res Multiple', result);

        if (result.success) {
            this.setState({
                submittingMultiplePriceModal: false,
                showMultiplePriceModal: false,
                multiplePriceModal: {},
                reserveList: []
            }, this.getAgenda);
        }
    }
    /** end Reserve Price */

    /** Unavailable */
    createUnavailable = async (value) => {
        const { local } = this.state;

        const unavailable = {
            startDate: value?.date?.startOf('day').toISOString(),
            endDate: value?.date?.endOf('day').toISOString(),
            allDay: true,
            startTime: null,
            endTime: null,
            companyRest: true,
            exceptionDayForCouplePayment: false,
            employee: null,
            user: getUserId(),
            weddingPlace: local
        };

        const result = await CreateUnavailable(unavailable);
        // console.warn('Res', result);

        if (result.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Indisponibilidade Registada!'
            });
            this.getAgenda();
        }
    }

    removeUnavailable = async (value) => {
        const { local } = this.state;

        const result = await RemoveUnavailableByAgenda(value.date, local);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Indisponibilidade eliminada!'
            });
            this.getAgenda();
        }
    }

    onSubmitMultipleUnavailable = async () => {
        const { year, local, reserveList } = this.state;

        const reserves = reserveList ? reserveList : [];

        const result = await UpdateMultipleCompanyRest(local, reserves);
        // console.warn('Res Multiple', result);

        if (result?.success && result?.data) {
            if (result?.data && Array.isArray(result.data) && result.data.length < reserves.length) {
                Alerts.new({
                    type: 'success',
                    title: 'Atenção',
                    text: 'Algumas indisponibilidades não foram possível registar!'
                });
            } else {
                Alerts.new({
                    type: 'success',
                    title: 'Sucesso',
                    text: 'Indisponibilidades Registadas!'
                });
            }
            this.getAgenda();
        }
    }
    /** end Unavailable */

    /** Reserve Menu */
    onSelectReserveMenu = (value) => {
        const { history } = this.props;
        const { reserveList } = this.state;
        const itemSelected = parseInt(value.key);

        if (reserveList.length === 0) {
            return Alert.new({
                type: 'error',
                title: 'Erro',
                text: 'Selecione as datas pretendidas.'
            });
        }

        // Manage Prices
        if (itemSelected === 1) {
            this.setState({
                showMultiplePriceModal: true,
                multiplePriceModal: {
                    startDate: null,
                    endDate: null,
                    checkReserveList: true
                }
            });
        }
        // Create Reserve
        else if (itemSelected === 2) {
            this.showModalReserve();
        }
        // Create Unavailables
        else if (itemSelected === 3) {
            this.onSubmitMultipleUnavailable();
        }
    }

    menuReserve = (
        <Menu onClick={this.onSelectReserveMenu}>
            <Menu.Item key={1}>
                <Icon type="euro" />
                Gerir Preços
            </Menu.Item>
            <Menu.Item key={2}>
                <Icon type="carry-out" />
                Criar Reserva
            </Menu.Item>
            <Menu.Item key={3}>
                <Icon type="calendar" />
                Criar Indisponibilidade
            </Menu.Item>
        </Menu>
    );
    /** end Reserve Menu */

    /** Calendar Menu */
    onSelectCalendarMenu = (value) => {
        // console.warn('Val', value);
        const { history } = this.props;
        const { viewType } = this.state;

        const returnPage = viewType === 2 ? 'RP' : 'RA';
        const itemSelected = parseInt(value?.key);
        // Manage Prices
        if (itemSelected === 1) {
            this.setState({
                showMultiplePriceModal: true,
                multiplePriceModal: {
                    startDate: null,
                    endDate: null,
                    minPax: 140,
                    checkReserveList: false
                }
            });
        }
        // Rest Days
        else if (itemSelected === 2) {
            history.push(`/rest-days?page=${returnPage}`)
        }
        // Employee Payment Days
        else if (itemSelected === 3) {
            history.push(`/payment-days/?page=${returnPage}`);
        }
        // Couple Exception Payment Days
        else if (itemSelected === 4) {
            history.push(`/couple-payment-days/?page=${returnPage}`);
        }
    }

    menu = (
        <Menu onClick={this.onSelectCalendarMenu}>
            <Menu.Item key={1}>
                <Icon type="euro" />
                Gerir Preços
            </Menu.Item>
            <Menu.Item key={2}>
                <Icon type="calendar" />
                Dias de Descanso
            </Menu.Item>
            <Menu.Item key={3}>
                <Icon type="euro" />
                Dias de Pagamento
            </Menu.Item>
            <Menu.Item key={4} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                <Icon type="sketch" />
                <span style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start', alignItems: 'flex-start' }}>
                    <span>Dias de Exceção</span>
                    <span>Pagamento Noivos</span>
                </span>
            </Menu.Item>
        </Menu>
    );
    /** end Calendar Menu */

    changeWeddingWaitingInfo = async () => {
        const { wedding, untilPeriodNotificationWaitingDate, acceptedWaitingPolicy } = this.state;
        const weddingId = wedding?._id ? wedding?._id : wedding;
        this.setState({ savingWeddingWaiting: true });
        const data = {
            period: untilPeriodNotificationWaitingDate,
            // accepted: acceptedWaitingPolicy
        };

        const result = await UpdateWaitingInfo(weddingId, data);

        if (result?.success) {
            Alerts.new({
                type: 'success',
                title: 'Sucesso',
                text: 'Informação atualizada com sucesso!'
            });
            this.setState({
                wedding: result?.data,
                acceptedWaitingPolicy: result?.data?.acceptedWaitingPolicy,
                untilPeriodNotificationWaitingDate: result?.data?.untilPeriodNotificationWaitingDate,
                savingWeddingWaiting: false,
            });
        } else {
            this.setState({ savingWeddingWaiting: false });
            Alerts.new({
                type: 'error',
                title: 'Erro',
                text: result?.data
            });
        }
    }

    render() {
        const { history } = this.props;
        const { loading, year, yearFilter, local, dataSource, waitingDates, interestDates, viewType, agendaType } = this.state;
        const { reserveList, reservesLoading } = this.state;
        const { showMultiplePriceModal, multiplePriceModal, submittingMultiplePriceModal } = this.state;
        const { showPriceModal, priceModal, loadingPriceModal } = this.state;
        const { showReserveModal, valuesReserveModal } = this.state;
        const { showDateModal, dataDateModal } = this.state;
        const { editReserve, wedding, showWaitingPolicyModal, showInterestPolicyModal } = this.state;
        const { selectingWaitingDate, waitingDatesLoading, untilPeriodNotificationWaitingDate, acceptedWaitingPolicy, savingWeddingWaiting } = this.state;
        const { selectingInterestDate, interestDatesLoading, acceptedInterestPolicy, savingWeddingInterest } = this.state;
        // const { showUnavailableModal, unavailableModal } = this.state;
        const title = viewType === 2 ? 'Agenda de Preços das Reservas' : 'Agenda de Reservas';
        const reservePhase = !wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE) ? true : false;
        const reserveSinalizationPhase = wedding && wedding?.status && wedding?.status === WeddingStatus.RESERVE_SINALIZATION ? true : false;

        return (
            <React.Fragment>
                <HeaderContainer>
                    <HeaderTitle buttons={3}>
                        <PageTitle>{title}</PageTitle>
                        {wedding !== undefined && wedding !== null
                            ? getWeddingTitle(wedding)
                            : ''}
                    </HeaderTitle>
                    <HeaderButtonsContainer buttons={3}>
                        <Radio.Group value={viewType} disabled={loading} onChange={(event) => this.changeView(event)} style={{ marginRight: 10 }}>
                            <Radio.Button value={1}>
                                <Icon className='reserve-header-icon' type='calendar' style={{ marginRight: 5 }} />
                                Agenda
                            </Radio.Button>
                            <Radio.Button value={2}>
                                <Icon className='reserve-header-icon' type='euro' style={{ marginRight: 5 }} />
                                Preços
                            </Radio.Button>
                        </Radio.Group>
                        <Dropdown overlay={this.menu} trigger={['click']}>
                            <BaseButton
                                type={'primary'}
                                icon={'down'}
                                text={'Opções'}
                            />
                        </Dropdown>
                    </HeaderButtonsContainer>
                </HeaderContainer>
                <PageContainer style={{ padding: '0 20px 20px 20px' }} buttons={3}>
                    <Row gutter={[12, 12]} type='flex'>
                        <Col
                            xl={{ span: 20, order: 1 }}
                            lg={{ span: 19, order: 1 }}
                            md={{ span: 24, order: 2 }}
                            sm={{ span: 24, order: 2 }}
                            xs={{ span: 24, order: 2 }}>
                            <CalendarBox reserves={true}>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12} lg={10} xl={8} xxl={8}>
                                        <RadioPlace>
                                            <Radio.Group value={local} disabled={loading} onChange={(event) => this.changeTab(event)}>
                                                {locals.map((local, index) => (
                                                    <Radio.Button key={index} value={local._id}>
                                                        {local.name}
                                                    </Radio.Button>
                                                ))}
                                            </Radio.Group>
                                        </RadioPlace>
                                    </Col>
                                    <Col xs={24} md={7} lg={10} xl={10} xxl={10}>
                                        <RadioYear>
                                            <Radio.Group value={0} disabled={loading} onChange={(event) => this.changeYear(event)}>
                                                <Radio.Button value={-1} disabled={year <= moment().year()}>
                                                    <Tooltip className='iconPrevious' title={'Ano Anterior'}>
                                                        <Icon type="left" />
                                                    </Tooltip>

                                                    <span className='spanPrevious'>
                                                        <Icon type="left" />
                                                        {'Ano Anterior'}
                                                    </span>
                                                </Radio.Button>
                                                <Radio.Button value={0}>{year}</Radio.Button>
                                                <Radio.Button value={1}>
                                                    <Tooltip className='iconNext' title={'Ano Seguinte'}>
                                                        <Icon type="right" />
                                                    </Tooltip>

                                                    <span className='spanNext'>
                                                        {'Ano Seguinte'}
                                                        <Icon type="right" />
                                                    </span>
                                                </Radio.Button>
                                            </Radio.Group>
                                        </RadioYear>
                                    </Col>
                                    <Col xs={24} md={5} lg={4} xl={6} xxl={6}>
                                        <CalendarPicker>
                                            <YearInput
                                                placeholder="Selecione o ano"
                                                input={{
                                                    value: yearFilter,
                                                    onChange: (value) => {
                                                        this.setState({
                                                            yearFilter: value,
                                                            year: value !== '' ? parseInt(value) : moment().year()
                                                        }, this.getAgenda);
                                                    }
                                                }}
                                                disabled={loading}
                                                allowClear={true}
                                                meta={{}} />
                                        </CalendarPicker>
                                    </Col>
                                </Row>

                                <Row>
                                    <div style={{ marginTop: '2rem', position: 'relative' }}>
                                        {loading ?
                                            <SpinLoading /> :
                                            <React.Fragment>
                                                {(reservesLoading || selectingWaitingDate || waitingDatesLoading || selectingInterestDate || interestDatesLoading)
                                                    && <div className='backdrop'>
                                                        <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                                                    </div>}
                                                <Calendar
                                                    loading={loading || reservesLoading || waitingDatesLoading || selectingWaitingDate || interestDatesLoading || selectingInterestDate}
                                                    localizer={localizer}
                                                    defaultDate={moment().set('year', year)}
                                                    events={dataSource}
                                                    selectedDates={reserveList}
                                                    wedding={wedding}
                                                    waitingDates={waitingDates}
                                                    interestDates={interestDates}
                                                    reserveView={agendaType === AgendaType.AGENDA}
                                                    agendaType={agendaType}
                                                    toolbar={false}
                                                    defaultView='year'
                                                    views={{
                                                        // day: true,
                                                        // week: true,
                                                        // month: true,
                                                        year: viewType === 1 ? YearView : PriceView
                                                    }}
                                                    messages={{ year: 'Year' }}
                                                    onDayClick={this.onDayClick}
                                                    onWaitingClick={this.onWaitingClick}
                                                    onMultipleWaitingClick={this.onMultipleWaitingClick}
                                                    onInterestClick={this.onInterestClick}
                                                />
                                                <Legend>
                                                    <LegendItem><b>F:</b> Feriado Português</LegendItem>
                                                    <LegendItem><b>FM:</b> Feriado Municipal Português</LegendItem>
                                                    <LegendItem><b>I:</b> Data Indisponível</LegendItem>
                                                    <LegendItem><b>ND:</b> Preço Não Definido</LegendItem>
                                                    {wedding !== null && wedding !== undefined && agendaType === AgendaType.WAITING_DATE
                                                        && <LegendItem><b><WaitingIcon src={Bookmark} />:</b> Lista de Espera</LegendItem>}
                                                    {wedding !== null && wedding !== undefined && agendaType === AgendaType.INTEREST
                                                        && <LegendItem><b><WaitingIcon src={Star} />:</b> Datas de Interesse</LegendItem>}
                                                </Legend>
                                            </React.Fragment>
                                        }
                                    </div>
                                </Row>
                            </CalendarBox>
                        </Col>
                        <Col className='reserve-column'
                            xl={{ span: 4, order: 2 }}
                            lg={{ span: 5, order: 2 }}
                            md={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            xs={{ span: 24, order: 1 }}>
                            <ReserveContent>
                                {wedding && reservePhase && <React.Fragment>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <ReserveTitle>Selecione a agenda que pretende</ReserveTitle>
                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Radio.Group
                                                onChange={(e) => this.setState({ agendaType: e?.target.value }, () => this.navigateToAgenda(e))}
                                                value={agendaType}
                                                disabled={loading}
                                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                {reservePhase && <Radio value={AgendaType.AGENDA}>Reservas</Radio>}
                                                {reservePhase && <Radio value={AgendaType.INTEREST}>Reservas + Datas de Interesse</Radio>}
                                                {wedding && <Radio value={AgendaType.WAITING_DATE}>Reservas + Lista de Espera</Radio>}
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                </React.Fragment>}

                                {agendaType === AgendaType.AGENDA && <React.Fragment>
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <ReserveTitle>Lista de Reservas</ReserveTitle>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'end' }}>
                                            <BaseButton
                                                type={'default'}
                                                icon={'close'}
                                                text={'Cancelar'}
                                                onClick={() => this.goBack()}
                                                style={{ width: '100%' }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'end' }}>
                                            {wedding
                                                ? <BaseButton
                                                    type={'primary'}
                                                    icon={'save'}
                                                    disabled={loading || reservesLoading}
                                                    text={'Ativar'}
                                                    onClick={() => this.showModalReserve()}
                                                    style={{ width: '100%' }}
                                                />
                                                : <Dropdown disabled={loading || reservesLoading} overlay={this.menuReserve} trigger={['click']}>
                                                    <Button style={{ width: '100%' }}>
                                                        Opções
                                                        <Icon type="down" />
                                                    </Button>
                                                </Dropdown>}
                                        </Col>
                                    </Row>

                                    <Row gutter={[12, 12]}>
                                        {locals.map((local, index) => (
                                            <Col key={index} xs={24} sm={12} md={12} lg={24} xl={24}>
                                                <ReserveSubtitle>{local.name}</ReserveSubtitle>
                                                {reservesLoading && <SpinLoading style={{ minHeight: 30 }} />}
                                                {reserveList.filter(f => f?.weddingPlace === local._id).length === 0 && <p>Sem reservas</p>}
                                                {reserveList.filter(f => f?.weddingPlace === local._id).length > 0 &&
                                                    <ReserveList>
                                                        {reserveList.filter(f => f?.weddingPlace === local._id)
                                                            .map(m => ({ ...m, date: m?.date && moment.utc(m.date).isValid() ? moment.utc(m.date).toISOString() : m.date }))
                                                            .sort((a, b) => a?.date && b?.date && a?.date > b?.date ? 1 : -1)
                                                            .map((reserve, indexR) => <ReserveItem key={indexR} edit={editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && reserve.weddingPlace === editReserve.weddingPlace && reserve?.date && editReserve?.date && moment.utc(reserve?.date).isSame(editReserve?.date, 'day')}>
                                                                <div>
                                                                    <p style={{ marginBottom: 0 }}>
                                                                        <b>Data: </b>
                                                                        {reserve?.date ? moment.utc(reserve.date).format('DD/MM/YYYY') : ''}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    {(reserve?.event?.resource?.weddingReserve?.sentWaitingNotification === undefined ||
                                                                        (reserve?.event?.resource?.weddingReserve?.sentWaitingNotification !== undefined &&
                                                                            reserve?.event?.resource?.weddingReserve?.sentWaitingNotification === false)) && <React.Fragment>
                                                                            {(reserve && reserve?.event?.resource?.weddingReserve
                                                                                && reserve?.event?.resource?.weddingReserve?.status === ReserveStatus.RSC
                                                                                && editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && editReserve?.weddingPlace === reserve.weddingPlace
                                                                                && editReserve?.date && reserve?.date && moment.utc(reserve?.date).isSame(editReserve?.date, 'day'))
                                                                                || (editReserve && editReserve?.weddingPlace && reserve?.weddingPlace && editReserve?.weddingPlace === reserve.weddingPlace
                                                                                    && editReserve?.date && reserve?.date && moment.utc(reserve?.date).isSame(editReserve?.date, 'day'))
                                                                                ? <Tooltip title='Cancelar Edição'>
                                                                                    <Icon type='close-circle' onClick={() => this.cancelEditDate(reserve)}></Icon>
                                                                                </Tooltip>
                                                                                : <Tooltip title='Editar'>
                                                                                    <Icon type='edit' onClick={() => this.editDate(reserve)}></Icon>
                                                                                </Tooltip>
                                                                            }
                                                                        </React.Fragment>}
                                                                    {(reserve && reserve?.event && reserve?.event?.resource
                                                                        && reserve?.event?.resource?.weddingReserve
                                                                        && reserve?.event?.resource?.weddingReserve?.status !== ReserveStatus.RSC)
                                                                        ? null
                                                                        : <Popconfirm
                                                                            placement="topRight"
                                                                            title={removeMessage}
                                                                            okText='Eliminar'
                                                                            onConfirm={() => this.removeDate(reserve)}>
                                                                            <Icon type="delete" />
                                                                        </Popconfirm>
                                                                    }
                                                                </div>
                                                            </ReserveItem>)
                                                        }
                                                    </ReserveList>
                                                }
                                            </Col>
                                        ))}
                                    </Row>
                                </React.Fragment>}

                                {agendaType === AgendaType.WAITING_DATE && <React.Fragment>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <ReserveTitle>Lista de Espera</ReserveTitle>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'end' }}>
                                            <BaseButton
                                                type={'default'}
                                                icon={'close'}
                                                text={'Cancelar'}
                                                onClick={() => this.goBack()}
                                                style={{ width: '100%' }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'end' }}>
                                            {wedding && <BaseButton
                                                type={'primary'}
                                                icon={'save'}
                                                disabled={savingWeddingWaiting || loading}
                                                text={'Guardar'}
                                                onClick={() => this.changeWeddingWaitingInfo()}
                                                style={{ width: '100%' }}
                                            />}
                                        </Col>
                                    </Row>

                                    {(savingWeddingWaiting || loading)
                                        ? <SpinLoading style={{ minHeight: 30 }} />
                                        : <Row gutter={[12, 12]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <ReserveSubtitle>Pretendo ser notificado até</ReserveSubtitle>
                                                <Radio.Group
                                                    onChange={(e) => this.setState({ untilPeriodNotificationWaitingDate: e?.target?.value || 2 })}
                                                    value={untilPeriodNotificationWaitingDate}
                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Radio value={12}>12 meses antes</Radio>
                                                    <Radio value={6}>6 meses antes</Radio>
                                                    <Radio value={2}>2 meses antes</Radio>
                                                </Radio.Group>
                                            </Col>
                                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Checkbox
                                                className='checkbox-waiting-policy'
                                                checked={acceptedWaitingPolicy}
                                                disabled={wedding?.acceptedWaitingPolicy}
                                                onChange={(e) => this.setState({ acceptedWaitingPolicy: e?.target?.checked })}>
                                                <LinkAcceptPolicy onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ showWaitingPolicyModal: true })
                                                }}>Aceito os termos e condições da lista de espera</LinkAcceptPolicy>
                                            </Checkbox>
                                        </Col> */}
                                        </Row>
                                    }
                                </React.Fragment>}

                                {agendaType === AgendaType.INTEREST && <React.Fragment>
                                    <Row gutter={[12, 12]} className='reserve-title'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <ReserveTitle>Datas de Interesse</ReserveTitle>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'end' }}>
                                            <BaseButton
                                                type={'default'}
                                                icon={'close'}
                                                text={'Cancelar'}
                                                onClick={() => this.goBack()}
                                                style={{ width: '100%' }}
                                            />
                                        </Col>
                                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'end' }}>
                                            {wedding && <BaseButton
                                                type={'primary'}
                                                icon={'save'}
                                                disabled={savingWeddingInterest || loading}
                                                text={'Guardar'}
                                                onClick={() => this.changeWeddingWaitingInfo()}
                                                style={{ width: '100%' }}
                                            />}
                                        </Col> */}
                                    </Row>

                                    {/* {(savingWeddingInterest || loading)
                                        ? <SpinLoading style={{ minHeight: 30 }} />
                                        : <Row gutter={[12, 12]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Checkbox
                                                className='checkbox-waiting-policy'
                                                checked={acceptedWaitingPolicy}
                                                disabled={wedding?.acceptedWaitingPolicy}
                                                onChange={(e) => this.setState({ acceptedWaitingPolicy: e?.target?.checked })}>
                                                <LinkAcceptPolicy onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ showWaitingPolicyModal: true })
                                                }}>Aceito os termos e condições da lista de espera</LinkAcceptPolicy>
                                            </Checkbox>
                                        </Col>
                                        </Row>
                                    } */}
                                </React.Fragment>}
                            </ReserveContent>
                        </Col>
                    </Row>

                    {showMultiplePriceModal && <MultipleReservePriceModal
                        isOpen={showMultiplePriceModal}
                        onClose={e => this.setState({ showMultiplePriceModal: false, multiplePriceModal: null })}
                        onSubmit={this.onSubmitMultiplePrice}
                        initialValues={multiplePriceModal}
                        submitting={submittingMultiplePriceModal}
                        dateList={reserveList.length > 0 ? true : false}
                        year={year}
                    />}

                    <ReservePriceModal
                        isOpen={showPriceModal}
                        loading={loadingPriceModal}
                        onClose={e => this.setState({ showPriceModal: false, priceModal: null })}
                        onSubmit={this.onSubmitPrice}
                        initialValues={priceModal}
                    />

                    {showReserveModal && <ReserveInfoModal
                        isOpen={showReserveModal}
                        onClose={() => this.setState({ showReserveModal: false, valuesReserveModal: {} })}
                        onSubmit={this.finishReserve}
                        initialValues={valuesReserveModal}
                        reserves={reserveList}
                        deleteDate={($event) => this.removeDate($event)}
                        editDate={($event) => this.setState({ showModal: false }, () => this.editDate($event))}
                    />}

                    {showDateModal && <ReserveDateWarningModal
                        openModal={showDateModal}
                        closeModal={() => this.setState({ showDateModal: false, dataDateModal: null })}
                        onSubmit={(value) => this.editReserveDate(value)}
                        // loading={loadingButton}
                        initialValues={dataDateModal}
                    />}

                    {/* {(showWaitingPolicyModal || showInterestPolicyModal) && <WaitingInterestPolicyModal
                        openModal={showWaitingPolicyModal || showInterestPolicyModal}
                        closeModal={() => this.setState({ showWaitingPolicyModal: false, showInterestPolicyModal: false })}
                        agendaType={agendaType}
                    />} */}

                    {/* <UnavailableModal
                    isOpen={showUnavailableModal}
                    onClose={e => this.setState({ showUnavailableModal: false, unavailableModal: null })}
                    onSubmit={this.onSubmitUnavailabe}
                    initialValues={unavailableModal}
                /> */}
                </PageContainer>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(ReserveAgenda);
