import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import {
  GetLogs
} from '../../infra/requests/LogsRequests';
import {
  GetChapterLogsList
} from '../../infra/requests/ChapterRequests';
import {
  GetQuestionLogsList
} from '../../infra/requests/QuestionsRequests';
import {
  GetUsersList
} from '../../infra/requests/UsersRequests';
import LogsFilters from './LogsFilters';
import SectionNames from '../../infra/services/sections/SectionNames';
import moment from 'moment';
import LogsDetail from './LogsDetail';
import BaseButton from '../../components/buttons/BaseButton';
import RoomEditorModal from './RoomEditorModal';

class LogsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    filters: '',
    columns: [
      {
        title: 'Date',
        key: 'createdAt',
        render: data => moment(data.createdAt).local().format('DD/MM/YYYY, HH:mm:ss'),
        width: '12%'
      },
      {
        title: 'Utilizador',
        key: 'user',
        render: data => this.renderUser(data.user),
        width: '12%'
      },
      {
        title: 'Secção',
        key: 'section',
        render: data => this.renderSection(data),
        width: '12%'
      },
      {
        title: 'Capítulo',
        key: 'chapter',
        render: data => this.renderChapter(data),
        width: '15%'
      },
      {
        title: 'Pergunta',
        key: 'question',
        render: data => data?.question?.title?.pt,
        width: '20%'
      },
      {
        title: 'Ação',
        key: 'description',
        render: data => {
          return data?.roomPlan
            ? <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
              <BaseButton
                style={{ width: 'fit-content', marginTop: 8 }}
                type='primary'
                icon='search'
                text={'Pré-visualizar'}
                onClick={() => this.setState({ showModal: true, logValue: data })} />
            </div>
            : <span dangerouslySetInnerHTML={{ __html: data?.description }} />
        },
        width: '29%'
      }
    ],
    rows: [],
    total: 0,
    chapters: [],
    questions: [],
    users: [],
    wedding: '',
    filterSection: '',
    filterChapter: '',

    showModal: false,
    logValue: null,
  };

  componentDidMount = async () => {
    const { match: { params } } = this.props;

    if (params.wedding) {
      this.setState({
        wedding: params.wedding
      }, await this.updateDataTable());
    }
    else {
      await this.updateDataTable();
    }
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { match: { params } } = this.props;
      let { currentPage, pageSize, filters, filterSection, filterChapter } = this.state;

      if (!filters) {
        filters = JSON.stringify({ wedding: params.wedding })
      }

      const result = await GetLogs(currentPage, pageSize, filters);
      //console.warn('Result', result.data);

      const usersList = await GetUsersList();
      await this.getChapters(filterSection);
      await this.getQuestions(filterSection, filterChapter);

      //Add a specific user representing the Bride and Groom to the users list
      let users = [];
      if (usersList?.data?.length > 0) {
        users.push({
          id: 1,
          name: 'Noivos'
        });
        users.push({
          id: 2,
          name: 'Convidado(s)'
        });

        usersList.data.forEach(user => {
          users.push({
            id: user._id,
            name: user.name
          });
        });
      }

      //const size = result.data.items.length - 1;
      //result.data.items[size].user = null;

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        users
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderUser = (user) => {
    //console.log('User', user);
    if (user !== null && user?.admin) return user.name;
    else if (user !== null && user?.alternativeUser) return `${user.name} (Convidado)`
    else if (user !== null && !user?.admin) return `${user.name} (Noivos)`;
    else return '';
  };

  renderSection = (data) => {
    if (data?.extraSection === 'upgrade') return 'Upgrade';
    else if (data?.extraSection === 'budget') return 'Orçamento';
    else if (data?.extraSection === 'process') return 'Processo';
    else if (data?.extraSection === 'orders') return 'Encomendas';
    else return SectionNames[data?.section?.tag];
  };

  renderChapter = (data) => {
    if (data?.extraChapter === 'payment') return 'Pagamentos Efectuados';
    else if (data?.extraChapter === 'expense') return 'Outros valores';
    else if (data?.extraChapter === 'offer') return 'Valores a descontar';
    else if (data?.extraChapter === 'orders_download') return 'Download da encomenda';
    else if (data?.extraChapter === 'orders_email') return 'Envio da encomenda por e-mail';
    else return data?.chapter?.name?.pt || '-';
  };

  getChapters = async (section) => {
    //Chapter list for filters
    let chapters = [];

    if (section !== 'upgrade' && section !== 'budget' && section !== 'process') {
      const filters = JSON.stringify({ section });
      const chaptersList = await GetChapterLogsList(filters);

      if (chaptersList?.data?.length > 0) {
        chaptersList.data.forEach(chapter => {
          const sectionName = SectionNames[chapter.section?.tag] || '';

          chapters.push({
            id: chapter._id,
            name: `${sectionName} » ${chapter.name?.pt}`
          });
        });
      }
    }
    else if (section === 'budget') {
      chapters = [
        {
          id: 'payment',
          name: 'Pagamentos Efectuados'
        },
        {
          id: 'expense',
          name: 'Outros valores'
        },
        {
          id: 'offer',
          name: 'Valores a descontar'
        }
      ];
    }

    this.setState({
      chapters
    });
  }

  getQuestions = async (section, chapter) => {
    //Question list for filters
    const questions = [];

    if (section !== 'upgrade' && section !== 'budget' && section !== 'process' && chapter !== 'payment' && chapter !== 'expense' && chapter !== 'offer') {
      const filters = JSON.stringify({ section, chapter });
      const questionsList = await GetQuestionLogsList(filters);

      if (questionsList?.data?.length > 0) {
        questionsList.data.forEach(question => {
          const sectionName = SectionNames[question.section?.tag] || '';
          const chapterName = question.chapter?.name?.pt || '';

          questions.push({
            id: question._id,
            name: `${sectionName} » ${chapterName} » ${question.title?.pt}`
          });
        });
      }
    }

    this.setState({
      questions
    });
  }

  checkSection = async (value) => {
    this.setState({
      filterSection: value,
      filterChapter: ''
    });
  };

  checkChapter = async (value) => {
    this.setState({
      filterChapter: value
    });
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, chapters, questions, users, wedding } = this.state;
    const { showModal, logValue } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Histórico de Ações</PageTitle>
            Logs
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <LogsFilters
            queryChange={this.handleFilterChange}
            chapters={chapters}
            questions={questions}
            users={users}
            wedding={wedding}
            checkSection={this.checkSection}
            checkChapter={this.checkChapter}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="warning"
            emptyText={'Não existe histórico inserido!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            expandedRowRender={(data) => <LogsDetail insideWedding={wedding ? true : false} data={data} />}
          />

          {showModal && <RoomEditorModal
            openModal={showModal}
            closeModal={() => this.setState({ showModal: false })}
            initialValues={logValue}
          />}
        </PageContainer>
      </React.Fragment>
    );
  }
}
export default LogsPage;