import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Radio } from 'antd';
import PropTypes from 'prop-types';
import styledComponents from 'styled-components';
import { ScheduleTypes } from '../ScheduleModal';
import { ScheduleServiceType } from './ScheduleServiceModal';

const RowButtons = styledComponents.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;

    .ant-btn {
        margin: 5px 0px; 
    }
`;

let ServiceActionModal = ({
    isOpen,
    initialValues,
    onCreateAppointment,
    onEdit,
    onClose,
    onDelete,
}) => {
    const [actionType, setActionType] = useState(null);

    // useEffect(() => {
    //     setActionType(initialValues?.actionType);
    // }, [initialValues])

    const onChange = (event) => {
        setActionType(event.target.value);
    }
    return (
        <Modal
            visible={isOpen}
            title={'Opções'}
            maskClosable={false}
            onCancel={onClose}
            width={250}
            footer={null}>
            {initialValues?.source?.type == ScheduleServiceType.RECURSIVE
                && <Radio.Group onChange={onChange} value={actionType}>
                    <Radio value={0}>Todos os horários</Radio>
                    <Radio value={1}>Este horário</Radio>
                </Radio.Group>
            }
            <RowButtons>
                <Button
                    key='cancel'
                    type='default'
                    disabled={(initialValues?.source?.type == ScheduleServiceType.RECURSIVE && actionType == null) || initialValues?.source?.hasSchedule}
                    onClick={() => onEdit({...initialValues, actionType})}>
                    <Icon type='edit' />
                    Editar
                </Button>
                <Button
                    key='delete'
                    type='danger'
                    disabled={(initialValues?.source?.type == ScheduleServiceType.RECURSIVE && actionType == null) || initialValues?.source?.hasSchedule}
                    onClick={() => onDelete({...initialValues, actionType})}>
                    <Icon type='delete' />
                    Remover
                </Button>
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    <Icon type='close' />
                    Cancelar
                </Button>
            </RowButtons>
        </Modal>
    );
};


ServiceActionModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default ServiceActionModal