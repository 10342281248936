import moment from "moment";
import { ReserveStatus, WeddingStatus } from "./ReserveAgenda";

// Payment/Row Colors
const greenColor = '#d9f7be';
const redColor = '#ffa39e';
export const yellowColor = '#fff566';
export const inactiveColor = '#dbdbdb';

// Tag Colors
const greenTag = 'green';
const redTag = 'red';
const yellowTag = 'gold'

export const GetRowColor = (colors, reserve) => {
    let color = '';
    if (reserve?.status === ReserveStatus.INACTIVE) {
        color = inactiveColor;
    } else if (reserve?.wedding?.status !== WeddingStatus.RESERVE) {
        if (reserve?.wedding?.wedding_place === 1) return colors?.colorSys1;
        if (reserve?.wedding?.wedding_place === 2) return colors?.colorSys2;
    }

    return color;
}

export const GetWaitingRowColor = (colors, waitingDate) => {
    let color = '';
    if (waitingDate.weddingPlace === 1) return colors?.colorSys1;
    if (waitingDate?.weddingPlace === 2) return colors?.colorSys2;

    return color;
}

export const GetStatusColor = (colors, reserve) => {
    let color = '';
    if (reserve?.status === ReserveStatus.INACTIVE) {
        color = inactiveColor;
    } else if (reserve?.wedding?.status !== WeddingStatus.RESERVE) {
        if (reserve?.wedding?.status === WeddingStatus.RESERVE_SINALIZATION) {
            color = yellowColor;
        } else {
            if (reserve?.wedding?.wedding_place === 1) return colors?.colorSys1;
            if (reserve?.wedding?.wedding_place === 2) return colors?.colorSys2;
        }
    }

    return color;
}

export const GetPaymentColor = (lastPayment, wedding, budget) => {
    let color = '';
    // To Test ONLY
    // const now = moment('2023-03-07').endOf('day');
    const now = moment();

    if (wedding?.status !== WeddingStatus.RESERVE) {

        // 2nd Phase Complete or phases bigger than 2nd
        if (lastPayment && lastPayment?.phaseOrder && ((lastPayment?.phaseOrder === 2 && lastPayment?.phaseComplete) || lastPayment?.phaseOrder > 2)) {
            color = budget && budget?.payedPercentage && budget?.payedPercentage < 50 ? redColor : greenColor;
        }
        // 2nd Phase Not Complete
        else if (lastPayment && lastPayment?.phaseOrder && lastPayment.phaseOrder === 2 && !lastPayment?.phaseComplete) {
            color = lastPayment?.phase && lastPayment?.phase?.date &&
                now.isAfter(moment(lastPayment.phase.date)) && !lastPayment?.phaseComplete
                ? lastPayment?.phase?.warningColor
                : '';
        }
        // 1st Phase
        else if (lastPayment && lastPayment?.phaseOrder && lastPayment?.phaseOrder === 1) {
            color = lastPayment?.phaseComplete ? '' :
                lastPayment?.phase
                    ? lastPayment?.phase?.warningColor
                    : yellowColor;
        } else {
            color = yellowColor;
        }
    }

    // if (wedding?.status === WeddingStatus.RESERVE_SINALIZATION) {
    //     // 1st Phase Complete -> payment without color
    //     if (lastPayment && lastPayment?.phaseOrder && lastPayment?.phaseOrder === 1 && lastPayment?.phaseComplete) {
    //         color = '';
    //     }
    //     // 1st Phase not completed -> payment yellow
    //     else if (lastPayment?.phaseOrder && lastPayment?.phaseOrder === 1 && !lastPayment?.phaseComplete) {
    //         color = lastPayment?.phase ? lastPayment?.phase?.warningColor : yellowColor;
    //     }
    //     // Other phases and phase 1 not completed
    //     else color = yellowColor;
    // } else if (wedding && wedding?.status && wedding?.status !== WeddingStatus.RESERVE && wedding?.status !== WeddingStatus.RESERVE_SINALIZATION) {
    //     if (lastPayment && lastPayment?.phaseOrder && lastPayment.phaseOrder === 1 && lastPayment?.phaseComplete) {
    //         color = redColor;
    //     } else if (lastPayment && lastPayment?.phaseOrder && lastPayment.phaseOrder === 2) {
    //         color = lastPayment?.phase && lastPayment?.phase?.date &&
    //             now.isAfter(moment(lastPayment.phase.date)) && !lastPayment?.phaseComplete
    //             ? lastPayment?.phase?.warningColor
    //             : greenColor;
    //     } else if (lastPayment && lastPayment?.phaseOrder && lastPayment.phaseOrder === 3 && budget && budget?.payedPercentage && budget?.payedPercentage < 50) {
    //         color = redColor;
    //     } else color = greenColor
    // }

    return color;
}

export const GetTagPaymentColor = (lastPayment, wedding, budget) => {
    let color = '';
    // To Test ONLY
    // const now = moment('2023-03-07').endOf('day');
    const now = moment();

    if (wedding?.status !== WeddingStatus.RESERVE) {

        // 2nd Phase Complete or phases bigger than 2nd
        if (lastPayment && lastPayment?.phaseOrder && ((lastPayment?.phaseOrder === 2 && lastPayment?.phaseComplete) || lastPayment?.phaseOrder > 2)) {
            color = budget && budget?.payedPercentage && budget?.payedPercentage < 50 ? redTag : greenTag;
        }
        // 2nd Phase Not Complete
        else if (lastPayment && lastPayment?.phaseOrder && lastPayment.phaseOrder === 2 && !lastPayment?.phaseComplete) {
            color = lastPayment?.phase && lastPayment?.phase?.date &&
                now.isAfter(moment(lastPayment.phase.date)) && !lastPayment?.phaseComplete
                ? '' // lastPayment?.phase?.warningColor
                : '';
        }
        // 1st Phase
        else if (lastPayment && lastPayment?.phaseOrder && lastPayment?.phaseOrder === 1) {
            color = lastPayment?.phaseComplete ? '' :
                lastPayment?.phase
                    ? '' // lastPayment?.phase?.warningColor
                    : yellowTag;
        } else {
            color = yellowTag;
        }
    }

    // // If exists last payment and is associated with a paymentPhase
    // if (lastPayment && lastPayment?.phaseOrder) {
    //     // If wedding In Sinalization
    //     if (wedding?.status === WeddingStatus.RESERVE_SINALIZATION) {
    //         // 1st Phase Not Completed -> payment yellow
    //         if (lastPayment && lastPayment?.phaseOrder && lastPayment.phaseOrder === 1 && !lastPayment.phaseComplete) {
    //             color = lastPayment?.phase?.warningColor;
    //         }
    //         // Phase Order ins't the 1st
    //         else if (lastPayment && lastPayment?.phaseOrder && lastPayment.phaseOrder >= 2) {
    //             color = yellowTag;
    //         }
    //         else color = '';
    //     }
    //     // If wedding is Confirmed or Archived
    //     else if (wedding?.status && wedding?.status !== WeddingStatus.RESERVE && wedding?.status !== WeddingStatus.RESERVE_SINALIZATION) {
    //         if (lastPayment && lastPayment.phaseOrder === 1 && lastPayment.phaseComplete) {
    //             color = redTag;
    //         } else if (lastPayment && lastPayment.phaseOrder === 2) {
    //             color = lastPayment?.phase && lastPayment?.phase?.date &&
    //                 now.isAfter(moment(lastPayment.phase.date)) && !lastPayment?.phaseComplete
    //                 ? '' //lastPayment.phase.warningColor
    //                 : greenTag;
    //         } else if (lastPayment && lastPayment.phaseOrder === 3 && budget && budget?.payedPercentage && budget?.payedPercentage < 50) {
    //             color = redTag;
    //         } else color = greenTag
    //     }
    // } else {
    //     if (wedding && wedding.paymentPhases && Array.isArray(wedding.paymentPhases)) {
    //         const firstPaymentPhase = wedding.paymentPhases.find(f => f.order && f.order === 1);
    //         if (firstPaymentPhase && now.isAfter(moment(firstPaymentPhase.date))) {
    //             color = redTag;
    //         } else color = budget && budget?.payedPercentage && budget?.payedPercentage < 50 ? redTag : greenTag;
    //     } else color = budget && budget?.payedPercentage && budget?.payedPercentage < 50 ? redTag : greenTag;;
    // }

    return color;
}

export const getReserveStatus = (status) => {
    if (status) {
        return status === ReserveStatus.OCCUPIED
            ? 'O'
            : status === ReserveStatus.INACTIVE
                ? 'I'
                : status === ReserveStatus.SINALIZATION
                    ? 'S'
                    : 'RSC';

    } else return '';
}

export const setDataToUpdateForReserveWedding = (values) => {

    const data = {
        wedding_place: 1,
        language: values.language,
        bride: {
            name: values.bridename,
            email: values.brideemail,
            contact: values.bridecontact
        },
        groom: {
            name: values.groomname,
            email: values.groomemail,
            contact: values.groomcontact
        },
        expectedMinPeople: values.expectedMinPeople,
        visitedPlace: values.visitedPlace !== undefined ? values.visitedPlace : false,
        // placeToVisit: values.placeToVisit !== undefined ? values.placeToVisit : false,
        // wantToVisitWithoutInfo: values.wantToVisitWithoutInfo !== undefined ? values.wantToVisitWithoutInfo : false,
        accessToConfirmedWedding: values.accessToConfirmedWedding !== undefined ? values.accessToConfirmedWedding : false,
    };
    
    if (values.visitedWeddingPlace !== undefined) data['visitedWeddingPlace'] = values.visitedWeddingPlace;
    // if (values.weddingPlaceToVisit !== undefined) data['weddingPlaceToVisit'] = values.weddingPlaceToVisit;
    // if (values.shecheduleToVisit !== undefined) data['shecheduleToVisit'] = values.shecheduleToVisit;
    
    if ((values?.alternativemail && values?.alternativemail !== '')
        || (values?.alternativecontact && values?.alternativecontact !== '')) {
        data['alternativeGuest'] = {};
        if (values?.alternativename && values?.alternativename !== '') data.alternativeGuest.name = values?.alternativename || '';
        if (values?.alternativemail && values?.alternativemail !== '') data.alternativeGuest.email = values?.alternativemail || '';
        if (values?.alternativecontact && values?.alternativecontact !== '') data.alternativeGuest.contact = values?.alternativecontact || '';
        if (values?.alternativerelationcouple && values?.alternativerelationcouple !== '') data.alternativeGuest.relationToCouple = values?.alternativerelationcouple || '';
    }

    return data;
}