import React, { Component, Fragment } from 'react';
import { Popconfirm, Icon, Checkbox } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  FormContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  TableButton,
  PageContainer,
  SectionTitle
} from '../../../styles/BasicStyles';
import {
  GetUpgrades,
  CreateUpgrade,
  DeleteUpgrade,
  ToggleUpgrade,
  ToggleUpgradeOptions
} from '../../../infra/requests/UpgradesRequests';
import BaseButton from '../../../components/buttons/BaseButton';
import LanguagesInput from '../../../components/inputs/LanguagesInput';
import FormValidator from '../../../infra/services/validations/FormValidator';
import {
  GetSection,
  UpdateSection
} from '../../../infra/requests/SectionRequests';
import SectionNames from '../../../infra/services/sections/SectionNames';
import NewUpgradeModal from '../../upgrades/NewUpgradeModal';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { FlattenToFormData } from '../../../infra/services/formdata/TransformToFormData';
import CurrencyComponent from '../../../components/currency/CurrencyComponent';
import Table from '../../../components/table/Table';
import CheckboxInput from '../../../components/inputs/CheckboxInput';

const validations = FormValidator.make({
  description: 'required|languages'
});

class UpgradesSection extends Component {
  state = {
    loading: true,
    ready: false,
    openModal: false,
    upgrades: [],
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        render: value => TranslateValue(value)
      },
      {
        title: 'Preço',
        dataIndex: 'price',
        render: value => <CurrencyComponent value={value} />
      },
      {
        title: 'Publicado',
        dataIndex: 'active',
        render: value => (value ? 'Sim' : 'Não')
      },
      // {
      //   title: 'Opções Premium?',
      //   dataIndex: 'hasPremiumOptions',
      //   render: value => (value ? 'Sim' : 'Não')
      // },
      {
        title: 'Acções',
        render: data => (
          <Fragment>
            {/* <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={`Quer ${data.hasPremiumOptions ? 'desativar' : 'ativar'
                  } as opções para este upgrade?`}
                onConfirm={() => this.toggleOptionsToActivate(data)}>
                <Icon type={data.hasPremiumOptions ? 'close-circle' : 'check-circle'} />
                {data.hasPremiumOptions ? ' Desativar' : ' Ativar'} Opções
              </Popconfirm>
            </TableButton> */}
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={`Quer ${data.active ? 'desativar' : 'publicar'
                  } este upgrade?`}
                onConfirm={() => this.toggleActive(data)}>
                <Icon type={data.active ? 'close-circle' : 'check-circle'} />
                {data.active ? ' Desativar' : ' Publicar'}
              </Popconfirm>
            </TableButton>
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Tem a certeza que quer apagar este upgrade?"
                onConfirm={() => this.deleteUpgrade(data._id)}>
                <Icon type="delete" />
                {' Apagar'}
              </Popconfirm>
            </TableButton>
          </Fragment>
        )
      }
    ]
  };

  componentDidMount() {
    this.loadSection();
  }

  loadSection = async () => {
    const { dispatch } = this.props;
    const { data } = await GetSection('UPGRADE');
    dispatch(initialize('manage_upgradesection_form', data));
    await this.getUpgrades();
  };

  getUpgrades = async () => {
    try {
      const { data } = await GetUpgrades();
      this.setState({
        upgrades: data
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true });
    }
  };

  onSubmit = async values => {
    const { history } = this.props;
    this.setState({ loading: true });
    await UpdateSection('UPGRADE', values);
    history.push('/sections');
  };

  newUpgrade = async values => {
    this.setState({ loading: true });
    const { history } = this.props;
    if (!values?.price || values?.price == '') {
      values.price = 0;
    }
    const payload = FlattenToFormData(values);
    const { data, success } = await CreateUpgrade(payload);
    this.setState({ loading: false });
    if (success) history.push(`/upgrades/${data._id}`);
    else this.setState({ openModal: false });
  };

  toggleOptionsToActivate = async (record) => {
    this.setState({ loading: true });
    await ToggleUpgradeOptions(record._id, { hasPremiumOptions: !record.hasPremiumOptions });
    await this.getUpgrades();
  }

  toggleActive = async record => {
    this.setState({ loading: true });
    await ToggleUpgrade(record._id, { active: !record.active });
    await this.getUpgrades();
  };

  deleteUpgrade = async id => {
    await DeleteUpgrade(id);
    await this.getUpgrades();
  };

  onPressRow = data => {
    const { history } = this.props;
    history.push(`/upgrades/${data._id}`);
  };

  render() {
    const { history, handleSubmit } = this.props;
    const { ready, columns, upgrades, loading, openModal } = this.state;
    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>{SectionNames.UPGRADES}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={3}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Novo Upgrade'}
              onClick={() => this.setState({ openModal: true })}
            />
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={() => history.push('/sections')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>

        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={LanguagesInput}
              name={'description'}
              type="text"
              label={'Descrição'}
              placeholder={'Descrição da secção'}
            />
            <Field
              component={LanguagesInput}
              name={'information'}
              type="text"
              label={'Condições do upgrade'}
              placeholder={'Mensagem de aviso de subscrição do upgrade'}
            />
          </BaseForm>
        </FormContainer>
        <PageContainer subpage>
          <SectionTitle>Lista</SectionTitle>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={upgrades.length}
            loading={loading}
            rows={upgrades}
            showHeader={true}
            emptyIcon="crown"
            emptyText={'Não existem upgrades definidos'}
            total={upgrades.length}
            onPressRow={this.onPressRow}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
        <NewUpgradeModal
          open={openModal}
          loading={loading}
          onSubmit={this.newUpgrade}
          closeModal={() => this.setState({ openModal: false })}
        />
      </React.Fragment>
    );
  }
}

UpgradesSection = reduxForm({
  form: 'manage_upgradesection_form',
  validate: validations
})(UpgradesSection);

export default withLocalize(UpgradesSection);
