import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Row, Col, Steps, TimePicker, Checkbox } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../../infra/services/validations/FormValidator';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import SelectInput from '../../../components/inputs/SelectInput';
import { BaseForm, SpinLoading } from '../../../styles/BasicStyles';
import PropTypes from 'prop-types';
import DateInput from '../../../components/inputs/DateInput';
import { TimeInput } from '../../../components/inputs/InputStyles';
import { GetAvailableEmployeesAttending } from '../../../infra/requests/UsersRequests';
import XpertGoTimeInput from '../../../components/inputs/XpertGoTimeInput';
import TextInput from '../../../components/inputs/TextInput';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import moment from 'moment';
import Alerts from '../../../components/alert/Alert';
import TitleInput from '../../../components/inputs/TitleInput';
import TagInput from '../../../components/inputs/TagInput';
import LanguagesInput from '../../../components/inputs/LanguagesInput';
import TagTranslateInput from '../../../components/inputs/TagTranslateInput';

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        acronym: 'required|languagesNoSpaces',
        subtitle: 'required|languages',
        description: 'languagesNotRequired',
    })(values);

    return errors;
};

let FoodRestrictionModal = ({
    isOpen,
    handleSubmit,
    initialValues,
    onSubmit,
    onClose,
    onDelete,
    restrictionForm
}) => {
    useEffect(() => {
    }, []);

    return (
        <Modal
            visible={isOpen}
            title={initialValues?._id ? 'Editar Restrição Alimentar' : 'Criar Restrição Alimentar'}
            maskClosable={false}
            onCancel={onClose}
            footer={<React.Fragment>
                <Button
                    key='cancel'
                    type='default'
                    onClick={onClose}>
                    Cancelar
                </Button>
                <Button
                    key='submit'
                    type='primary'
                    onClick={handleSubmit(onSubmit)}>
                    Gravar
                </Button>
            </React.Fragment>}>

            <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TagTranslateInput}
                            name="acronym"
                            label="Acrónimo *"
                            placeholder="Insira o acrónimo"
                            type="text"
                        />
                    </Col>

                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={TitleInput}
                            name={'subtitle'}
                            type="text"
                            label={'Legenda *'}
                            placeholder={'Legenda da restrição'}
                        />
                    </Col>

                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={LanguagesInput}
                            name={'description'}
                            type="text"
                            label={'Descrição'}
                            placeholder={'Descrição da restrição'}
                        />
                    </Col>

                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={CheckboxInput}
                            name={'notesRequired'}
                            label={'Requer observações?'}
                            positiveLabel={'Sim'}
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={CheckboxInput}
                            name={'chefChoice'}
                            label={'À escolha do chefe?'}
                            positiveLabel={'Sim'}
                        />
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Field
                            component={CheckboxInput}
                            name={'pastryOrder'}
                            label={'Apresentar nas encomendas de pastelaria?'}
                            positiveLabel={'Sim'}
                        />
                    </Col>
                </Row>
            </BaseForm>
        </Modal>
    );
};

FoodRestrictionModal = reduxForm({
    form: 'restriction_form',
    enableReinitialize: true,
    validate: validations,
})(FoodRestrictionModal);

const selector = formValueSelector('restriction_form');

const mapStateToProps = state => ({
    restrictionForm: {
        acronym: selector(state, 'acronym'),
        subtitle: selector(state, 'subtitle'),
        description: selector(state, 'description'),
    }
});

FoodRestrictionModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(FoodRestrictionModal)