import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Radio, Checkbox } from 'antd';
import BaseButton from '../../../components/buttons/BaseButton';
import { TableFilterSection } from '../../../styles/BasicStyles';
import TextInput from '../../../components/inputs/TextInput';
import SelectInput from '../../../components/inputs/SelectInput';
import { visitedLocals } from '../../schedules/SchedulesFilters';
import { RoomPlanTypes } from './TableTypeModal';

const requiredOptions = [
  {
    _id: 1,
    name: 'Sim',
    value: true
  },
  {
    _id: 2,
    name: 'Não',
    value: false
  }
];

class TableTypeFilters extends Component {
  timeout = 0;
  state = {
    search: '',
    weddingPlace: '',
    canBeJoin: null,
    coupleTable: null,
    roomPlan: null,
    deleted: undefined,
    loading: false
  };

  componentDidMount = async () => {
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    // queryChange(JSON.stringify(query));
    queryChange(query);
  };

  render() {
    const { search, weddingPlace, canBeJoin, coupleTable, roomPlan, loading } = this.state;
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <div className='settings-search'>
              <TextInput
                input={{
                  value: search,
                  onChange: event =>
                    this.onInputChange('search', event.target.value, 600)
                }}
                meta={{ valid: true }}
                type="text"
                label="Pesquisar"
                placeholder="Procurar por designação"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Local"
              disabled={loading}
              allowClear
              placeholder="Escolher local"
              data={visitedLocals}
              input={{
                value: weddingPlace,
                onChange: value => this.onInputChange('weddingPlace', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <SelectInput
              label="Pode ser unida"
              disabled={loading}
              allowClear
              placeholder="Escolher opção"
              data={requiredOptions}
              dataKey='value'
              input={{
                value: canBeJoin,
                onChange: value => this.onInputChange('canBeJoin', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <SelectInput
              label="Tipo de mesa"
              disabled={loading}
              allowClear
              placeholder="Escolher opção"
              data={[
                {_id: true, name: 'Casal'},
                {_id: false, name: 'Convidados'},
              ]}
              input={{
                value: coupleTable,
                onChange: value => this.onInputChange('coupleTable', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          {/* <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <SelectInput
              label="Plano"
              disabled={loading}
              allowClear
              placeholder="Escolher opção"
              data={[
                {_id: RoomPlanTypes.NORMAL, name: 'Normal'},
                {_id: RoomPlanTypes.XL, name: 'XL'},
                {_id: RoomPlanTypes.BOTH, name: 'Ambos'},
              ]}
              input={{
                value: roomPlan,
                onChange: value => this.onInputChange('roomPlan', value)
              }}
              meta={{ valid: true }}
            />
          </Col> */}
          <Col xs={24} sm={12} md={4} lg={3} xl={3} style={{height: 66, display: 'flex'}}>
            <Checkbox 
            style={{display: 'flex', alignItems: 'flex-end', marginBottom: 10}} 
            onChange={event => this.onInputChange('deleted', event.target.checked)}>Eliminado</Checkbox>
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default (TableTypeFilters);